import { useSelector } from "react-redux";

// REACT ICONS
import { BiLinkExternal } from "react-icons/bi";
import { RiDownload2Line } from "react-icons/ri";
// CUSTOM HOOKS
import { useResponsiveCondition } from "src/pos/hooks";
import useDownloadPDF from "src/customHooks/useDownloadPDF";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
// UTILS
import { brokerTenant } from "src/utils/tenantSettings";
// CUSTOM COMPONENTS
import FloatButton from "src/components/Common/Button/FloatButton";
import Spinner from "../Spinner";

export default function ComparePDFDownloadButton() {
  const { isCompareRoute } = useGetRouteMatch();
  const { width, breakpoint } = useResponsiveCondition(768);

  const { quotes: compareQuotes, selectedSectionView } = useSelector(
    state => state.globalComponent.shareModule,
  );

  const onDownloadClick = async () => {
    await downloadComparePDF();
  };

  const { pdfFetchLoading, downloadComparePDF, url } = useDownloadPDF({
    quotes: compareQuotes?.slice(0, 2),
    selectedSectionView: selectedSectionView,
  });

  if (width > breakpoint || !isCompareRoute) return <></>;
  return (
    <FloatButton
      onClick={() => {
        onDownloadClick();
      }}
    >
      {compareQuotes?.length > 1 ? (
        brokerTenant("renew_buy") && url && url != "" ? (
          <BiLinkExternal
            size={18}
            onClick={() => window.open(`${url}`, "_blank")}
          />
        ) : pdfFetchLoading ? (
          <Spinner />
        ) : (
          <RiDownload2Line size={18} />
        )
      ) : (
        <></>
      )}
    </FloatButton>
  );
}
