import { createSlice } from "@reduxjs/toolkit";

const recommendationSlice = createSlice({
  initialState: {
    answers: {},
  },
  name: "recommendation",
  reducers: {
    setRecommendationAnswers: (state, action) => {
      state.answers = action.payload;
    },
  },
});

export const { setRecommendationAnswers } = recommendationSlice.actions;

export default recommendationSlice.reducer;
