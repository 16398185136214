import { createSlice } from "@reduxjs/toolkit";

const schema = createSlice({
  name: "schema",
  initialState: {
    currentSchema: {},
    active: 0,
    loadingSchema: true,
    isContinueBtnClick: false,
  },
  reducers: {
    setCurrentSchema: (state, action) => {
      state.currentSchema = action.payload;
    },
    setActiveForm: (state, action) => {
      state.active = action.payload;
    },
    setLoadingSchema(state, { payload }) {
      state.loadingSchema = payload;
    },
    setIsContinueBtn: (state, action) => {
      state.isContinueBtnClick = action.payload;
    },
  },
});

export const { setCurrentSchema, setLoadingSchema, setIsContinueBtn } =
  schema.actions;

export default schema.reducer;
