import { brokerAppPhase, brokerTenant } from "./tenantSettings";
import { numToLakh } from "./string.utility";

export const webEngageTrackEvent = (eventName, eventProps) => {
  if (
    brokerTenant("bajaj") &&
    brokerAppPhase("PROD") &&
    !process.env.REACT_APP_BLOCK_JOURNEY
  ) {
    window?.webengage?.track(eventName, eventProps);
  }
};

export const webEngageTrackProposalEvent = ({
  stage,
  cartEntries = [],
  riders = [],
  enquiriesData,
  totalPremium,
  getCompanyLogo,
  prevTotalPremium = null,
  revisedTotalPremium = null,
}) => {
  const eventNames = {
    "Proposer Details": "Proposer Details Submitted",
    "KYC Details": "KYC Details Submitted",
    "Insured Details": "Insured Details Submitted",
    "Medical Details": "Medical Details Submitted",
    "Other Details": "Other Details Submitted",
    "Revised Popup Initiated": "Health Plan Revised Premium Declaration",
    "Revised Popup Accepted": "Health Plan Revised Premium Accepted",
    "Pay Now": "Payment Initiated",
  };

  let groupsObj = {};

  cartEntries.forEach((cartEntry, index) => {
    groupsObj = {
      ...groupsObj,
      [`Group ${index + 1}`]: cartEntry?.group?.members.join(", "),
    };
  });

  let planDetails = [];

  cartEntries.forEach(cartEntry => {
    const groupObj = {
      "Group Name": cartEntry?.group?.members.join(" + "),
      "Insurer Name": cartEntry?.product?.company?.name,
      "Plan Name": cartEntry?.product?.name,
      "Insurer Logo": [getCompanyLogo(cartEntry?.product?.company?.alias)],
      Cover: numToLakh(cartEntry?.sum_insured),
      "Yearly Premium incl GST": cartEntry?.total_premium,
      Tenure: `${cartEntry?.tenure} Year`,
      "Riders Name": cartEntry?.health_riders
        ?.map(rider => rider?.name)
        .join(", "),
    };
    planDetails.push(groupObj);
  });

  let eventProps = {
    "Family Groups Name": [groupsObj],
    "Total Family Group": cartEntries.length,
    "Trace ID": enquiriesData.data.trace_id,
    "Enquiry URL": window.location.href,
    "Insurer Name": cartEntries
      .map(cartEntry => cartEntry?.product?.company?.name)
      .join(", "),
    "Plan Name": cartEntries
      .map(cartEntry => cartEntry?.product?.name)
      .join(", "),
    "Total Insurance Cover": numToLakh(
      cartEntries.reduce((sum, policy) => sum + policy.sum_insured, 0),
    ),
    "Riders Name": riders,
    "Plan Details": planDetails,
  };

  if (
    stage === "Proposer Details" ||
    stage === "KYC Details" ||
    stage === "Insured Details"
  ) {
    eventProps = {
      ...eventProps,
      "Premium incl GST": totalPremium,
    };
  }

  if (stage === "KYC Details" || stage === "Insured Details") {
    eventProps = {
      ...eventProps,
      "Have CKYC": cartEntries[0]?.ckyc_mode === "ckyc_number" ? true : false,
    };
  }

  if (stage === "Pay Now") {
    eventProps = {
      ...eventProps,
      "Total Premium": totalPremium,
      "Plan Type": "Base Health",
    };
  }

  if (
    stage === "Revised Popup Initiated" ||
    stage === "Revised Popup Accepted" ||
    stage === "Medical Details" ||
    stage === "Other Details"
  ) {
    eventProps = {
      ...eventProps,
      "Previous Total Premium": prevTotalPremium,
      "Revised Total Premium": revisedTotalPremium,
    };
  }

  webEngageTrackEvent(eventNames[stage], eventProps);
};

export const generateInputTrackEventData = ({
  selectedMembersList,
  traceId,
}) => {
  const selectedMembers = selectedMembersList.members;

  const isMemberSelected = memberCode => {
    return (
      selectedMembers?.find(member => member?.code === memberCode) || false
    );
  };

  const memberAge = memberCode => {
    return (
      Number(
        selectedMembers?.find(member => member?.code === memberCode)?.age,
      ) || 0
    );
  };

  const numberOfSons = selectedMembers?.filter(member =>
    member?.code?.startsWith("son"),
  )?.length;

  const numberOfDaughters = selectedMembers?.filter(member =>
    member?.code?.startsWith("daughter"),
  )?.length;

  const eventTrackingData = {
    "Trace ID": traceId,
    "Enquiry URL": window.location.href,

    "Self Selected": !!isMemberSelected("self"),
    "Self Age in Years": memberAge("self"),
    "Spouse Selected": !!isMemberSelected("spouse"),
    "Spouse Age in Years": memberAge("spouse"),

    "Son Selected": !!isMemberSelected("son"),
    "Number of Sons": numberOfSons,
    "Son 1 Age": memberAge("son"),
    "Son 2 Age": memberAge("son2"),
    "Son 3 Age": memberAge("son3"),
    "Son 4 Age": memberAge("son4"),
    "Son 5 Age": memberAge("son5"),

    "Daughter Selected": !!isMemberSelected("daughter"),
    "Number of Daughters": numberOfDaughters,
    "Daughter 1 Age": memberAge("daughter"),
    "Daughter 2 Age": memberAge("daughter2"),
    "Daughter 3 Age": memberAge("daughter3"),
    "Daughter 4 Age": memberAge("daughter4"),
    "Daughter 5 Age": memberAge("daughter5"),

    "Father Selected": !!isMemberSelected("father"),
    "Father Age in Years": memberAge("father"),

    "Mother Selected": !!isMemberSelected("mother"),
    "Mother Age in Years": memberAge("mother"),

    "Grand Father Selected": !!isMemberSelected("grand-father"),
    "Grand Father Age in Years": memberAge("grand-father"),

    "Grand Mother Selected": !!isMemberSelected("grand-mother"),
    "Grand Mother Age in Years": memberAge("grand-mother"),

    "Father-in-Law Selected": !!isMemberSelected("father-in-law"),
    "Father-in-Law Age in Years": memberAge("father-in-law"),

    "Mother-in-Law Selected": !!isMemberSelected("mother-in-law"),
    "Mother-in-Law Age in Years": memberAge("mother-in-law"),

    "Brother Selected": !!isMemberSelected("brother"),
    "Brother Age in Years": memberAge("brother"),

    "Sister Selected": !!isMemberSelected("sister"),
    "Sister Age in Years": memberAge("sister"),

    "Son-in-law Selected": !!isMemberSelected("son-in-law"),
    "Son-in-law Age in Years": memberAge("son-in-law"),
  };
  return eventTrackingData;
};

export const webEngageEncryptUserData = async data => {
  try {
    const res = await fetch(
      `https://prod-api.bajajcapital.com/bclcomapp/api/encryptData `,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: "Bajajapi",
          secret: "b@j@j@9#8#7#",
        },
        body: JSON.stringify({
          id: `${data}`,
        }),
      },
    );
    if (res.status === 200) {
      const response = await res.json();
      if (response?.success && response?.encryptData) {
        return response.encryptData;
      }
    } else {
      console.error(res.message);
    }
  } catch (error) {
    console.error({ error });
  }
};
