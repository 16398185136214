import { useEffect, useRef, useState } from "react";
// CUSTOM HOOKS
import {
  useGetProductBrochureQuery,
  useGetProductFeaturesQuery,
} from "src/api/api";
import { useResponsiveCondition } from "src/pos/hooks";
// UTILS
import { getPlanFeatures } from "src/utils/helper";
// STYLED COMPONENTS
import {
  FeatureDefinitionWrapperStyled,
  FeatureListContainerStyled,
  FeatureListWrapperStyled,
  PlanDetailsWrapperStyled,
} from "./PlanDetails.style";
// CUSTOM COMPONENTS
import CardSkeletonLoader from "src/components/Common/card-skeleton-loader/CardSkeletonLoader";
import DownloadCard from "./DownloadCard";
import FeaturesTab from "./FeatureTab";
import PlanTabMobile from "./PlanTabMobile";
import PlansTab from "./PlansTab";
import { useTheme } from "src/customHooks";

export default function PlanDetails({ quote }) {
  const { product, sum_insured } = quote;

  const { isLoading, isUninitialized, data } = useGetProductFeaturesQuery(
    product.id,
  );
  const { width, breakpoint } = useResponsiveCondition(1024);
  const isDesktop = width > breakpoint;
  const planDetails = getPlanFeatures(data, sum_insured);

  const productBrochureQuery = useGetProductBrochureQuery(product.id);

  const [activeFeature, setActiveFeature] = useState(
    planDetails?.featureList?.[0]?.title || "",
  );

  const { colors } = useTheme();

  const scrollToTop = useRef();

  useEffect(() => {
    setActiveFeature(planDetails?.featureList?.[0]?.title);
  }, [JSON.stringify(planDetails)]);

  if (
    isLoading ||
    isUninitialized ||
    productBrochureQuery.isLoading ||
    productBrochureQuery.isUninitialized
  )
    return <CardSkeletonLoader />;

  const brochureUrl = (productBrochureQuery.data || [])[0]?.brochure_url;
  const policyWordingUrl = (productBrochureQuery.data || [])[0]
    ?.policy_wording_url;

  return (
    <PlanDetailsWrapperStyled>
      <FeatureListContainerStyled colors={colors}>
        <FeatureListWrapperStyled>
          {planDetails?.featureList?.map((data, index) => {
            if (data.displayType === "base") {
              return isDesktop ? (
                <PlansTab
                  key={index}
                  title={data.title}
                  description={data.description}
                  onClick={() => {
                    setActiveFeature(data.title),
                      (scrollToTop.current.scrollTop = 0);
                  }}
                  isActive={activeFeature === data.title}
                />
              ) : (
                <PlanTabMobile
                  key={index}
                  item={data}
                  data={planDetails.innerData[data.title]}
                />
              );
            }
          })}
        </FeatureListWrapperStyled>

        {brochureUrl && policyWordingUrl ? (
          <DownloadCard
            brochureUrl={brochureUrl}
            policyWordingUrl={policyWordingUrl}
          />
        ) : (
          <></>
        )}
      </FeatureListContainerStyled>

      <FeatureDefinitionWrapperStyled ref={scrollToTop} colors={colors}>
        {planDetails?.innerData?.[activeFeature]?.map((data, index) => (
          <FeaturesTab key={index} data={data} type="container" />
        ))}
      </FeatureDefinitionWrapperStyled>
    </PlanDetailsWrapperStyled>
  );
}
