import { FooterContainer } from "./footer-stylesheet";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import "styled-components/macro";

const Footer = () => {
  const { ...frontendBoot } = useFrontendBoot();

  const { alias } = frontendBoot?.data?.tenant;
  return (
    <>
      {alias === "uib" ? (
        <FooterContainer>
          <div className="footer_container">
            <div>
              <span>Category: </span> Composite Broker
            </div>
            <div>
              <span>CIN No.</span> U66030MH2009PTC195794
            </div>
            <div>
              <span>IRDAI Registration No.</span> 410
            </div>
            <div className="pad_x">
              <span>Copyright © 2022</span> UIB Insurance Brokers (India) Pvt.
              Ltd.
            </div>
          </div>
        </FooterContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default Footer;
