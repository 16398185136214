import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export default function BarGraph({
  data,
  direction,
  label,
  labelKey,
  dataKey,
  colors,
  dataPointLimit,
}) {
  let xAxisData = [];
  let yAxisData = [];

  const limitedData = data.slice(-dataPointLimit);

  limitedData.map(item => {
    return xAxisData.push(item[labelKey]), yAxisData.push(item[dataKey]);
  });

  return (
    <div>
      <Bar
        data={{
          labels: xAxisData,
          datasets: [
            {
              label,
              data: yAxisData,
              backgroundColor: colors.slice(-dataPointLimit),
            },
          ],
        }}
        height={300}
        width={200}
        options={{
          indexAxis: direction === "horizontal" ? "y" : "x",
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              titleColor: "rgba(0,0,0,1)",
              bodyColor: "rgba(0,0,0,1)",
              backgroundColor: "rgba(255,255,255,1)",
            },
          },
        }}
      />
    </div>
  );
}
