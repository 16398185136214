import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { getUserName } from "src/utils/helper";
import CardModal from "../Common/Modal/CardModal";
import CartSummaryContent from "./CartSummaryContent";

/* MAIN COMPONENT ================================================ */
export default function CartSummaryModal({
  selectedRiders = [],
  onClose = () => {},
  onContinue = () => {},
  allClose = () => {},
}) {
  const { input, enquiryData } = useGetEnquiry();
  const firstName = getUserName(enquiryData?.name || input.params.first_name);
  return (
    <CardModal
      title={`Hey ${
        firstName || "User"
      }, take a minute and review your cart before you proceed`}
      show
      handleClose={onClose}
      content={
        <CartSummaryContent
          selectedRiders={selectedRiders}
          onContinue={onContinue}
          closeModal={onClose}
          allClose={allClose}
        />
      }
      noFooter={true}
    />
  );
}
