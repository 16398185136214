/* 
  This file is used to set the tenant and phase for the application.
*/
import { capitalize } from "./helper";

export const envTenant = () => {
  return process.env.NODE_ENV === "development"
    ? sessionStorage.getItem("brokerTenant") || process.env.REACT_APP_TENANT
    : process.env.REACT_APP_TENANT;
};

export const envAppPhase = () => {
  return process.env.NODE_ENV === "development"
    ? sessionStorage.getItem("brokerAppPhase") || process.env.REACT_APP_PHASE
    : process.env.REACT_APP_PHASE;
};

export const envAppBaseURL = () => {
  return process.env.NODE_ENV === "development"
    ? sessionStorage.getItem("brokerApi") || process.env.REACT_APP_API_BASE_URL
    : process.env.REACT_APP_API_BASE_URL;
};

export const brokerTenant = tenant => {
  return tenant === envTenant();
};

export const brokerAppPhase = phase => {
  return phase === envAppPhase();
};

export const getTenantFullName = tenant => {
  switch (tenant) {
    case "bajaj":
      return "Bajaj Capital Insurance Broking Ltd";

    default:
      return tenant;
  }
};

export const getTenantDisplayName = tenant => {
  switch (tenant) {
    case "bajaj":
      return "Bajaj Capital Insurance Broking Ltd";

    case "radical":
      return "FinEzzy";

    case "tmibasl":
      return "LifeKaPlan - The Right Policy For You";

    default:
      return capitalize(tenant);
  }
};
