// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
import useRiderLoading from "src/pages/ProductDetails/Hooks/useRiderLoading";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  RiderCardStyled,
  RiderInfoAndActionWrapperStyled,
  RiderOptionsWrapperStyled,
  RiderPremiumButtonStyled,
} from "./RiderCard/RiderCard.style";
// CUSTOM COMPONENTS
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomSelect from "src/components/CustomSelect";
import Description from "../Description";
// DESIGN SYSTEM

export default function FreeRiderCard({
  onChange,
  freeRiders,
  selectedFreeRider,
}) {
  const { groupCode } = useParams();
  const { colors } = useTheme();
  const isRiderLoading = useRiderLoading(groupCode);

  const freeRiderList = freeRiders?.map(rider => ({
    code: rider?.alias,
    display_name: rider?.name,
  }));

  const handleChange = freeRider => {
    const selectedRider = freeRiders?.filter(
      rider => rider.alias === freeRider.value,
    )[0];

    onChange({
      ...selectedRider,
      isSelected: true,
      is_free_rider_selected: true,
    });
  };
  const selectedValue = freeRiderList?.find(
    rider => rider.code === selectedFreeRider.alias,
  );

  return (
    <RiderCardStyled
      data-rider-card-loading={isRiderLoading}
      data-rider-disabled={false}
    >
      <RiderPremiumButtonStyled
        colors={colors}
        data-rider-disabled={true}
        data-rider-selected={true}
      >
        <input type="checkbox" checked readOnly />
      </RiderPremiumButtonStyled>
      <RiderInfoAndActionWrapperStyled>
        <FHTypography variant="h6" weight="semi">
          Free Rider
        </FHTypography>
        <Description
          description={
            "Please choose a rider from the dropdown menu provided below. The selected rider will be complimentary; however, for additional riders, the appropriate fees will be applicable."
          }
        />
        <RiderOptionsWrapperStyled>
          <CustomSelect
            optionsList={freeRiderList}
            selectedOption={selectedValue}
            handleChange={handleChange}
          />
        </RiderOptionsWrapperStyled>
      </RiderInfoAndActionWrapperStyled>
    </RiderCardStyled>
  );
}
