// Defining the HTML tag that FHTypography component will support
export const variantsMapping = {
  h1Big: "h1",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subheading1: "h6",
  subheading2: "h6",
  body1: "p",
  body2: "p",
};

// Defining the Font weights that FHTypography component will support
export const weightMapping = {
  base: "normal",
  md: "medium",
  semi: "semiBold",
  bold: "bold",
};

// Defining the Font Role that FHTypography component will support
export const typeMapping = {
  success: "success",
  error: "error",
};

// Defining the colors mapping that FHTypography component will support
export const colorMapping = {
  heading: "headingColor",
  subHeading: "subHeadingColor",
  body: "bodyColor",
  white: "whiteColor",
};
