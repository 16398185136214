import styled from "styled-components";
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_ROW_CENTER, ROUNDED, SHADOW } from "src/styles/styleGuide";
import { bclWhatsappQRCode, whatsappImg } from "src/assets/images";
import FHTypography from "src/styles/Typography";
import { brokerTenant } from "src/utils/tenantSettings";

export default function WhatsappFloatButton() {
  const isOnBcl = brokerTenant("bajaj") && !process.env.REACT_APP_BLOCK_JOURNEY;

  if (!isOnBcl) return null;

  return (
    <FloatButtonStyled
      href="https://api.whatsapp.com/send/?phone=919667753599&text=Hi&type=phone_number&app_absent=0"
      target="__blank"
    >
      <QRCodePopup className="qr_code_popup">
        <FHTypography variant="subheading2" color="heading" weight="semi">
          Scan QR code to chat on WhatsApp
        </FHTypography>
        <img src={bclWhatsappQRCode} alt="Whatsapp QR code" />
        <FHTypography variant="subheading2" color="heading" weight="semi">
          +91 9667-753-599
        </FHTypography>
      </QRCodePopup>
    </FloatButtonStyled>
  );
}

const FloatButtonStyled = styled.a`
  position: relative;
  ${FLEX_ROW_CENTER};
  height: 2.3rem;
  width: 2.3rem;
  background-color: #25d366;
  background-image: url("${whatsappImg}");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${ROUNDED.full};
  box-shadow: ${SHADOW.md};

  ${VIEW.md} {
    height: 3rem;
    width: 3rem;
  }

  &:hover > .qr_code_popup {
    display: block;
  }
`;

const QRCodePopup = styled.div`
  position: absolute;
  right: 0;
  bottom: 3.5rem;

  display: none;
  padding: 0.5rem;
  border: 2px solid #25d366;
  border-radius: ${ROUNDED.xl};
  background-color: white;
  text-align: center;

  img {
    width: 140px;
    aspect-ratio: 1 / 1;
    margin-block: 0.25rem;
  }

  p {
    margin: 0;
  }
`;
