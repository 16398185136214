import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { setMultiYearFilterAction } from "../QuotePageSlice/quoteFilterSlice";
import { MULTIYEAR_OPTIONS } from "../quotePage.constants";
import { useEffect } from "react";

function useMultiYearFilter() {
  const { data: enquiryData } = useGetEnquiry();
  const groups = enquiryData?.data?.groups || [];
  const { groupCode } = useParams();
  let currentGroup = groups?.find(group => group?.id === parseInt(groupCode));
  const { multiYear } = useSelector(state => state.quoteFilters);
  const dispatch = useDispatch();
  const multiYearOptionsList = MULTIYEAR_OPTIONS;
  const multiYearValue = multiYear?.value || {};
  const defaultMultiYearValue = MULTIYEAR_OPTIONS[0];

  //EFFECT/s
  useEffect(() => {
    if (!multiYear.value.code) {
      dispatch(
        setMultiYearFilterAction(
          currentGroup?.extras?.filters?.multi_year || MULTIYEAR_OPTIONS[0],
        ),
      );
    }
    return () => {};
  }, []);

  //   FUNCTION/s
  const handleMultiYearChange = ({ payload }) => {
    dispatch(setMultiYearFilterAction(payload));
  };

  return {
    multiYearOptionsList,
    multiYearValue,
    handleMultiYearChange,
    defaultMultiYearValue,
  };
}

export default useMultiYearFilter;
