// Color Shades used for text color and background and border colors.
const SHADES = {
  HEADING: "#384252",
  SUB_HEADING: "#4c5564",
  BODY: "#6b737f",
  DIM_GRAY: "#6B737F",
  GRAY: "#9CA2AD",
  DARK_GRAY: "#D2D5DC",
  LIGHT_GRAY: "#E6E7EB",
  WHITE: "#F3F4F6",
  DROP_SHADE: "#6B737F77",
};

const PALETTE = {
  error: {
    main: "#f44336",
    light: "#e57373",
    dark: "#d32f2f",
  },
  success: {
    main: "#66bb6a",
    light: "#81c784",
    dark: "#388e3c",
    proposal: "#5baa5f",
  },
  warning: {
    main: "#ffa726",
    light: "#ffb74d",
    dark: "#f57c00",
  },
  custom: {
    gold: "#ffd700",
  },
};

const SHADOW = {
  sm: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  base: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  md: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  lg: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  xl: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  xxl: "0 25px 50px -12px rgb(0 0 0 / 0.25)",
};

// Font weights used for text.
const FWEIGHT = {
  base: 400,
  md: 500,
  semi: 600,
  bold: 700,
};

// ROUNDED used for border radius.
const ROUNDED = {
  sm: "0.125rem",
  base: "0.25rem",
  md: "0.375rem",
  lg: "0.5rem",
  xl: "0.75rem",
  xxl: "1rem",
  full: "5rem",
};

// ClassName Style font weights.
const FONT = {
  h1Big: "typography--variant-h1Big",
  h1: "typography--variant-h1",
  h2: "typography--variant-h2",
  h3: "typography--variant-h3",
  h4: "typography--variant-h4",
  h5: "typography--variant-h5",
  h6: "typography--variant-h6",
  subheading1: "typography--variant-subheading1",
  subheading2: "typography--variant-subheading2",
  body1: "typography--variant-body1",
  body2: "typography--variant-body2",
};

// ClassName Style font weights.
const FWEIGHT_CLASS = {
  base: "typography--weight-normal",
  md: "typography--weight-medium",
  semi: "typography--weight-semiBold",
  bold: "typography--weight-bold",
};

// Variables used for flex-box.
const FLEX_ROW = `
  display: flex;
  flex-direction: row;
  `;

const FLEX_COL = `
  display: flex;
  flex-direction: column;
  `;

const FLEX_ROW_CENTER = `
  ${FLEX_ROW};
  justify-content: center;
  align-items: center;
  `;

const FLEX_COL_CENTER = `
  ${FLEX_COL};
  justify-content: center;
  align-items: center;
  `;

export {
  SHADES,
  PALETTE,
  SHADOW,
  ROUNDED,
  FWEIGHT,
  FONT,
  FWEIGHT_CLASS,
  FLEX_ROW,
  FLEX_COL,
  FLEX_ROW_CENTER,
  FLEX_COL_CENTER,
};
