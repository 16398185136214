/*
 Description:- Renders the header card and render input forms
*/

import { memo } from "react";
import { useParams } from "react-router-dom";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import { usePos } from "src/customHooks/usePos";
import { useResponsiveCondition } from "src/pos/hooks";
import { DEVICES } from "src/styles/responsiveStyle";
import styled from "styled-components";
import BajajFooter from "../../components/Common/Footer/BajajFooter/BajajFooter";
import Footer from "../../components/Common/Footer/SriyahFooter";
import UibFooter from "../../components/Common/Footer/UibFooter";
import HeaderCard from "./HeaderCard/HeaderCard";
import useAutoBasicDetailsPass from "./Hooks/useAutoBasicDetailsPass";
import AutofillDetailsLoader from "./components/AutofillDetailsLoader";
import RenderInputForms from "./components/RenderInputForms";
import {
  DefaultFooterStyled,
  InputContainerStyled,
  InputHeading,
  InputInnerWrapperStyled,
  InputSubtitle,
  InputWrapperStyled,
  MobileHeadingWrapper,
  ResponsiveWrapper,
} from "./inputPage.styled";
import { getJourneyText } from "./inputPage.utility";

const InputPageInner = () => {
  const { width, breakpoint } = useResponsiveCondition(DEVICES.tabletSM);
  const { colors } = useTheme();
  const { settings } = useFrontendBoot();
  const { currentForm } = useParams();
  const { loginLoading } = useAutoBasicDetailsPass();
  const { posContent } = usePos(currentForm);

  return (
    <InputPageWrapperStyled>
      <InputContainerStyled>
        <ResponsiveWrapper>
          {
            // Render on Mobile of width under 769px (mobile and small tabs)
            width <= breakpoint && (
              <MobileHeadingWrapper settings={settings} colors={colors}>
                <InputHeading colors={colors}>
                  {getJourneyText().title}
                </InputHeading>
                <InputSubtitle>{getJourneyText().subTitle}</InputSubtitle>
              </MobileHeadingWrapper>
            )
          }
          <InputWrapperStyled currentForm={currentForm}>
            {
              // Render on Mobile of width more than 768px (big tabs and desktop)
              width > breakpoint && (
                <InputInnerWrapperStyled>
                  <HeaderCard content={posContent} />
                </InputInnerWrapperStyled>
              )
            }
            <RenderInputForms
              posContent={posContent}
              currentForm={currentForm}
            />
          </InputWrapperStyled>
          <div className="sriyah__footer">
            <Footer />
          </div>
        </ResponsiveWrapper>
      </InputContainerStyled>
      <DefaultFooterStyled>
        <UibFooter />
        <BajajFooter />
      </DefaultFooterStyled>

      {/* Autofill data loader */}
      {loginLoading && <AutofillDetailsLoader />}
    </InputPageWrapperStyled>
  );
};

const InputPageWrapperStyled = styled.div``;

export default memo(InputPageInner);
