import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHandleBodyScroll from "src/customHooks/useHandleBodyScroll";
import {
  setDetailSectionAction,
  setSeeDetailsModalAction,
  setSeeDetailsQuoteAction,
} from "../slices/utility.slice";
export default function useSeeDetailsUtility() {
  const dispatch = useDispatch();
  const { seeDetails } = useSelector(state => state.utilities);
  const { hideBodyOverflow, unsetBodyOverflow } = useHandleBodyScroll();

  const seeDetailsModalOpen = seeDetails.isSeeDetailsModalOpen;
  const seeDetailsCurrentQuote = seeDetails.currentQuote;
  const seeDetailSection = seeDetails.section || "plan-details";

  // EFFECT/s
  useEffect(() => {
    if (seeDetailsModalOpen) hideBodyOverflow();
    else unsetBodyOverflow();

    return () => {};
  }, [seeDetailsModalOpen]);

  // FUNCTION/s
  const handleUpdateSeeDetailsModal = ({ payload, section }) => {
    dispatch(setDetailSectionAction(section));
    dispatch(setSeeDetailsQuoteAction(payload));
    dispatch(setSeeDetailsModalAction(true));
  };

  const handleCloseSeeDetailsModal = () => {
    dispatch(setSeeDetailsModalAction(false));
  };

  return {
    handleCloseSeeDetailsModal,
    handleUpdateSeeDetailsModal,
    seeDetailsModalOpen,
    seeDetailsCurrentQuote,
    seeDetailSection,
  };
}
