import { useLocation } from "react-router-dom";
import { useGetPoliciesQuery } from "../api/api";
import { useUrlQueries } from "./useUrlQuery";

const doNotCheckPoliciesUrl = [
  "/",
  "/input/basic-details",
  "/input/portability",
  "/input/renewal-details",
];

export const useCheckPolicies = () => {
  const searchQueries = useUrlQueries();

  const { pathname } = useLocation();

  const doNotCheckPolicies = doNotCheckPoliciesUrl.includes(pathname);

  const { data, isLoading, isUninitialized, isFetching, error, refetch } =
    useGetPoliciesQuery(undefined, {
      skip: !searchQueries.enquiryId || doNotCheckPolicies,
    });

  return {
    policiesData: data?.data,
    policiesLoading: isLoading,
    policiesUninitialized: isUninitialized,
    policiesFetching: isFetching,
    doNotCheckPolicies,
    searchQueries,
    policiesDataExist: !!data?.data,
    policiesError: error,
    policiesRefetch: refetch,
  };
};
