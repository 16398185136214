/* Typography Scale for HEALTH

  Base size: 16px
  Scale: 1.25 - Major Third
  Case Weight: 400

  Reference: https://typescale.com/?size=16&scale=1.250 => 1.125 Major Second
*/
import {
  colorMapping,
  typeMapping,
  variantsMapping,
  weightMapping,
} from "./typeUtilities";
import "./typography.css";

const FHTypography = ({
  variant,
  weight,
  colorType,
  color,
  type,
  children,
  className,
  ...props
}) => {
  const TypeVariantComp = variant ? variantsMapping[variant] || "p" : "p";
  const typeWeight = weight ? weightMapping[weight] : "normal";
  const typeRole = typeMapping[type];
  const colorTypeMap = colorMapping[colorType];
  const classes = className || "";

  return (
    <TypeVariantComp
      className={`
      typography--variant-${variant}
      typography--weight-${typeWeight}
      ${colorTypeMap ? "typography--color-" + colorTypeMap : ""}
      ${typeRole ? "typography--role-" + typeRole : ""}

      ${classes}
      `}
      style={{ color }}
      {...props}
    >
      {children}
    </TypeVariantComp>
  );
};

export default FHTypography;
