import axios from "axios";
import { onWhichPage } from "../utils/helper";
import { envAppBaseURL } from "../utils/tenantSettings";
import { getUrlQueries } from "src/utils/url.utility";

const defaultOptions = {
  headers: {},
  queryParams: null,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
};

export const restClient = axios.create();

restClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const httpClient = async (url = "", options = defaultOptions, enquiry_id) => {
  const urlQueryStrings = getUrlQueries();
  const baseUrl = envAppBaseURL();
  let fullPath = `${baseUrl}${url}`;
  const EnquiryId = enquiry_id || urlQueryStrings?.enquiryId;

  // when we have EnquiryId in url then we set it in header
  if (EnquiryId) {
    restClient.defaults.headers.common["Enquiry-Id"] = EnquiryId;
  }

  // setting up Requested-From-Page header
  restClient.defaults.headers.common["Requested-From-Page".toLowerCase()] =
    onWhichPage();

  return await restClient({
    ...options,
    url: fullPath,
    method: options.method || "GET",
    data: options.data,
  })
    .then(response => {
      return {
        data: response?.data,
        errors: response?.data?.errors,
        error: response?.data?.error,
        message: response?.message,
        statusCode: response?.status,
        success:
          (response?.status === 200 || response?.status === 201) &&
          response?.data?.status,
      };
    })
    .catch(err => {
      return {
        statusCode: err?.response?.status,
        data: err?.response?.data,
        success: false,
        message: err?.response?.data?.message,
        errors: err?.response?.data?.errors,
      };
    });
};

export default httpClient;
