import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useGetCartQuery } from "src/api/api";
import { useTheme, useUrlEnquiry } from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_ROW_CENTER, FONT, ROUNDED } from "src/styles/styleGuide";
import styled from "styled-components/macro";
import Spinner from "../Common/Spinner";

function CartToggleProductCTA({
  group,
  closeModal,
  allClose,
  setShowQuoteDeleteMsg,
  showQuoteDeleteMsg,
}) {
  const { colors } = useTheme();
  const history = useHistory();
  const { enquiryId } = useUrlEnquiry();
  const { data, isLoading, isUninitialized } = useGetCartQuery();
  const { currentGroupObject } = useGetEnquiry();

  if (isLoading || isUninitialized) return <Spinner />;

  let cartEntry = null;

  if (data.data) {
    cartEntry = data.data.find(cartEntry => cartEntry?.group?.id === group?.id);
  }

  function handleAddPlanClick() {
    closeModal && closeModal();
    allClose();
    history.push({
      pathname: `/quotes/${group?.id}`,
      search: `enquiryId=${enquiryId}&pincode=${currentGroupObject.pincode}&city=${currentGroupObject.city}`,
    });
  }

  if (!cartEntry)
    return (
      <AddPlanBtnStyled
        colors={colors}
        onClick={handleAddPlanClick}
        className={FONT.subheading1}
      >
        <span className="btn_text">Add Plan</span>
        <FaPlusCircle />
      </AddPlanBtnStyled>
    );

  return (
    <>
      {!showQuoteDeleteMsg && (
        <DeleteQuoteBtnStyled
          colors={colors}
          onClick={() => {
            setShowQuoteDeleteMsg(true);
          }}
        >
          <FaTrashAlt />
        </DeleteQuoteBtnStyled>
      )}
    </>
  );
}

//   STYLED
const AddPlanBtnStyled = styled.div`
  ${FLEX_ROW_CENTER};
  cursor: pointer;
  max-width: 120px;
  background: ${props => props.colors.primary_shade};
  color: ${props => props.colors.primary_color};
  border: none;
  border-radius: ${ROUNDED.md};
  padding: 0.3rem 0.8rem;
  .btn_text {
    margin-right: 5px;
  }
  ${VIEW.md} {
    padding: 10px;
  }
`;

const DeleteQuoteBtnStyled = styled.button`
  width: calc(1.5rem + 1.125vw);
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: ${props => props.colors.primary_shade};
  font-size: calc(0.8rem + 0.125vw);
  color: ${props => props.colors.primary_color};
  @media (max-width: 400px) {
    font-size: 0.8rem;
  }
`;

export default CartToggleProductCTA;
