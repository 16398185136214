import { checkTopup, isRenewals, isPortability } from "src/utils/checkJourney";
import { brokerTenant } from "src/utils/tenantSettings";

// ======= CONSTANTS =========
export const journeyTitle = {
  top_up: `${process.env.REACT_APP_TOPUP_TITLE} Insurance`,
  health: "Health Insurance",
  renew: "Renew Policy",
  portability: "Port your Health Policy",
};

export const journeySubTitle = {
  top_up: `Buy ${process.env.REACT_APP_TOPUP_TITLE} insurance plan in a few simple steps`,
  health: "Buy Health insurance plan in a few simple steps",
  renew: "Renew Policy plan in a few simple steps",
  portability:
    "Feeling limited by your current Health Insurance Plan? Port your Health Insurance Policy Now",
};

// FUNCTION/s
export const getJourneyText = () => {
  const journeyType = checkTopup()
    ? "top_up"
    : isRenewals()
    ? "renew"
    : isPortability()
    ? "portability"
    : "health";

  return {
    title: journeyTitle[journeyType] || "",
    subTitle: journeySubTitle[journeyType] || "",
  };
};

export const planArray = journeyType => {
  return brokerTenant("sriyah")
    ? [
        `Get plan recommendation in seconds`,
        `Compare ${getJourneyText().title} plans`,
        `Instant policy purchase`,
        `Free claims assistance`,
      ]
    : journeyType === "portability"
    ? [
        `Switch plans seamlessly - No restarts on waiting periods`,
        `Keep your No Claim Bonus - Your loyalty is rewarded`,
        `Focus on health, not paperwork - Portability simplifies switching`,
      ]
    : [
        `Compare ${getJourneyText().title} plans`,
        `Instant policy Issuance`,
        `Free claims assistance`,
        `Get plan recommendation in seconds`,
      ];
};
