import { Redirect } from "react-router-dom";

import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { isRenewals } from "../utils/checkJourney";
import { brokerAppPhase, brokerTenant } from "../utils/tenantSettings";
import FullScreenLoader from "./Common/FullScreenLoader";

export function BaseComponent() {
  const {
    data,
    query: { isUninitialized, isLoading },
    subJourneyType,
  } = useFrontendBoot();

  const location = window.location;

  if (isUninitialized || isLoading) {
    return <FullScreenLoader />;
  }

  if (brokerTenant("renew_buy") && brokerAppPhase("PROD")) {
    // other broker routing changes
    if (isRenewals()) {
      return <Redirect to={`/input/renewal-details`} />;
    }

    if (subJourneyType === "port") {
      return <Redirect to={`/input/basic-details${location.search}`} />;
    }
    return <Redirect to={`/input/basic-details${location.search}`} />;
  } else {
    // other broker routing changes \
    switch (location.host) {
      case data?.tenant?.health_renewal_frontend_domain:
        return <Redirect to={`/input/renewal-details`} />;
      case data?.tenant?.health_port_frontend_domain:
        return <Redirect to={`/input/basic-details${location.search}`} />;
      default:
        return <Redirect to={`/input/basic-details${location.search}`} />;
    }
  }
}
