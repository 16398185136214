import styled from "styled-components";
import { useState } from "react";
import { RiCloseFill } from "react-icons/ri";
export default function ChangeBroker({ handleClose }) {
  const [values, setValues] = useState({
    baseURI: "",
    tenant: "",
    appPhase: "",
    quotesCount: "",
  });

  const onSubmitHandler = event => {
    event.preventDefault();
    sessionStorage.setItem("brokerApi", values.baseURI);
    sessionStorage.setItem("brokerTenant", values.tenant);
    sessionStorage.setItem("brokerAppPhase", values.appPhase);
    sessionStorage.setItem("QuotesCount", values.quotesCount);

    window.location.reload();
  };

  const onChangeHandler = event => {
    switch (event.target.name) {
      case "baseURI":
        return setValues({ ...values, baseURI: event.target.value });
      case "tenant":
        return setValues({ ...values, tenant: event.target.value });
      case "quotesCount":
        return setValues({ ...values, quotesCount: event.target.value });
      default:
        return setValues({ ...values, appPhase: event.target.value });
    }
  };

  return (
    <ChangeBrokerStyled>
      <div className="modal_backdrop_main" onClick={handleClose}></div>
      <form id="brokerChange" onSubmit={onSubmitHandler}>
        <section>
          <h1>Change Broker Settings</h1>{" "}
          <RiCloseFill className="close" size={24} onClick={handleClose} />
        </section>
        <label htmlFor="baseURI">
          BaseURI:
          <input
            type="text"
            name="baseURI"
            id="baseURI"
            onChange={onChangeHandler}
            value={values.baseURI}
            required
          />
        </label>
        <label htmlFor="tenant">
          Tenant:
          <input
            type="text"
            name="tenant"
            id="tenant"
            onChange={onChangeHandler}
            value={values.tenant}
            required
          />
        </label>
        <label htmlFor="appPhase">
          App Phase:
          <input
            type="text"
            name="appPhase"
            id="appPhase"
            onChange={onChangeHandler}
            value={values.appPhase}
            required
          />
        </label>
        <label htmlFor="quotesCount">
          Quotes Count:
          <input
            type="text"
            name="quotesCount"
            id="quotesCount"
            onChange={onChangeHandler}
            value={values.quotesCount}
          />
        </label>
        <button type="submit">Continue</button>
      </form>
    </ChangeBrokerStyled>
  );
}

const ChangeBrokerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .modal_backdrop_main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #7777;
  }

  form {
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    z-index: 10;

    section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .close {
        cursor: pointer;
      }
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 10px;
    }

    label {
      font-size: 1.2rem;
      text-transform: capitalize;
      font-weight: 600;
    }

    input {
      margin-left: 10px;
      border: 1px solid #6666;
      border-radius: 3px;
      padding: 2px 6px;
    }

    button {
      padding: 5px 15px;
      border-radius: 3px;
      font-size: 1rem;
      background-color: #666;
      color: white;
      justify-self: flex-end;
      align-self: flex-end;
      margin-top: 20px;
    }
  }
`;
