// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import { PlansTabStyled } from "./PlanDetails.style";

export default function PlansTab({ isActive, title, description, onClick }) {
  const { colors } = useTheme();

  return (
    <PlansTabStyled
      onClick={onClick}
      data-tab-active={isActive}
      colors={colors}
    >
      <FHTypography variant="h5" weight="md" className="plans-title">
        {title}
      </FHTypography>
      <FHTypography variant="subheading1" weight="base" colorType="body">
        {description}
      </FHTypography>
    </PlansTabStyled>
  );
}
