import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    errorPopup: {
      type: "",
      isOn: false,
    },
  },
  reducers: {
    showErrorPopup(state, { payload }) {
      state.errorPopup.type = payload?.type;
      state.errorPopup.isOn = true;
    },
    hideErrorPopup(state) {
      state.errorPopup.isOn = false;
      state.errorPopup.type = "";
    },
  },
});

export const { showErrorPopup, hideErrorPopup } = errorSlice.actions;

export default errorSlice.reducer;
