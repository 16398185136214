import { useEffect, useState } from "react";

export default function useSeeDetailsTabs(defaultActiveKey) {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const scrollClick = direction => {
    if (direction === "left") {
      document.querySelector("#tab-wrapper").scrollLeft -= 120;
    } else {
      document.querySelector("#tab-wrapper").scrollLeft += 120;
    }
  };

  const [scrollableDivRef, setScrollableDivRef] = useState(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const handleScroll = () => {
    if (scrollableDivRef) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollableDivRef;
      const scrollPercentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;

      setShowLeftButton(scrollPercentage >= 20);
      setShowRightButton(scrollPercentage <= 80);
    }
  };

  useEffect(() => {
    handleScroll();

    if (scrollableDivRef) {
      scrollableDivRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableDivRef) {
        scrollableDivRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollableDivRef]);

  const selectedTabOffset =
    document.querySelector(`#${activeKey}`)?.offsetLeft / 2 +
    PRODUCT_DETAILS_TABS.findIndex(tab => tab.key == activeKey) * 5;

  useEffect(() => {
    if (window.innerWidth < 1024) {
      document.querySelector("#tab-wrapper").scrollLeft = selectedTabOffset;
    }
  }, [selectedTabOffset]);

  return {
    showLeftButton,
    scrollClick,
    setActiveKey,
    showRightButton,
    scrollableDivRef,
    activeKey,
    setScrollableDivRef,
  };
}

export const PRODUCT_DETAILS_TABS = [
  { key: "plan-details", title: "Plan Details" },
  { key: "add-on-coverages", title: "Add On Coverages" },
  { key: "cashless-hospitals", title: "Cashless Hospitals" },
  { key: "claim-process", title: "Claim Process" },
  { key: "about-company", title: "About Company" },
];
