import { useParams, useRouteMatch } from "react-router-dom";
import { handleCommunicationRequest } from "../shareModule.api";
import useGetQuotesForCanvas from "./useGetQuotesForCanvas";
import useDownloadPDF from "src/customHooks/useDownloadPDF";
import { useSelector } from "react-redux";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";

function useQuotesSharing() {
  const { groupCode } = useParams();
  const { getQuotationImage } = useGetQuotesForCanvas();
  const { quotes, selectedSectionView } = useSelector(
    state => state.globalComponent.shareModule,
  );

  // Pages
  const isShortlistRoute = useRouteMatch({ path: "/shortlisted" });
  const isQuoteRoute = useRouteMatch({ path: "/quotes" });
  const isComparePageRoute = useRouteMatch({ path: "/compare" });

  const { downloadComparePDF } = useDownloadPDF(
    { quotes, selectedSectionView },
    "share",
  );
  // condition for UIB 
  const { tenant } = useFrontendBoot()

  // FUNCTION/s
  async function handleElementQuotesShareClick({ ele, data, shareModule }) {

    try {
      const imageB64 = await getQuotationImage();
      const requestObject = {
        stage: shareModule.stage,
        email: data.email,
        sms: data.sms,
        whatsapp: data.whatsapp,
        mode: [ele.toUpperCase()],
      };
      if (isShortlistRoute || isQuoteRoute || isComparePageRoute) {
        requestObject.image_to_send = imageB64 || "";
        requestObject.groupCode = parseInt(groupCode);
      }

      if (isComparePageRoute && tenant.alias == "uib" ) {
        requestObject.compare_pdf_link =
          (await downloadComparePDF(false)) || "";
        const response = await handleCommunicationRequest(requestObject);
        return response;
      }

      const response = await handleCommunicationRequest(requestObject);

      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  return { handleElementQuotesShareClick };
}

export default useQuotesSharing;
