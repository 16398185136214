import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CanvasQuoteCard from "./CanvasQuoteCard";
import { useTheme } from "src/customHooks";
import { FLEX_COL, FLEX_ROW, ROUNDED, SHADES } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import useGetQuotesForCanvas from "../hooks/useGetQuotesForCanvas";
import {
  CANVAS_HEADER_HEADING_TEXT,
  SHARE_MODULE_STAGES,
} from "../shareModule.constant";
import useShortlistedPlans from "src/pages/ShortlistedPage/hooks/useShortlistedPlans";
import { useParams } from "react-router-dom";
import useShareModal from "../hooks/useShareModal";

function CanvasQuotesList() {
  const { colors } = useTheme();
  const { groupCode } = useParams();
  const { shareModule } = useShareModal();
  const { getPlanByGroup } = useShortlistedPlans();
  const { baseCombinedQuotes } = useGetQuotesForCanvas();
  const shortlistedQuotes = getPlanByGroup(groupCode);

  //STATE/s
  const [canvasQuotesList, setCanvasQuotesList] = useState([]);

  // EFFECT/s
  // Set canvasQuotesList based on shareModule.stage
  useEffect(() => {
    if (canvasQuotesList.length !== 0) return () => {};
    if (shareModule.stage === SHARE_MODULE_STAGES.shortlisted) {
      setCanvasQuotesList(shortlistedQuotes);
    }
    if (shareModule.stage === SHARE_MODULE_STAGES.quote) {
      const extractFirstQuote = baseCombinedQuotes.map(quote => {
        return quote[0][0];
      });
      setCanvasQuotesList(extractFirstQuote);
    }
    return () => {};
  }, [shareModule.stage, shortlistedQuotes]);

  return (
    <CanvasContainerStyled>
      <div id="share-quotation" className="canvas">
        <CanvasQuotesHeaderStyled colors={colors}>
          {CANVAS_HEADER_HEADING_TEXT.map(text => (
            <FHTypography
              variant="subheading1"
              className="canvasHeadText"
              key={text}
            >
              {text}
            </FHTypography>
          ))}
        </CanvasQuotesHeaderStyled>
        <CanvasQuotesListStyled>
          {canvasQuotesList?.map((quote, index) => (
            <CanvasQuoteCard key={index} colors={colors} quote={quote} />
          ))}
        </CanvasQuotesListStyled>
      </div>
    </CanvasContainerStyled>
  );
}

const CanvasQuotesListStyled = styled.div`
  ${FLEX_COL};
  width: 100%;
  gap: 1rem;
`;

const CanvasQuotesHeaderStyled = styled.div`
  ${FLEX_ROW}
  padding: 0.8rem 0.3rem;
  align-items: center;
  justify-content: space-between;
  color: ${SHADES.WHITE};
  width: 100%;
  background-color: ${({ colors }) => colors.primary_color};
  border-radius: ${ROUNDED.md};

  .canvasHeadText:first-child {
    width: 40%;
  }
`;

const CanvasContainerStyled = styled.div`
  width: 600px;
  position: absolute;
  left: -99999px;
  z-index: 9999999;
`;

export default CanvasQuotesList;
