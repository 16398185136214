import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { formatCurrency, mergeQuotes, onWhichPage } from "../utils/helper";
import { setLoadedQuotesLength } from "src/pages/NewQuotePage/QuotePageSlice/quote.slice";

const { REACT_APP_API_BASE_URL: baseUrl } = process.env;

function urlQueriesEnquiry() {
  const queries = window.location.search?.split("?")[1];
  const paramsList = queries?.split("&");
  const paramsObject = {};
  paramsList?.length &&
    paramsList?.forEach(data => {
      const value = data.search("=");
      const itemName = data.slice(0, value);
      const itemValue = data.slice(value + 1, data.length);
      paramsObject[itemName] = itemValue;
    });

  if (paramsObject?.enquiryId?.includes(",")) {
    return paramsObject.enquiryId.split(",")[0];
  } else {
    return paramsObject.enquiryId;
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.NODE_ENV === "development"
      ? sessionStorage.getItem("brokerApi") || baseUrl
      : baseUrl,
  prepareHeaders: headers => {
    const EnquiryId = urlQueriesEnquiry() || null;
    const pageName = onWhichPage();
    if (EnquiryId) {
      headers.set("Enquiry-Id", EnquiryId);
    }
    headers.set("Requested-From-Page", pageName);

    return headers;
  },
});

//* API UTIL METHODS
//? dispatch(api.util.invalidateTags([<tag_1>,<tag_2>])) : to refetch the query.
//? dispatch(api.util.updateQueryData()) : to update the query data "LOCAL-STATE".
//? dispatch(api.util.resetApiState())  : to reset entire rtkQuery data.

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: [
    "Filter",
    "Quote",
    "Enquiry",
    "Rider",
    "Cart",
    "AdditionalDiscount",
    "TenureDiscount",
    "ProposalSummaryUpdate",
    "featureOption",
    "custom_quotes",
    "recommendation",
    "city_list",
    "questions",
    "enabled_products",
    "shortlist",
  ],
  endpoints: builder => ({
    // recommendation apis
    getRecommendationCityList: builder.query({
      query: () => ({
        url: `recommendation-network-hospital-city`,
      }),
      providesTags: ["recommendation", "city_list"],
    }),

    getSearchNetworkHospital: builder.query({
      query: ({ hospitalName, selectedCity }) => ({
        url: `recommendation-network-hospitals?hospital=${hospitalName}&city=${selectedCity}`,
      }),
    }),

    getRecommendationQuestions: builder.query({
      query: ({ groupCode }) => ({
        url: `recommendation-question?group=${groupCode}`,
      }),
      providesTags: ["recommendation", "questions"],
    }),

    getRecommendationPlans: builder.query({
      query: () => ({
        url: `recommend-plan`,
      }),
    }),

    getRecommendedQuotes: builder.query({
      query: ({ groupCode }) => ({
        url: `recommendation-quotes?group=${groupCode}`,
      }),
    }),
    getRecommendedQuotesApi: builder.mutation({
      query: ({ groupCode, answers }) => ({
        url: `recommend-plan?group=${groupCode}`,
        body: answers,
        method: "PATCH",
      }),
    }),

    getCities: builder.mutation({
      query: ({ searchQuery }) => ({
        url: `location-details?search=${searchQuery}`,
      }),
    }),
    getCart: builder.query({
      query: () => ({ url: `cart-items` }),
      providesTags: ["Cart"],
    }),
    getCkycReverification: builder.query({
      query: () => ({ url: `ckyc-reverification` }),
    }),
    getRecalculatePremium: builder.query({
      query: () => ({
        url: `cart-items?recalculate_premium`,
      }),
    }),
    createCart: builder.mutation({
      query: body => ({
        url: `cart-items`,
        body,
        method: "POST",
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: { data: cartEntry },
          } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData("getCart", undefined, cart => {
              const cartEntryExist = cart.data.some(
                cachedCartEntry =>
                  cachedCartEntry.group?.id === cartEntry.group?.id,
              );

              const updatedCart = {
                ...cart,
                data: cartEntryExist
                  ? cart.data.map(cachedCartEntry => {
                      const {
                        group: { id },
                      } = cachedCartEntry;
                      if (id === cartEntry.group?.id) return cartEntry;
                      return cachedCartEntry;
                    })
                  : [...cart.data, cartEntry],
              };
              Object.assign(cart, updatedCart);
            }),
          );
        } catch (error) {
          return error;
        }
      },
    }),
    deleteCart: builder.mutation({
      query: cartId => ({ url: `/cart-items/${cartId}`, method: "DELETE" }),
      onQueryStarted: (cartId, { dispatch, queryFulfilled }) => {
        const deleteCartAction = dispatch(
          api.util.updateQueryData("getCart", undefined, cart => {
            Object.assign(cart, {
              ...cart,
              data: cart.data.filter(
                cachedCartEntry => cachedCartEntry.id !== cartId,
              ),
            });
          }),
        );
        queryFulfilled.catch(deleteCartAction.undo);
      },
    }),

    getAdditionalDiscounts: builder.query({
      query: ({
        productId,
        groupCode,
        sum_insured,
        tenure,
        subJourneyType,
      }) => {
        let endpoint = "additional-discounts";
        if (subJourneyType === "renewal") {
          endpoint = "renewal-additional_discounts";
        }
        return {
          url: `products/${productId}/${endpoint}?group=${groupCode}&sum_insured=${sum_insured}&tenure=${tenure}`,
        };
      },
      providesTags: ["AdditionalDiscount"],
    }),
    getEnquiries: builder.query({
      query: () => ({ url: `enquiries` }),
      providesTags: ["Filter", "Enquiry"],
    }),
    getPolicies: builder.query({
      query: () => ({ url: `/policies` }),
    }),
    getKYCStatuses: builder.query({
      query: () => ({ url: `/kyc-statuses` }),
    }),
    getFeedback: builder.query({
      query: () => ({ url: `/rating_feedback/show` }),
    }),
    getProposers: builder.query({
      query: () => ({ url: `/proposers` }),
    }),
    sendFeedbackEmail: builder.query({
      query: () => ({ url: `/rating_feedback/send_email` }),
    }),
    updateShortlistedQuotes: builder.mutation({
      query: (quotes = []) => ({
        url: `/shortlists`,
        method: "PUT",
        body: {
          shortlisted_quotes: quotes,
        },
      }),
    }),
    getShortlistedQuotes: builder.query({
      query: () => ({ url: `/shortlists` }),
      providesTags: ["shortlist"],
    }),
    getQuotes: builder.query({
      query: ({
        alias,
        sum_insured_range,
        tenure,
        plan_type,
        group,
        base_plan_type = "base_health",
        journeyType = "quotes",
        deductible = 0,
      }) => {
        const endpoint = journeyType === "top_up" ? "topup-quotes" : "quotes";

        let url = `companies/${alias}/${endpoint}?sum_insured_range=${sum_insured_range}&tenure=${tenure}&plan_type=${plan_type}&group=${group}&base_plan_type=${base_plan_type}`;

        if (journeyType === "top_up") {
          url = url.concat(`&deductible=${deductible}`);
        }

        return {
          url,
        };
      },
      providesTags: ["Quote"],
    }),
    getFrontendBoot: builder.query({
      query: () => ({
        url: `frontend-boot`,
      }),
      transformResponse: res => {
        return {
          ...res,
          deductibles: res.deductibles.map(deductible => ({
            ...deductible,
            display_name: formatCurrency(deductible.code),
          })),
        };
      },
    }),
    updateGroups: builder.mutation({
      query: ({ groupCode, ...body }) => ({
        url: `groups/${groupCode}`,
        body,
        method: "PUT",
      }),

      onQueryStarted: async ({ groupCode, extras }, { dispatch }) => {
        dispatch(
          api.util.updateQueryData(
            "getEnquiries",
            undefined,
            draftEnquiries => {
              Object.assign(draftEnquiries, {
                ...draftEnquiries,
                data: {
                  ...draftEnquiries.data,
                  groups: draftEnquiries.data.groups.map(group => {
                    if (group?.id !== parseInt(groupCode)) return group;
                    return { ...group, extras };
                  }),
                },
              });
            },
          ),
        );
      },
    }),
    createEnquiry: builder.mutation({
      query: body => ({
        url: `/enquiries`,
        method: "POST",
        body,
      }),
      onQueryStarted: async (_data, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData("getEnquiries", undefined, draft => {
            if (draft) Object.assign(draft, data);
          }),
        );
      },
    }),
    createEnquiryNew: builder.mutation({
      query: body => ({
        url: `/enquiries`,
        method: "POST",
        body,
      }),
    }),
    submitFeedback: builder.mutation({
      query: body => ({
        url: `/rating_feedback/store`,
        method: "POST",
        body,
      }),
      onQueryStarted: async (_data, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          api.util.updateQueryData("getEnquiries", undefined, draft => {
            if (draft) Object.assign(draft, data);
          }),
        );
      },
    }),
    getEnabledProducts: builder.query({
      query: ({ company, group }) => {
        return {
          url: "/enabled-products?company_alias=" + company + "&group=" + group,
        };
      },
      providesTags: ["enabled_products"],
    }),
    getRiders: builder.query({
      query: ({
        productId,
        sum_insured,
        deductible,
        tenure,
        group,
        selected_riders = [],
        additionalUrlQueries = "",
        selectedRiderMemberUrlQueries = "",
        feature_options_query = "",
        selected_additional_discounts = [],
        journeyType = "health",
        subJourneyType = "",
        isPortPlan = false,
        additional_discount_policy_number = "",
      }) => {
        let endpoint = "riders";

        if (journeyType === "top_up" && !isPortPlan) {
          endpoint = "top_up-riders";
        }

        if (subJourneyType === "renewal") {
          endpoint = "renewal-riders";
        }

        if (journeyType === "top_up" && subJourneyType === "renewal") {
          endpoint = "top_up-renewal-riders";
        }

        let url = `products/${productId}/${endpoint}?sum_insured=${sum_insured}&tenure=${tenure}&group=${group}`;

        if (selected_riders.length)
          url = url.concat(`&selected_riders=${selected_riders.join(",")}`);

        if (deductible) url = url.concat(`&deductible=${deductible}`);

        if (selected_additional_discounts?.length) {
          url = url.concat(
            `&selected_additional_discounts=${selected_additional_discounts.join(
              ",",
            )}`,
          );
        }

        if (additional_discount_policy_number.length) {
          url = url.concat(
            `&additional_discount_policy_number=${additional_discount_policy_number}`,
          );
        }

        if (feature_options_query) {
          url = url.concat(`&${feature_options_query}`);
        }

        if (additionalUrlQueries) url = url.concat(`&${additionalUrlQueries}`);
        if (selectedRiderMemberUrlQueries?.length > 0)
          url = url.concat(`&${selectedRiderMemberUrlQueries}`);

        return {
          url,
        };
      },
      providesTags: ["Rider"],
      keepUnusedDataFor: 0.0001,
    }),
    deleteGroup: builder.query({
      query: groupId => ({ url: `groups/${groupId}`, method: "DELETE" }),
    }),
    getDiscounts: builder.query({
      query: ({
        product_id,
        sum_insured,
        group,
        deductible,
        feature_options = "",
        journeyType = "health",
        subJourneyType = "",
        isPortPlan = false,
        ageWiseBasePremium = false,
        discountAlias = "",
        additional_discount_policy_number = "",
      }) => {
        let url = `products/${product_id}/discounts?sum_insured=${sum_insured}&group=${group}&${feature_options}${
          discountAlias ? `&discounts=${discountAlias}` : ""
        }${
          additional_discount_policy_number
            ? `&additional_discount_policy_number=${additional_discount_policy_number}`
            : ""
        }`;

        if (journeyType === "top_up" && !isPortPlan) {
          url = `products/${product_id}/topup-discounts?sum_insured=${sum_insured}&group=${group}&deductible=${deductible}`;
        }
        if (subJourneyType === "renewal") {
          url = `products/${product_id}/renewal-discounts?sum_insured=${sum_insured}&group=${group}&deductible=${deductible}&${feature_options}`;
        }
        if (journeyType === "top_up" && subJourneyType === "renewal") {
          url = `products/${product_id}/topup_renewal-discounts?sum_insured=${sum_insured}&group=${group}&deductible=${deductible}`;
        }
        if (ageWiseBasePremium) {
          url = `products/${product_id}/discounts?sum_insured=${sum_insured}&group=${group}&age_bracket2=true&age_bracket1=true&${feature_options}`;
        }
        return {
          url,
        };
      },
      providesTags: ["TenureDiscount"],
      keepUnusedDataFor: "1",
    }),
    updateCart: builder.mutation({
      query: ({ cartId, ...body }) => ({
        url: `/cart-items/${cartId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Cart"],
    }),
    updateEnquiry: builder.mutation({
      query: body => ({
        url: `/enquiries`,
        method: "PATCH",
        body,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData("getEnquiries", undefined, draft => {
            Object.assign(draft, res.data);
          }),
        );
      },
    }),
    updatePosCover: builder.mutation({
      query: body => ({
        url: "seller_details/change/pos_to_nonpos",
        method: "POST",
        body,
      }),
      onQueryStarted: async ({ queryFulfilled }) => {
        const data = await queryFulfilled;
        return data;
      },
    }),
    updateGroupMembers: updateGroupMembersQueryBuilder(builder),
    getNetworkHospitals: builder.query({
      query: company_alias => `companies/${company_alias}/network-hospitals`,
    }),
    getClaimProcess: builder.query({
      query: company_id => `companies/${company_id}/claim_processes`,
    }),
    getProductBrochure: builder.query({
      query: product_id => `products/${product_id}/brochure`,
    }),
    getAboutCompany: builder.query({
      query: company_id => `companies/${company_id}/about`,
    }),
    getProductFeatures: builder.query({
      query: product_id => `products/${product_id}/features`,
    }),
    getLocationDetails: builder.query({
      query: ({ search }) => `location-details?search=${search}`,
    }),
    getQuote: builder.query({
      query: args => {
        const {
          insurer,
          sum_insured_range,
          tenure,
          plan_type,
          group,
          base_plan_type = "base_health",
          journeyType = "health",
          deductible = 0,
        } = args;

        const endpoint = journeyType === "top_up" ? "topup-quotes" : "quotes";

        let url = `companies/${insurer}/${endpoint}?sum_insured_range=${sum_insured_range}&tenure=${tenure}&plan_type=${plan_type}&group=${group}&base_plan_type=${base_plan_type}`;

        if (journeyType === "top_up") {
          url = url.concat(`&deductible=${deductible}`);
        }

        return {
          url,
        };
      },
    }),
    getCustomQuotes: builder.query({
      queryFn: async (
        args,
        { dispatch },
        _extraOptions,
        fetchWithBaseQuery,
      ) => {
        const {
          insurers = [],
          sum_insured_range,
          tenure,
          plan_type,
          group,
          base_plan_type = "base_health",
          journeyType = "health",
          deductible = 0,
          only_product,
          isOffline = false,
        } = args;
        async function getQuotes(insurer) {
          const endpoint =
            base_plan_type === "port_plan"
              ? "quotes"
              : journeyType === "top_up"
              ? "topup-quotes"
              : "quotes";

          let url = `companies/${insurer}/${endpoint}?tenure=${tenure}${
            sum_insured_range !== "no_preference"
              ? "&sum_insured_range=" + sum_insured_range
              : ""
          }&plan_type=${plan_type}&group=${group}&base_plan_type=${base_plan_type}`;

          let apiConfig = {};

          if (only_product) {
            url = `companies/${insurer}/${endpoint}?tenure=${tenure}${
              sum_insured_range !== "no_preference"
                ? "&sum_insured_range=" + sum_insured_range
                : ""
            }&plan_type=${plan_type}&group=${group}&base_plan_type=${base_plan_type}&only_product=${only_product}`;
          }

          if (only_product && journeyType === "top_up") {
            url = url.concat(`&deductible=${deductible}`);
          }

          if (journeyType === "top_up" && base_plan_type !== "port_plan") {
            url = url.concat(`&deductible=${deductible}`);
          }

          apiConfig = { url };
          if (isOffline) {
            apiConfig = { ...apiConfig, headers: { "IS-OFFLINE": "" } };
          }
          return fetchWithBaseQuery(apiConfig).then(res => {
            if (res.data) {
              dispatch(
                api.util.updateQueryData("getCustomQuotes", args, draft => {
                  Object.assign(draft, [
                    ...draft,
                    { company_alias: insurer, data: res.data, status: true },
                  ]);
                }),
              );
            } else {
              dispatch(
                api.util.updateQueryData("getCustomQuotes", args, draft => {
                  Object.assign(draft, [
                    ...draft,
                    {
                      company_alias: insurer,
                      data: null,
                      status: false,
                    },
                  ]);
                }),
              );
            }
          });
        }

        Promise.all(
          insurers.map((insurer, index) => {
            return sessionStorage.QuotesCount
              ? index < parseInt(sessionStorage.QuotesCount) &&
                  getQuotes(insurer)
              : getQuotes(insurer);
          }),
        ).then(val => {
          const fetchedQuotes = val?.flatMap(data => data?.data?.data) || [];
          const quotesArray = [];
          fetchedQuotes.map(quoteValue => {
            const isAlreadyCombined = quotesArray.includes(
              quoteValue?.product?.id,
            );
            if (!isAlreadyCombined) {
              quotesArray.push(quoteValue?.product?.id);
            }
          });
          dispatch(setLoadedQuotesLength(quotesArray.length));
        });
        return { data: [] };
      },
      providesTags: ["custom_quotes"],
      keepUnusedDataFor: 900,
    }),
    updateCompareQuotes: builder.mutation({
      query: body => ({
        url: "/comparisons",
        body,
        method: "PUT",
      }),
      onQueryStarted: (args, { dispatch }) => {
        dispatch(
          api.util.updateQueryData("getCompareQuotes", undefined, draft => {
            Object.assign(draft, { data: args });
          }),
        );
      },
    }),
    getCompareQuotes: builder.query({
      query: () => `/comparisons`,
    }),
    getCompareFeatures: builder.query({
      query: productId => `products/${productId}/features`,
    }),
    getProposalData: builder.query({
      query: () => ({ url: "health/proposals" }),
      //providesTags: ["ProposalSummaryUpdate"],
    }),
    saveProposal: builder.mutation({
      query: body => ({
        url: `/health/proposals`,
        method: "POST",
        body,
      }),
    }),
    saveProposerPan: builder.mutation({
      query: body => ({
        url: `/proposers`,
        method: "PATCH",
        body,
      }),
    }),
    getTopUpAddOns: builder.query({
      queryFn: async (
        { sum_insured, groupCode, tenure, companies = [], insurance_type },
        { dispatch },
        _extraOptions,
        fetchWithBaseQuery,
      ) => {
        const topUpUrls = companies.map(company_alias =>
          quoteUrl(company_alias, insurance_type, {
            sum_insured,
            groupCode,
            tenure,
          }),
        );

        const topUpQuotesResponses = await getQuotePromises(
          topUpUrls,
          fetchWithBaseQuery,
          { afterEach: () => {} },
        );

        const topUpQuotes = topUpQuotesResponses
          .map(quotesResponse => quotesResponse.data?.data)
          .map(quotes => mergeQuotes(quotes));

        dispatch;
        return {
          data: topUpQuotes,
        };
      },
    }),
    updateRenewalQuery: builder.mutation({
      query: ({
        company_alias,
        policy_no,
        expiry_date,
        date_of_birth,
        section = "renewal",
      }) => {
        return {
          url: "renewal-enquiries",
          body: {
            company_alias,
            policy_no,
            expiry_date,
            date_of_birth,
            section,
          },
          method: "POST",
        };
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          dispatch(
            api.util.updateQueryData("getEnquiries", undefined, draft => {
              //?draft : the targeted object i.e. getEnquires object from store.
              return Object.assign(draft, res?.data);
              //? assign(targetObject , newObject)<updated object>
            }),
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getRenewalSumInsureds: builder.query({
      query: ({
        product_id,
        groupCode,
        tenure,
        journeyType,
        subJourneyType,
      }) => {
        let endpoint = `renewal-suminsureds`;
        if (journeyType === "top_up" && subJourneyType === "renewal") {
          endpoint = `top_up-renewal-suminsureds`;
        }
        return `products/${product_id}/${endpoint}?group=${groupCode}&tenure=${tenure}`;
      },
    }),
    getFeatureOptions: builder.query({
      query: ({
        productId,
        sumInsured,
        groupCode,
        tenure,
        featureDependentRiders = "",
      }) => {
        const { riderAlias, featureAlias } = featureDependentRiders;
        if (riderAlias && featureAlias) {
          return {
            url: `product/${productId}/options?sum_insured=${sumInsured}&group=${groupCode}&tenure=${tenure}&feature_dependent_riders=${riderAlias}&dependent_features=${featureAlias}`,
          };
        }
        return {
          url: `product/${productId}/options?sum_insured=${sumInsured}&group=${groupCode}&tenure=${tenure}`,
        };
      },
      providesTags: ["featureOption"],
    }),
    getProposalSchema: builder.query({
      query: ({ enquiryId }) => `proposal-fields?enquiryId=${enquiryId}`,
      keepUnusedDataFor: 2,
    }),
    getRenewalKycSchema: builder.query({
      query: ({ enquiryId }) => `renewals/kyc-fields?enquiryId=${enquiryId}`,
    }),
    sendOtp: builder.query({
      query: ({ tenantAlias, name, mobile, email }) =>
        `otp_verification/${tenantAlias}/send_otp?name=${name}&mobile=${mobile}&email=${email}`,
    }),
    verifyOtp: builder.query({
      query: ({ tenantAlias, otp, uuid = "" }) =>
        `otp_verification/${tenantAlias}/verify_otp?${
          uuid && `uuid=${uuid}&`
        }otp=${otp}`,
    }),
    callApi: builder.mutation({
      query: ({ url, body, method }) => ({
        url,
        body,
        method,
      }),
    }),
  }),
});

function getQuotePromises(urls, fetchWithBaseQuery, { afterEach } = {}) {
  return Promise.all(
    urls.map(url =>
      fetchWithBaseQuery(url).then(res => {
        afterEach && afterEach(res);
        return res;
      }),
    ),
  );
}

const quoteEndpoints = {
  top_up: "topup-quotes",
  critical_illness: "critical-illness-quotes",
  cancer: "cancer-quotes",
};

function getQuoteEndpoint(insurance_type) {
  return quoteEndpoints[insurance_type];
}

function quoteUrl(
  company_alias,
  insurance_type,
  { sum_insured, tenure = 1, groupCode } = {},
) {
  const quoteEndpoint = getQuoteEndpoint(insurance_type);
  let url = `companies/${company_alias}/${quoteEndpoint}?tenure=${tenure}&group=${groupCode}`;

  if (insurance_type === "top_up") {
    url = url.concat(`&deductible=${sum_insured}`);
    return url;
  }

  if (sum_insured) {
    url = url.concat(`&sum_insured=${sum_insured}`);
    return url;
  }

  return url;
}

export const {
  useUpdatePosCoverMutation,
  useGetRecommendationCityListQuery,
  useGetRecommendationQuestionsQuery,
  useGetRecommendationPlansQuery,
  useGetRecommendedQuotesQuery,
  useGetRecommendedQuotesApiMutation,
  useGetSearchNetworkHospitalQuery,
  useGetCitiesMutation,
  useGetCartQuery,
  useGetCkycReverificationQuery,
  useGetRecalculatePremiumQuery,
  useGetAdditionalDiscountsQuery,
  useGetQuotesQuery,
  useGetEnquiriesQuery,
  useGetFrontendBootQuery,
  useUpdateGroupsMutation,
  useCreateCartMutation,
  useSaveProposalMutation,
  useSaveProposerPanMutation,
  useDeleteCartMutation,
  useDeleteGroupQuery,
  useCreateEnquiryMutation,
  useCreateEnquiryNewMutation,
  useSubmitFeedbackMutation,
  useGetRidersQuery,
  useGetDiscountsQuery,
  useLazyGetDiscountsQuery,
  useUpdateCartMutation,
  useUpdateEnquiryMutation,
  useUpdateGroupMembersMutation,
  useGetNetworkHospitalsQuery,
  useUpdateShortlistedQuotesMutation,
  useGetClaimProcessQuery,
  useGetProductBrochureQuery,
  useLazyGetProductBrochureQuery,
  useGetAboutCompanyQuery,
  useGetProductFeaturesQuery,
  useGetShortlistedQuotesQuery,
  useGetLocationDetailsQuery,
  useLazyGetLocationDetailsQuery,
  useGetCustomQuotesQuery,
  useLazyGetCustomQuotesQuery,
  useUpdateCompareQuotesMutation,
  useGetCompareQuotesQuery,
  useGetCompareFeaturesQuery,
  usePrefetch,
  useLazyGetQuoteQuery,
  useGetProposalDataQuery,
  useGetTopUpAddOnsQuery,
  useUpdateRenewalQueryMutation,
  useGetRenewalSumInsuredsQuery,
  useGetFeatureOptionsQuery,
  useGetPoliciesQuery,
  useGetKYCStatusesQuery,
  useGetFeedbackQuery,
  useGetProposersQuery,
  useSendFeedbackEmailQuery,
  useGetQuoteQuery,
  useLazyGetCartQuery,
  useGetProposalSchemaQuery,
  useGetRenewalKycSchemaQuery,
  useCallApiMutation,
  useLazyGetEnabledProductsQuery,
  useSendOtpQuery,
  useVerifyOtpQuery,
} = api;

function updateGroupMembersQueryBuilder(builder) {
  return builder.mutation({
    queryFn: async ({ members }, _utils, _extraOptions, fetchWithBaseQuery) => {
      try {
        const { data: updateEnquiriesResult, error: updateEnquiryError } =
          await fetchWithBaseQuery({
            url: `/enquiries`,
            method: "PATCH",
            body: { members },
          });

        if (updateEnquiryError) return { error: updateEnquiryError };

        const getCartResult = await fetchWithBaseQuery({ url: "/cart-items" });

        return {
          data: { updateEnquiriesResult, getCartResult: getCartResult.data },
        };
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  });
}
