// utility function for pos, non-pos

import { brokerTenant, envTenant } from "../utils/tenantSettings";

export const STORED_SSO_USER = () => {
  return localStorage?.SSO_user ? JSON.parse(localStorage?.SSO_user) : null;
};

export const STORED_POS_DETAILS = () => {
  return localStorage?.Pos_details
    ? JSON.parse(localStorage?.Pos_details)
    : null;
};

export const STORED_TOKEN = () => {
  return localStorage?.Pos_details
    ? JSON.parse(localStorage?.Pos_details).token
    : null;
};

export const STORED_TOKEN_SSO = () => {
  return localStorage?.SSO_user
    ? JSON.parse(localStorage?.SSO_user).token
    : null;
};

// check if user is logged with B2B or B2C
// Business 2 Business (B2B) or Business 2 Customer (B2C)
export const isB2CLogin = () => {
  if (brokerTenant("renew_buy")) {
    return STORED_SSO_USER() ? "B2B" : "B2C";
  }
  const result =
    STORED_POS_DETAILS() && STORED_POS_DETAILS()?.seller_type !== "U"
      ? "B2B"
      : "B2C";

  return result || "B2C";
};

// Variable used for checking if pos cover message is shown or not
export const STORED_POS_COVER_MESSAGE = () =>
  sessionStorage.pos_cover_message || undefined;

export function printSellerType(type) {
  switch (type?.toLowerCase()) {
    case "p":
      return "POS";
    case "e":
      return "EMP";
    case "u":
      return "USER";
    default:
      return "PART";
  }
}

export function notAllowFor(broker = []) {
  let value = true;
  broker.forEach(alias => {
    if (alias === envTenant()) {
      value = false;
    }
  });
  return value;
}

// Validation for token 1hrs
export const isTokenOneHrsLong = () => {
  const ONE_HOUR = 60 * 60 * 1000; /* ms */
  const tokenTiming =
    localStorage?.SSO_user && JSON.parse(localStorage?.SSO_user).saveDate;
  if (new Date() - tokenTiming > ONE_HOUR) {
    return true;
  }

  return false;
};

// function that return RB SSO AMS URL
export const getRB_ams_URl = () => {
  const hostName = window.location.hostname;
  const getOnlyDomain = hostName.split(".")[1] + "." + hostName.split(".")[2];
  return `https://accounts.${getOnlyDomain}/`;
};

// sendBack to login page
export function sendBackToLoginPage() {
  window.location.replace(
    `${getRB_ams_URl()}?next=${
      window.location.origin + process.env.PUBLIC_URL
    }/input/basic-details`,
  );
}

// function that check if platform is webview or not
export const isWebView = () => {
  let isMobileIOS = false;
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    isMobileIOS = true;
  }

  let userAgent = window.navigator.userAgent.toLowerCase();
  const isIOSwebView =
    isMobileIOS &&
    !userAgent.includes("safari/") &&
    !userAgent.includes("version/");

  if (
    userAgent.includes("wv") ||
    userAgent.includes("RB Partners".toLocaleLowerCase()) ||
    isIOSwebView
  ) {
    return true;
  }

  return false;
};
