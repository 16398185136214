import { useEffect, useState } from "react";
import styled from "styled-components";
// UTILITIES
import { base64ToImageSrc } from "src/utils/helper";
import { brokerTenant } from "src/utils/tenantSettings";
// DESIGN SYSTEM
import { VIEW } from "src/styles/responsiveStyle";
import { ROUNDED, SHADES } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
// CONSTANTS
import noConnectionBase64 from "src/assets/base64/noConnection";

export default function OfflineErrorModal() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (brokerTenant("renew_buy")) return null;

  return (
    isOffline && (
      <>
        <OfflineErrorBackdrop />
        <OfflineErrorModalStyled>
          <FHTypography variant="h5" weight="semi" colorType="heading">
            Please check your internet connection.
          </FHTypography>

          <img
            src={base64ToImageSrc({
              base64: noConnectionBase64,
              imageMimetype: "image/png",
            })}
            alt="No internet connection."
            loading="lazy"
          />
        </OfflineErrorModalStyled>
      </>
    )
  );
}

export const OfflineErrorModalStyled = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72vw;
  padding: 1rem;
  border-radius: ${ROUNDED.md};
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  z-index: 1061;

  img {
    width: 54%;
  }

  ${VIEW.md} {
    padding: 1.5rem 3rem;
    min-width: 360px;
    max-width: 540px;
  }
`;

export const OfflineErrorBackdrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${SHADES.HEADING}99;
  z-index: 1060;
`;
