import { useState } from "react";
// CUSTOM HOOKS
import { useGetAboutCompanyQuery } from "src/api/api";
import { useCompanies, useTheme } from "src/customHooks";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  AboutCompanyContainerStyled,
  AboutCompanyInfoWrapperStyled,
  CompanyNameStyled,
  CompanyOverViewStyled,
  FeatureContainerStyled,
  FeatureDescriptionStyled,
  FeatureInfoWrapperStyled,
  FeatureTabStyled,
  FeatureTabsContainerStyled,
  FeatureTitleStyled,
  FeatureWrapperStyled,
  GraphAndReferenceWrapper,
  ReferenceTextStyled,
} from "./AboutCompany.style";
// CUSTOM COMPONENTS
import BarGraph from "./BarGraph";
import CardSkeletonLoader from "src/components/Common/card-skeleton-loader/CardSkeletonLoader";
// CONSTANTS
import { companyFeaturesArray } from "./aboutCompanyData";

export default function AboutCompany({ quote }) {
  const {
    product: { company },
  } = quote;

  const { getCompany } = useCompanies();

  const { short_name: company_name } = getCompany(company.alias);

  const {
    isLoading,
    isUninitialized,
    data: aboutCompany,
  } = useGetAboutCompanyQuery(company.id);

  const [activeBtn, setActiveBtn] = useState("market_sizes");
  const {
    settings: { block_csr_visibility },
  } = useFrontendBoot();

  const { colors } = useTheme();
  const featureTabs = [
    "market_sizes",
    ...(+block_csr_visibility === 0 ? ["claim_settlement_ratios"] : []),
    "claim_incured_ratios",
  ];

  if (isLoading || isUninitialized) return <CardSkeletonLoader />;

  const graphColors = getColorValues(aboutCompany?.data?.group_color);

  return (
    <AboutCompanyContainerStyled>
      <AboutCompanyInfoWrapperStyled>
        <CompanyNameStyled primary_color={colors.primary_color}>
          About
          <span>{company_name}</span>
          Insurance
        </CompanyNameStyled>
        <CompanyOverViewStyled
          dangerouslySetInnerHTML={{
            __html: aboutCompany?.data?.overview || "No data vailable",
          }}
        />
      </AboutCompanyInfoWrapperStyled>

      <FeatureContainerStyled>
        <FeatureTabsContainerStyled>
          {featureTabs.map((tab, key) => (
            <FeatureTabStyled
              key={key}
              colors={colors}
              onClick={() => setActiveBtn(tab)}
              data-button-active={activeBtn === tab}
            >
              <FHTypography variant="h6" weight="semi">
                {companyFeaturesArray[tab].title}
              </FHTypography>
            </FeatureTabStyled>
          ))}
        </FeatureTabsContainerStyled>

        {featureTabs.map(tab => (
          <FeatureWrapperStyled key={tab} data-tab-active={activeBtn === tab}>
            <FeatureInfoWrapperStyled>
              <FeatureTitleStyled>
                {companyFeaturesArray[tab].title}
              </FeatureTitleStyled>
              <FeatureDescriptionStyled primary_color={colors.primary_color}>
                {companyFeaturesArray[tab].description}
              </FeatureDescriptionStyled>
            </FeatureInfoWrapperStyled>

            {aboutCompany?.data && (
              <GraphAndReferenceWrapper>
                <BarGraph
                  data={aboutCompany?.data?.[tab]}
                  label={companyFeaturesArray[tab].title}
                  labelKey={companyFeaturesArray[tab].labelKey}
                  dataKey={companyFeaturesArray[tab].dataKey}
                  direction={companyFeaturesArray[tab].graphDirection}
                  colors={graphColors}
                  dataPointLimit={companyFeaturesArray[tab].dataPointLimit}
                />
                <ReferenceTextStyled>
                  {companyFeaturesArray[tab].reference}
                </ReferenceTextStyled>
              </GraphAndReferenceWrapper>
            )}
          </FeatureWrapperStyled>
        ))}
      </FeatureContainerStyled>
    </AboutCompanyContainerStyled>
  );
}

function getColorValues(group_color) {
  const colorValues = [];

  group_color.forEach(obj => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].startsWith("#")) {
        colorValues.push(obj[key]);
      }
    }
  });

  return colorValues.reverse();
}
