import { useState } from "react";
// REACT ICONS
import { BiChevronDown } from "react-icons/bi";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  FeatureDefinitionContainer,
  FeatureDescriptionStyled,
  FeatureDescriptionWrapperStyled,
  FeatureNameWrapperStyled,
} from "./PlanDetails.style";

export default function FeatureDefinition({ data }) {
  const [toggle, setToggle] = useState(false);

  if (+data?.is_visible == 0) return <></>;

  return (
    <FeatureDefinitionContainer onClick={() => setToggle(!toggle)}>
      <FeatureNameWrapperStyled toggle={toggle}>
        <FHTypography
          variant="h6"
          weight="semi"
          colorType="heading"
          className="rider-name-value"
        >
          {data.header} : {data.value}
        </FHTypography>
        <BiChevronDown className="toggle_icon" />
      </FeatureNameWrapperStyled>
      <FHTypography variant="subheading2" weight="base" colorType="subHeading">
        <ul>
          {data.short_description.split("•").map((data, idx) => {
            return (
              <li key={idx}>
                {idx >= 1 ? "•" : ""}
                {data}
              </li>
            );
          })}
        </ul>
      </FHTypography>
      <FeatureDescriptionWrapperStyled toggle={toggle}>
        <FeatureDescriptionStyled>{data.description}</FeatureDescriptionStyled>
      </FeatureDescriptionWrapperStyled>
    </FeatureDefinitionContainer>
  );
}
