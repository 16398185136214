import { memo } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { planArray } from "../inputPage.utility";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import { isRenewals, checkTopup, isPortability } from "src/utils/checkJourney";
import { PlanListItemStyled } from "./HeaderCard.styled";
import FHTypography from "src/styles/Typography";

function PlanList() {
  const { colors } = useTheme();
  const {
    settings,
    tenantAlias,
    data: { tenant },
  } = useFrontendBoot();

  const journeyType = checkTopup({ tenant })
    ? "top_up"
    : isRenewals(tenant)
    ? "renew" :
    isPortability(tenant) 
    ? "portability"
    : "health";
    
  return planArray(journeyType).map(data => (
    <PlanListItemStyled
      key={data}
      settings={settings}
      tenantAlias={tenantAlias}
    >
      <span>
      <IoIosCheckmarkCircle color={colors.primary_color}  />
      </span>
      
      <FHTypography variant="h6" weight="base">
        {data}
      </FHTypography>
      {/* <p>{data}</p> */}
    </PlanListItemStyled>
  ));
}

export default memo(PlanList);
