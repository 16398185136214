import React, { useState } from "react";
import { RiFileCopy2Line, RiQrCodeFill } from "react-icons/ri";
import { Button } from "src/components/Common/Button/ContinueButton";
import QRCodeComponent from "src/components/QRCode";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_COL, FLEX_ROW_CENTER } from "src/styles/styleGuide";
import styled from "styled-components";

function ShareModuleOtherActions() {
  const { settings } = useFrontendBoot();
  const { share_option_visibility } = settings || {};

  // STATE/s
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const [copyText, setCopyText] = useState(false);

  //FUNCTION/s
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(String(window.location.href));
      setCopyText(true);
      setTimeout(() => {
        setCopyText(false);
      }, 2000);
    } catch (err) {
      setCopyText(false);
    }
  };
  return (
    <ShareModuleOtherActionContainerStyled className="shareModule_otherActions">
      {share_option_visibility.includes("qr") && (
        <Button
          className="actionButton"
          onClick={() => {
            setIsQRCodeVisible(true);
          }}
        >
          <span className="actionButton_text">Scan QR</span>
          <RiQrCodeFill size={20} />
        </Button>
      )}

      {share_option_visibility.includes("copy_to_clipboard") && (
        <Button className="actionButton" onClick={copyLink}>
          <span className="actionButton_text">
            {copyText ? "Copied" : "Copy URL"}
          </span>
          <RiFileCopy2Line size={20} />
        </Button>
      )}

      <QRCodeComponent
        isQRCodeVisible={isQRCodeVisible}
        closeQrCode={() => setIsQRCodeVisible(false)}
      />
    </ShareModuleOtherActionContainerStyled>
  );
}

const ShareModuleOtherActionContainerStyled = styled.div`
  width: 100%;
  ${FLEX_COL};
  gap: 0.5rem;

  ${VIEW.md} {
    ${FLEX_ROW_CENTER};
    gap: 2rem;
  }

  .actionButton {
    flex: 1;
    &_text {
      margin-right: 0.8rem;
    }
  }
`;

export default ShareModuleOtherActions;
