/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import AppProviders from "./AppProviders";
import { GlobalStyles } from "./styles";
import { Toaster } from "react-hot-toast";
import UnrecoverableError from "./components/Errors/UnrecoverableError";
import initializeSentry from "./services/Sentry/sentry";
import { brokerAppPhase, brokerTenant } from "./utils/tenantSettings";
import "src/styles/FontFace/fontFace.css";
import { register } from "./registerServiceWorker";

window.isTransactionClicked = false;

// Disabling all logs from PRODUCTION
if (brokerAppPhase("PROD") && process.env.NODE_ENV === "production") {
  // console.log = () => {};
  // console.error = () => {};
  // console.warn = () => {};
}
initializeSentry();

// Clear local storage on every refresh
// to avoid pos data mismatch
if (brokerTenant("renew_buy")) {
  localStorage.clear();
  sessionStorage.removeItem("is_Gi_certified");
}

const RenderApp = () => {
  return (
    <StrictMode>
      <GlobalStyles />
      <Sentry.ErrorBoundary fallback={<UnrecoverableError />}>
        <AppProviders>
          <App />
        </AppProviders>
      </Sentry.ErrorBoundary>
      <Toaster position="bottom-right" />
    </StrictMode>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<RenderApp />);

brokerTenant("bajaj") && brokerAppPhase("PROD") && register();
