import { Fragment } from "react";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import Talktouspopup from "../../TalkToUs";
import FloatButton from "../Button/FloatButton";
import { useRouteMatch } from "react-router-dom";
import useReusableModal from "../Popups/ReusableModal/useReusableModal";
import CustomToolTip from "../CustomTooltip";

const CallButton = () => {
  const isBasicDetailsRoute = useRouteMatch({
    path: "/input/basic-details",
  });
  const { settings } = useFrontendBoot();

  const { handleCloseReusableModal, handleOpenReusableModal, isModalOpen } =
    useReusableModal();
  if (isBasicDetailsRoute || settings?.is_talk_to_us_visible === "0")
    return <></>;

  return (
    <Fragment>
      <CustomToolTip
        description="Need assistance? Reach out to us here!"
        showInfoIcon={false}
        className="tool"
        theme="light"
      >
        <FloatButton
          onClick={() => {
            handleOpenReusableModal();
          }}
        >
          {settings?.contact_us_not_display_contact_form?.includes(
            "contact",
          ) ? (
            <BsFillChatLeftTextFill />
          ) : (
            <FaPhoneAlt />
          )}
        </FloatButton>
        <Talktouspopup show={isModalOpen} onClose={handleCloseReusableModal} />
      </CustomToolTip>
    </Fragment>
  );
};

export default CallButton;
