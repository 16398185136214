import { useEffect } from "react";
import toast from "react-hot-toast";
import { workingWomen } from "../../assets/images";
import { onWhichPage } from "src/utils/helper";
import { OuterDivStyled, UnrecoverableErrorStyled } from "./Error.style";

function UnrecoverableError() {
  const page = onWhichPage();
  useEffect(() => {
    toast.error("Error has been reported to our team!", {
      position: "bottom-right",
      icon: "✅",
      style: {
        backgroundColor: "#232323",
        color: "white",
      },
    });
  }, []);
  return (
    <OuterDivStyled>
      <UnrecoverableErrorStyled>
        <div className="errorHead">
          <h1>Unhandled Runtime Error</h1>
          <p className="errorMsg">{`This error occurred on ${page} page!`}</p>
        </div>
        <div className="errorBody">
          <img
            src={workingWomen}
            alt="unhandled runtime error"
            loading="lazy"
          />
          <h2>We are already working to fix this</h2>
        </div>
        <div className="errorActions">
          <button
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </button>
          <button
            onClick={() => {
              window.location.href = `${
                window.location.origin + process.env.PUBLIC_URL + "/"
              }`;
            }}
          >
            Home
          </button>
        </div>
      </UnrecoverableErrorStyled>
    </OuterDivStyled>
  );
}

export default UnrecoverableError;
