import { memo } from "react";
import { useRouteMatch } from "react-router-dom";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { VIEW } from "src/styles/responsiveStyle";
import { FWEIGHT, ROUNDED, SHADOW } from "src/styles/styleGuide";
import { renderDisclaimer } from "src/utils/helper";
import { removeHtmlTagsFromString } from "src/utils/string.utility";
import styled from "styled-components";

function TermsAndConditions() {
  const {
    tenantName,
    data: { settings },
  } = useFrontendBoot();
  const isBasicDetailsRoute = useRouteMatch("/input/basic-details");
  const isRenewalDetailsRoute = useRouteMatch("/input/renewal-details");
  const { colors } = useTheme();

  // VARIABLE/s
  const disclaimer = removeHtmlTagsFromString(
    renderDisclaimer({ tenantName, settings }),
  );

  if (!isBasicDetailsRoute && !isRenewalDetailsRoute) return null;

  return (
    <TermsAndConditionsStyled colors={colors}>
      <FHTypography variant="body1" weight="base" colorType="subHeading">
        {disclaimer}
      </FHTypography>
    </TermsAndConditionsStyled>
  );
}

export default memo(TermsAndConditions);

const TermsAndConditionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  text-align: justify;
  padding: 1rem 0.5rem;
  box-shadow: ${SHADOW.lg};
  background-color: ${props => props.colors.primary_shade};
  border-radius: ${ROUNDED.lg};

  .TStext_toggle {
    cursor: pointer;
    padding: 0 0.1rem;
    max-width: fit-content;
    color: ${props => props.colors.primary_color};
    font-weight: ${FWEIGHT.semi};
    border-bottom: 1px solid ${props => props.colors.primary_color};

    &:hover {
      background-color: ${props => props.colors.primary_shade};
    }
  }

  ${VIEW.md} {
    max-width: 500px;
    width: 100%;
  }
`;
