import ICLogoBase from "src/components/Common/ICLogo";
import FHTypography from "src/styles/Typography";
import { FLEX_ROW, FONT, ROUNDED, SHADES } from "src/styles/styleGuide";
import { amount, numToLakh } from "src/utils/string.utility";
import styled from "styled-components";

export default function CanvasQuoteCard({ quote, colors }) {
  const {
    product: { name },
    total_premium,
    sum_insured,
    company_alias,
  } = quote;

  return (
    <CanvasQuoteCardContainerStyled colors={colors}>
      <section className="canvasQuoteCard_brand">
        <ICLogoBase
          className="canvasImage"
          alias={company_alias}
          width={80}
          loading="lazy"
        />
        <FHTypography variant="subheading1" weight="semi">
          {name}
        </FHTypography>
      </section>
      <FHTypography variant="subheading1" color={colors.primary_color}>
        {amount(total_premium)}
      </FHTypography>
      <FHTypography variant="subheading1">
        {numToLakh(sum_insured)}
      </FHTypography>
      <button className={`canvasBtn ${FONT.subheading2}`}>Proceed</button>
    </CanvasQuoteCardContainerStyled>
  );
}

const CanvasQuoteCardContainerStyled = styled.div`
  ${FLEX_ROW};
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0.2rem;

  :not(:last-child) {
    border-bottom: 1px solid ${SHADES.DARK_GRAY};
  }

  .canvasQuoteCard_brand {
    ${FLEX_ROW};
    align-items: center;
    width: 40%;

    gap: 0.5rem;

    .canvasImage {
      border: 1px solid ${SHADES.DARK_GRAY};
      padding: 0.2rem 0;
      border-radius: ${ROUNDED.md};
      min-height: 2rem;
    }
  }

  .canvasBtn {
    background-color: ${({ colors }) => colors.primary_color};
    color: ${SHADES.WHITE};
    border-radius: ${ROUNDED.md};
    padding: 0.5rem 1rem;
  }
`;
