/* 
    TITLE: utility.slice.js
    DESCRIPTION: Redux slice for all the utilities states

*/

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showDate: {
    son: {
      son1: null,
    },
    daughter: {
      daughter1: null,
    },
  },
  showCalendar: {
    son: {
      son1: false,
    },
    daughter: {
      daughter1: false,
    },
  },
  allQuotesDisplayed: [],
  filterLoading: false,
  isQuotesLoading: true,
  isMobileFilterOpen: false,
  basePlanType: "",
  seeDetails: {
    currentQuote: {},
    section: "plan-details",
    isSeeDetailsModalOpen: false,
  },
  compare: {
    products: [],
  },
  cartSummary: {
    isCartSummaryModalOpen: false,
  },
  journeyChange: {
    isLoading: false,
    newJourneyType: "Health",
  },
  portability: {
    show: false,
    type: "",
    date: "",
  },
};

export const utilitySlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    setShowCalendarAction: (state, actions) => {
      state.showCalendar = {
        ...state.showCalendar,
        [actions.payload.baseCode]: {
          ...state.showCalendar[actions.payload.baseCode],
          [actions.payload.code]: actions.payload.value,
        },
      };
    },
    setShowDateAction: (state, actions) => {
      state.showDate = {
        ...state.showDate,
        [actions.payload.baseCode]: {
          ...state.showDate[actions.payload.baseCode],
          [actions.payload.code]: actions.payload.value,
        },
      };
    },
    setAllQuotesDisplayedAction: (state, actions) => {
      state.allQuotesDisplayed = actions.payload;
    },
    setSeeDetailsQuoteAction: (state, actions) => {
      state.seeDetails = { ...state.seeDetails, currentQuote: actions.payload };
    },
    setDetailSectionAction: (state, actions) => {
      state.seeDetails = { ...state.seeDetails, section: actions.payload };
    },
    setSeeDetailsModalAction: (state, actions) => {
      state.seeDetails = {
        ...state.seeDetails,
        isSeeDetailsModalOpen: actions.payload,
      };
    },
    setCartSummaryModalAction: (state, actions) => {
      state.cartSummary = {
        ...state.cartSummary,
        isCartSummaryModalOpen: actions.payload,
      };
    },
    setCompareQuotesAction: (state, actions) => {
      state.compare = {
        ...state.compare,
        products: actions.payload,
      };
    },

    setJourneyChangeAction: (state, actions) => {
      state.journeyChange = actions.payload;
    },
    setPortabilityConfigAction: (state, actions) => {
      state.portability = { ...state.portability, ...actions.payload };
    },
    setBasePlanTypeChangeAction: (state, actions) => {
      state.basePlanType = actions.payload;
    },
    setFilterLoadingAction: (state, actions) => {
      state.filterLoading = actions.payload;
    },
    setIsQuotesLoadingAction: (state, actions) => {
      state.isQuotesLoading = actions.payload;
    },
    setMobileFilterModalToggleAction: (state, actions) => {
      state.isMobileFilterOpen = actions.payload;
    },
  },
});

export const {
  setShowCalendarAction,
  setShowDateAction,
  setAllQuotesDisplayedAction,
  setSeeDetailsModalAction,
  setSeeDetailsQuoteAction,
  setDetailSectionAction,
  setCartSummaryModalAction,
  setCompareQuotesAction,
  setJourneyChangeAction,
  setPortabilityConfigAction,
  setBasePlanTypeChangeAction,
  setFilterLoadingAction,
  setIsQuotesLoadingAction,
  setMobileFilterModalToggleAction,
} = utilitySlice.actions;

export default utilitySlice.reducer;
