import { useSelector } from "react-redux";
import styled from "styled-components";
// CUSTOM HOOKS
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
// DESIGN SYSTEM
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_COL, FLEX_ROW, ROUNDED, SHADES } from "src/styles/styleGuide";
// CUSTOM COMPONENTS
import ShortlistFloat from "./ShortListFloat";
import CallButton from "../CallButton/CallButton";
import ScrollToTopBtn from "../ScrollToTop/ScrollToTopBtn";
import ShareModuleFloatButton from "./ShareModuleFloatButton";
import ComparePDFDownloadButton from "./ComparePDFDownloadButton";
import ToggleCompareNavButton from "./ToggleCompareNavButton";
import WhatsappFloatButton from "./WhatsappFloatButton";

function FloatElements() {
  const {
    isQuoteRoute,
    isProposalRoute,
    isProposalSummaryRoute,
    isProductDetailsRoute,
    isCompareRoute,
  } = useGetRouteMatch();
  const { compareNavigationShow } = useSelector(state => state.comparePage);

  // FUNCTION/s
  function getBottomPosition(view = "D") {
    if (isQuoteRoute) {
      return view === "D" ? "20px" : "74px";
    }
    if (isCompareRoute) {
      if (
        window.innerWidth > 768 &&
        window.innerWidth < 1024 &&
        compareNavigationShow
      )
        return "160px";
      return view === "D" ? "20" : "20px";
    }
    if (isProductDetailsRoute) {
      return view === "D" ? "20px" : "220px";
    }
    if (isProposalSummaryRoute) {
      return view === "D" ? "145px" : "190px";
    }
    if (isProposalRoute) {
      return view === "D" ? "20px" : "80px";
    }
    return view === "D" ? "20px" : "20px";
  }

  function getRightPosition(view = "D") {
    if (isProposalSummaryRoute || isProposalRoute || isCompareRoute) {
      return view === "D" ? "20px" : "10px";
    }
    return view === "D" ? "20px" : "16px";
  }

  if (isProductDetailsRoute)
    return (
      <FloatElementsContainerStyled
        getRightPosition={getRightPosition}
        getBottomPosition={getBottomPosition}
      >
        <WhatsappFloatButton />
      </FloatElementsContainerStyled>
    );
  return (
    <FloatElementsContainerStyled
      getRightPosition={getRightPosition}
      getBottomPosition={getBottomPosition}
    >
      <WhatsappFloatButton />
      {isCompareRoute && <ToggleCompareNavButton />}
      <ShareModuleFloatButton />
      <ShortlistFloat />
      <CallButton />
      {isCompareRoute && <ComparePDFDownloadButton />}
      <ScrollToTopBtn />
    </FloatElementsContainerStyled>
  );
}

const FloatElementsContainerStyled = styled.div`
  position: fixed;
  bottom: ${({ getBottomPosition }) => getBottomPosition("M")};
  right: ${({ getRightPosition }) => getRightPosition("M")};
  ${FLEX_ROW};
  justify-content: flex-end;
  gap: 0.5rem;
  backdrop-filter: blur(2px);
  border-radius: ${ROUNDED.xxl};
  background-color: ${SHADES.SUB_HEADING + "22"};
  box-shadow: 0 0 5px 5px ${SHADES.SUB_HEADING + "22"};
  transition: all 0.2s ease-in-out;
  z-index: 90;

  ${VIEW.md} {
    bottom: ${({ getBottomPosition }) => getBottomPosition("D")};
    right: ${({ getRightPosition }) => getRightPosition("D")};
    ${FLEX_COL};
    background-color: transparent;
    box-shadow: none;
  }
`;

export default FloatElements;
