import { useSelector, useDispatch } from "react-redux";
// import { setIsShortlistTourDone } from "src/pages/NewQuotePage/QuotePageSlice/quote.slice";
import { setIsCustomPlanTourDone } from "src/pages/CustomPlanPage/customPlan.slice";

export default function useCustomPlanTour(journeyType) {
  const dispatch = useDispatch();
  const isCustomPlanTourDone = useSelector(
    state => state.customPlanPage.isCustomPlanTourDone,
  );

  let customPlanTourData = [
    {
      title: "Add an Insurer",
      description:
        'Begin by adding an insurer of your choice - simply click "Select Company" to get started.',
    },
    {
      title: "Select a Product",
      description: "Choose a product by adding it's name to the list",
    },
    {
      title: "Select Coverage",
      description: "Determine your coverage by selecting the desired amount",
    },
    {
      title: "Choose Tenure",
      description: "Set the policy period by choosing the tenure option",
    },
    {
      title: "Add to list",
      description:
        "After making your selections, assess the products and its premium based on your requirements",
    },
  ];

  if (journeyType === "top_up") {
    customPlanTourData.splice(2, 0, {
      title: "Choose Deductible",
      description: "Specify your deductible amount for the selected product",
    });
  }

  const handleCustomPlanTourDone = () => {
    dispatch(setIsCustomPlanTourDone(true));
    sessionStorage.setItem("customPlanTourDone", true);
  };

  return {
    isCustomPlanTourDone,
    handleCustomPlanTourDone,
    customPlanTourData,
  };
}
