// UTILS
import { onWhichPage } from "./helper";
import { amount } from "./string.utility";
// CONSTANTS
const journeyRoutes = [
  "quotes",
  "shortlisted",
  "compare",
  "productdetails",
  "proposal",
  "proposal_summary",
  "thankyou",
];

const isRiderSelected = rider => {
  const {
    is_mandatory = false,
    is_rider_free = false,
    is_free_rider_selected = false,
    isSelected = false,
  } = rider;

  return (
    isSelected || is_mandatory || (is_rider_free && is_free_rider_selected)
  );
};

const getSelectedRiders = riders => riders?.filter(isRiderSelected);

const selectQuoteRiders = (riders = []) => {
  return riders?.map(rider => ({
    ...rider,
    id: rider?.id ?? rider?.rider_id,
    isSelected: isRiderSelected(rider),
  }));
};

const selectCartRiders = (riders = []) => {
  return riders?.map(rider => ({
    ...rider,
    id: rider?.rider_id ?? rider?.id,
    isSelected: true,
  }));
};

const isAffectsOtherRiders = rider => rider?.affects_other_riders;

const matchCurrentRoute = ({ matcher = "eq", route = "" }) => {
  const currentRouteIndex = journeyRoutes.indexOf(onWhichPage());

  if (route?.length == 0) return false;

  switch (matcher) {
    case "lte": {
      return currentRouteIndex <= journeyRoutes.indexOf(route);
    }
    case "gte": {
      return currentRouteIndex >= journeyRoutes.indexOf(route);
    }
    case "lt": {
      return currentRouteIndex < journeyRoutes.indexOf(route);
    }
    case "gt": {
      return currentRouteIndex > journeyRoutes.indexOf(route);
    }
    case "eq": {
      return currentRouteIndex == journeyRoutes.indexOf(route);
    }
    default: {
      return false;
    }
  }
};

const filterInvalidRiders = rider => {
  const { is_rider_default = false, calculate_premium_on_selection = false } =
    rider;

  // remove invalid riders except for the conditions in switch statements
  if (rider.total_premium <= 0) {
    switch (true) {
      case is_rider_default: {
        return rider;
      }
      case calculate_premium_on_selection: {
        return rider;
      }
      default: {
        return;
      }
    }
  }
  return rider;
};

const riderPremiumValue = ({ rider, includeTax = false }) => {
  const {
    total_premium,
    tax_amount,
    is_rider_default = false,
    is_rider_free = false,
  } = rider || {};

  switch (true) {
    case is_rider_default || is_rider_free: {
      return "Included";
    }
    default: {
      return includeTax
        ? amount(total_premium)
        : amount(total_premium - tax_amount);
    }
  }
};

const validateAvailableOnlyWithRiders = ({
  riders = [],
  selectedRidersAlias,
}) => {
  return riders.map(rider => {
    const disable_due_to_available_only_with =
      rider.available_only_with &&
      !selectedRidersAlias?.some(alias =>
        rider.available_only_with.includes(alias),
      );

    return {
      ...rider,
      disable: {
        ...rider.disable,
        disable_due_to_available_only_with,
      },
    };
  });
};

const validateNotAvailableWithRiders = ({
  riders = [],
  selectedRidersAlias,
}) => {
  return riders.map(rider => {
    const disable_due_to_not_available_with =
      rider.not_available_with &&
      selectedRidersAlias?.some(alias =>
        rider.not_available_with.includes(alias),
      );

    return {
      ...rider,
      disable: {
        ...rider.disable,
        disable_due_to_not_available_with,
      },
    };
  });
};

const validateNotAvailableWithFeatureOptionsRider = (
  riders = [],
  selectedFeatureOptions = [],
  selectedFeatureAlias = [],
  handleFeatureDependentRiders,
) => {
  const isFeatureMandatory =
    riders?.filter(rider => rider?.not_available_with_feature_option) || [];

  if (isFeatureMandatory?.length) {
    const riderAlias = isFeatureMandatory
      ?.filter(isRiderSelected)
      ?.map(riders => riders?.alias)
      .join(",");
    const featureAlias = selectedFeatureAlias.join(",");
    handleFeatureDependentRiders({ riderAlias, featureAlias });
  }
  return riders.map(rider => {
    const disable_due_to_not_available_with_feature_option =
      rider?.not_available_with_feature_option &&
      rider.not_available_with_feature_option.some(feature_alias =>
        selectedFeatureOptions?.includes(feature_alias),
      );
    return {
      ...rider,
      disable: {
        ...rider?.disable,
        disable_due_to_not_available_with_feature_option,
      },
    };
  });
};

const validateMandatoryRidersWhenOptedWithRiders = (
  riders = [],
  changedRider = {},
) => {
  const { mandatory_riders_when_opted, isSelected } = changedRider;

  if (mandatory_riders_when_opted && (isSelected || !isSelected)) {
    return riders.map(rider => ({
      ...rider,
      isSelected: mandatory_riders_when_opted.includes(rider?.alias)
        ? isSelected
        : rider.isSelected,
    }));
  }

  return [...riders];
};

const riderSelectedValidator = (riders = []) => {
  return riders.map(rider => {
    const { is_mandatory = false, is_rider_default = false } = rider;
    const isDisabled = Object.values(rider.disable).some(value => value);

    if (isDisabled) {
      return { ...rider, isSelected: false };
    } else if (is_mandatory || is_rider_default) {
      return { ...rider, isSelected: true };
    } else {
      return rider;
    }
  });
};

const ridersValidations = ({
  riders = [],
  selectedFeatureOptions = [],
  isOnClickValidations = false,
  changedRider = {},
  selectedFeatureAlias = [],
  handleFeatureDependentRiders = () => {},
}) => {
  const selectedRidersAlias = getSelectedRiders(riders)?.map(
    rider => rider.alias,
  );

  let displayRiders = validateAvailableOnlyWithRiders({
    riders,
    selectedRidersAlias,
  });

  displayRiders = validateNotAvailableWithRiders({
    riders: displayRiders,
    selectedRidersAlias,
  });

  displayRiders = validateNotAvailableWithFeatureOptionsRider(
    displayRiders,
    selectedFeatureOptions,
    selectedFeatureAlias,
    handleFeatureDependentRiders,
  );

  if (isOnClickValidations) {
    displayRiders = validateMandatoryRidersWhenOptedWithRiders(
      displayRiders,
      changedRider,
    );
  }

  displayRiders = riderSelectedValidator(displayRiders);

  return displayRiders;
};

export {
  ridersValidations,
  isRiderSelected,
  getSelectedRiders,
  selectCartRiders,
  selectQuoteRiders,
  filterInvalidRiders,
  isAffectsOtherRiders,
  riderPremiumValue,
  matchCurrentRoute,
};
