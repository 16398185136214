import { combineReducers } from "redux";
import {
  CartPageSlice,
  comparePageSlice,
  frontendBootSlice,
  seeDetailsModalSlice,
  greetingPageSlice,
  ProductDetailsSlice,
  schemaSlice,
  proposalPageSlice,
  quotePageSlice,
  errorSlice,
  recommendationSlice,
  customPlanSlice,
} from "../pages";
import quoteFilterSlice from "../pages/NewQuotePage/QuotePageSlice/quoteFilterSlice";
import differentQuotesSlice from "../pages/NewQuotePage/QuotePageSlice/differentQuotesSlice";
import utilitySlice from "src/slices/utility.slice";
import globalCompSlice from "src/slices/globalComp.slice";
import FormBuilderSlice from "./../components/FormBuilder/FormBuilder.slice";

import { api } from "../api/api";

export default combineReducers({
  frontendBoot: frontendBootSlice,
  greetingPage: greetingPageSlice,
  comparePage: comparePageSlice,
  quotePage: quotePageSlice,
  cart: CartPageSlice,
  proposalPage: proposalPageSlice,
  productPage: ProductDetailsSlice,
  seeDetailsModal: seeDetailsModalSlice,
  schema: schemaSlice,
  formBuilder: FormBuilderSlice,
  error: errorSlice,
  quoteFilters: quoteFilterSlice,
  utilities: utilitySlice,
  recommendationPage: recommendationSlice,
  differentQuotes: differentQuotesSlice,
  customPlanPage: customPlanSlice,
  globalComponent: globalCompSlice,
  [api.reducerPath]: api.reducer,
});
