import { useDispatch, useSelector } from "react-redux";
import {
  setInfoPopupClose,
  setInfoPopupOpen,
} from "src/slices/globalComp.slice";

export default function useInfoPopup() {
  const dispatch = useDispatch();
  const { infoPopup } = useSelector(state => state.globalComponent);

  //FUNCTION/s
  function handleOpenInfoPopup({
    title,
    description,
    buttonText,
    href,
    onClick,
    openInNewTab,
    position,
    hideCloseButton = true,
    component,
  }) {
    dispatch(
      setInfoPopupOpen({
        isOpen: true,
        title,
        description,
        buttonText,
        href,
        onClick,
        openInNewTab,
        position,
        hideCloseButton,
        component,
      }),
    );
  }

  function handleCloseInfoPopup() {
    dispatch(setInfoPopupClose());
  }
  return { infoPopup, handleCloseInfoPopup, handleOpenInfoPopup };
}
