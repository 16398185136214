/* 
TITLE: QRCode.jsx
DESCRIPTION: QR Code base file used in ShareModule. It Consist of QR Code User Interface. 

*/

import QRCode from "qrcode.react";
import { FaTimes } from "react-icons/fa";
import FHTypography from "src/styles/Typography";
import ICLogoBase from "../Common/ICLogo";
import useQRCode from "./useQRCode";
import { envTenant, getTenantFullName } from "src/utils/tenantSettings";
import styled from "styled-components";
import {
  FLEX_COL,
  FLEX_COL_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  ROUNDED,
} from "src/styles/styleGuide";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";

const QRCodeComponent = ({ isQRCodeVisible, closeQrCode }) => {
  const { isShortlistRoute } = useGetRouteMatch();
  const { scan_qr_text, posUser, data, tenant, colors, share_stage } =
    useQRCode();

  let qrURL = window.location.href;
  if (share_stage === "SHORTLISTED_QUOTES" && !isShortlistRoute) {
    qrURL = window.location.href.replace("quotes", "shortlisted");
  }

  if (!isQRCodeVisible) return <></>;
  return (
    <QRCodeStyled>
      <QRContentStyled colors={colors}>
        <section className="qrCode_head">
          <FHTypography variant="h4" weight="semi" colorType="heading">
            QR CODE
          </FHTypography>
          <button onClick={closeQrCode}>
            <FaTimes size={20} />
          </button>
        </section>
        <section className="qrCode_content">
          <ICLogoBase alias={envTenant()} width={180} />
          <FHTypography
            variant="h6"
            weight="md"
            colorType="body"
            className="qrCode_description"
          >
            Hi {posUser ? posUser.first_name : data?.data?.name}, Please scan
            the below QR Code
          </FHTypography>
          <QRWhiteBorderStyled>
            <QRCode value={qrURL} size={190} />
          </QRWhiteBorderStyled>

          <FHTypography variant="h6" weight="semi">
            {scan_qr_text || "Powered by " + getTenantFullName(tenant.alias)}
          </FHTypography>
        </section>
      </QRContentStyled>
    </QRCodeStyled>
  );
};

const QRWhiteBorderStyled = styled.div`
  margin: 1rem 0rem;
  width: 210px;
  height: 210px;
  border-radius: ${ROUNDED.md};
  background-color: #fff;
  ${FLEX_ROW_CENTER};
`;

const QRContentStyled = styled.div`
  ${FLEX_COL};
  align-items: center;
  height: 100%;
  z-index: 1;
  background-color: ${({ colors }) => colors.primary_shade};

  .qrCode_head {
    margin-bottom: 1.5rem;
    width: 100%;
    padding: 1rem 2rem;
    background-color: #fff;
    ${FLEX_ROW};
    justify-content: space-between;
    align-items: center;
  }

  .qrCode_content {
    ${FLEX_COL_CENTER};

    .qrCode_description {
      margin-top: 0.8rem;
    }
  }
`;

const QRCodeStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1101;
`;

export default QRCodeComponent;
