import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  infoPopup: {
    isOpen: false,
    title: "",
    description: "",
    buttonText: "",
    href: "",
    openInNewTab: false,
    onClick: () => {},
    position: "",
  },
  shareModule: {
    isOpen: false,
    step: 0,
    stage: "",
    purpose: "",
    headingIdentifier: "",
    quotes: [],
    selectedSectionView: {},
  },
  bodyOverflow: "unset",
};

export const globalCompSlice = createSlice({
  name: "globalComponent",
  initialState,
  reducers: {
    setInfoPopupOpen: (state, actions) => {
      const { payload } = actions;
      state.infoPopup = {
        href: payload.href || "",
        title: payload.title || "",
        description: payload.description || "",
        buttonText: payload.buttonText || "",
        isOpen: true,
        openInNewTab: payload.openInNewTab || false,
        onClick: payload.onClick || (() => {}),
        position: payload.position || "center",
        hideCloseButton: payload.hideCloseButton,
        component: payload.component || <></>,
      };
    },

    setInfoPopupClose: state => {
      state.infoPopup = {
        href: "",
        title: "",
        description: "",
        buttonText: "",
        isOpen: false,
        openInNewTab: false,
        position: "",
        onClick: () => {},
      };
    },

    // Share Module Slice
    setShareModuleToggleAction: (state, actions) => {
      const {
        isOpen,
        stage,
        step,
        purpose,
        headingIdentifier,
        quotes,
        selectedSectionView,
      } = actions.payload;
      state.shareModule = {
        ...state.shareModule,
        isOpen,
        stage,
        step,
        purpose,
        headingIdentifier: headingIdentifier || "quotes",
        quotes,
        selectedSectionView,
      };
    },

    setShareModuleStepAction: (state, actions) => {
      const { step } = actions.payload;
      state.shareModule = {
        ...state.shareModule,
        step,
      };
    },
    setShareModuleQuotes: (state, action) => {
      state.shareModule = {
        ...state.shareModule,
        quotes: action.payload,
      };
    },
    setShareModuleCompareView: (state, action) => {
      state.shareModule = {
        ...state.shareModule,
        selectedSectionView: action.payload,
      };
    },

    setBodyOverflow: (state, action) => {
      state.bodyOverflow = action.payload;
    },
  },
});

export const {
  setInfoPopupClose,
  setInfoPopupOpen,
  setShareModuleToggleAction,
  setShareModuleStepAction,
  setShareModuleQuotes,
  setShareModuleCompareView,
  setBodyOverflow,
} = globalCompSlice.actions;

export default globalCompSlice.reducer;
