import { envTenant } from "src/utils/tenantSettings";

function blockJourney() {
  return (
    process.env.REACT_APP_BLOCK_JOURNEY &&
    process.env.REACT_APP_BLOCK_JOURNEY === "true"
  );
}

export const doBlockJourney = ({
  urlToken,
  enquiryToken,
  isLoading,
  isRenewalsDomain,
}) => {
  const isBasicDetailsPage =
    window.location.pathname.includes("/basic-details");

  if (isBasicDetailsPage) {
    return blockJourney() && !urlToken && !isRenewalsDomain;
  } else {
    return (
      !isLoading &&
      blockJourney() &&
      !enquiryToken?.params?.token &&
      !isRenewalsDomain
    );
  }
};

export function getRedirection({ tenant }) {
  switch (envTenant()) {
    case "bajaj":
      return tenant.dashboard_url + "employee/dashboard";
    default:
      return tenant.dashboard_url;
  }
}
