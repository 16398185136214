import { useDispatch, useSelector } from "react-redux";
import { setCompareNavigationShow } from "src/pages/ComparePage/compare.slice";
import { useResponsiveCondition } from "src/pos/hooks";
import FloatButton from "../Button/FloatButton";
import { DEVICES } from "src/styles/responsiveStyle";
import { HiMenu } from "react-icons/hi";

export default function ToggleCompareNavButton() {
  const { width, breakpoint } = useResponsiveCondition(DEVICES.desktop);
  const dispatch = useDispatch();
  const { compareNavigationShow } = useSelector(state => state.comparePage);
  const openCompareNavigation = () => dispatch(setCompareNavigationShow(true));

  if (width > breakpoint) return <> </>;

  return (
    !compareNavigationShow && (
      <FloatButton onClick={openCompareNavigation}>
        <HiMenu size={24} />
      </FloatButton>
    )
  );
}
