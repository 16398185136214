import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./app.css";
import { store } from "./app/store";

function AppProviders({ children }) {
  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {children}
      </BrowserRouter>
    </Provider>
  );
}

export default AppProviders;
