import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useUrlEnquiry } from "src/customHooks";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import { useUrlQueries } from "src/customHooks/useUrlQuery";

export default function useCustomHistoryStack() {
  const history = useHistory();
  const { enquiryId } = useUrlQueries();
  const { groupCode, groups, cartGroups } = useGetEnquiry();
  const groupList = groups.map(group => group.id);
  const maxGroupId = Math.max(...cartGroups);

  const storedHistoryStack = sessionStorage.getItem("historyStack");
  const historyStack =
    storedHistoryStack === null ? [] : JSON.parse(storedHistoryStack);

  const getBackPath = useGenerateBackPath({
    enquiryId,
    groupCode,
    maxGroupId,
    groupList: cartGroups.sort((a, b) => a - b),
  });

  const {
    isQuoteRoute,
    isShortlistRoute,
    isCompareRoute,
    isProductDetailsRoute,
    isProposalRoute,
    isProposalSummaryRoute,
    isCustomPlanRoute,
  } = useGetRouteMatch();

  const pushRouteToCustomStack = route => {
    if (!historyStack.includes(route)) {
      historyStack.push(route);
      sessionStorage.setItem("historyStack", JSON.stringify(historyStack));
    }
  };

  const isBuildHistoryRoute =
    isQuoteRoute || isShortlistRoute || isCompareRoute || isCustomPlanRoute;

  const isNonBuildHistoryRoute =
    isProductDetailsRoute || isProposalRoute || isProposalSummaryRoute;

  const isOnMonitoredRoute = isBuildHistoryRoute || isNonBuildHistoryRoute;

  const buildHistoryStack = () => {
    // generate journey stack history
    // if user is on quotes, shortlisted and compare page
    if (isBuildHistoryRoute) {
      pushRouteToCustomStack(isBuildHistoryRoute.path);
    } else if (isNonBuildHistoryRoute && historyStack?.length > 0) {
      pushRouteToCustomStack(isNonBuildHistoryRoute.path);
    }
  };

  const popHistoryStack = () => {
    historyStack.pop();
    sessionStorage.setItem("historyStack", JSON.stringify(historyStack));
  };

  const goBack = () => {
    const storedHistoryStack = sessionStorage.getItem("historyStack");
    const historyStack =
      storedHistoryStack === null ? [] : JSON.parse(storedHistoryStack);

    const currentRoute = historyStack[historyStack.length - 1];

    const currentGroupIndex = groupList.indexOf(+groupCode);

    if (
      ["/compare", "/productdetails"].includes(currentRoute) &&
      historyStack.at(-2) === "/shortlisted"
    ) {
      popHistoryStack();
      history.push(`/shortlisted/${groupCode}?enquiryId=${enquiryId}`);
    } else if (
      currentRoute === "/productdetails" &&
      historyStack.at(-2) === "/compare" &&
      (groupList.length == 1 || currentGroupIndex == 0)
    ) {
      const storedCompareGroup =
        localStorage.getItem("compareGroup") &&
        JSON.parse(localStorage.getItem("compareGroup"));
      const compareGroupCode = storedCompareGroup || groupCode;

      popHistoryStack();
      history.push(`/compare/${+compareGroupCode}?enquiryId=${enquiryId}`);
    } else if (
      currentRoute === "/productdetails" &&
      historyStack.at(-2) === "/custom_plan" &&
      (groupList.length == 1 || currentGroupIndex == 0)
    ) {
      popHistoryStack();
      history.push(`/custom_plan/${groupCode}?enquiryId=${enquiryId}`);
    } else if (
      currentRoute === "/compare" &&
      historyStack.at(-2) === "/custom_plan"
    ) {
      popHistoryStack();
      history.push(`/custom_plan/${groupCode}?enquiryId=${enquiryId}`);
    } else {
      const { path, popStack } = getBackPath(
        currentRoute || isOnMonitoredRoute.path,
      );
      popStack && popHistoryStack();
      history.push(path);
    }
  };
  return { goBack, buildHistoryStack };
}

function useGenerateBackPath({ enquiryId, groupCode, maxGroupId, groupList }) {
  const { getUrlWithEnquirySearch } = useUrlEnquiry();

  const getBackPath = route => {
    let path = "";
    let popStack = true;
    switch (route) {
      case "/shortlisted": {
        path = `/quotes/${groupCode}?enquiryId=${enquiryId}`;
        break;
      }
      case "/custom_plan": {
        path = `/quotes/${groupCode}?enquiryId=${enquiryId}`;
        break;
      }
      case "/compare": {
        path = `/quotes/${groupCode}?enquiryId=${enquiryId}`;
        break;
      }
      case "/productdetails": {
        const currentGroupIndex = groupList.indexOf(+groupCode);
        if (groupList.length == 1 || currentGroupIndex == 0)
          return {
            path: `/quotes/${groupCode}?enquiryId=${enquiryId} `,
            popStack: true,
          };
        path = `/productdetails/${
          groupList[currentGroupIndex - 1]
        }?enquiryId=${enquiryId}`;
        popStack = false;

        break;
      }
      case "/proposal": {
        path = getUrlWithEnquirySearch(`/productdetails/${maxGroupId}`);
        break;
      }
      case "/proposal_summary": {
        path = `/proposal?enquiryId=${enquiryId}`;
        break;
      }
    }
    return { path, popStack };
  };
  return getBackPath;
}
