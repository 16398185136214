import { useParams } from "react-router-dom";
// CUSTOM HOOKS
import { riderPremiumValue, useCart, useTheme } from "src/customHooks";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import useRiderLoading from "src/pages/ProductDetails/Hooks/useRiderLoading";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  RiderActionWrapperStyled,
  RiderCardStyled,
  RiderFlagStyled,
  RiderInfoAndActionWrapperStyled,
  RiderOptionsWrapperStyled,
  RiderPremiumValue,
  RiderPremiumValueWrapperStyled,
  RiderTax,
  RiderActionAndPremiumWrapperStyled,
  RiderRecommendationStyled,
} from "./RiderCard.style";
// CUSTOM COMPONENTS
import RiderOption from "./RiderOption";
import RiderPremiumButton from "./RiderPremiumButton";
import RiderMembersDropdown from "./RiderMembersDropdown";
import CustomToolTip from "src/components/Common/CustomTooltip";
import { useSelector } from "react-redux";
import { getRiderDisabledMsg } from "src/pages/ProductDetails/productDetails.utility";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";
import Description from "../../Description";

export default function RiderCard({
  rider,
  onChange,
  showRiderActions,
  riderActionBtnClicked = false,
}) {
  const allRiders = useSelector(state => state.productPage.riders);
  const { colors } = useTheme();
  const { groupCode } = useParams();
  const isRiderLoading = useRiderLoading({ groupCode });
  const { isProposalRoute, isProposalSummaryRoute, isThankYouRoute } =
    useGetRouteMatch();
  const { rider_members } = rider;
  const defaultSelectedRoute =
    isProposalRoute || isProposalSummaryRoute || isThankYouRoute;
  const { handleOpenInfoPopup } = useInfoPopup();

  const { getCartEntry } = useCart();
  const selectedFeatureOptions = getCartEntry(groupCode)?.feature_options;

  const {
    disable = {},
    flag,
    is_mandatory = false,
    is_rider_default = false,
    is_rider_recommended = false,
  } = rider;

  // Message for rider if they are disabled due to other riders or features
  const riderDisabledMessage = getRiderDisabledMsg({
    rider,
    selectedFeatureOptions,
    allRiders,
  });

  const disableToken = Object.values(disable).some(value => value);
  const isRiderIncluded = rider?.is_rider_default || rider?.is_rider_free;
  const isRiderDisabled =
    disableToken || !showRiderActions || is_mandatory || is_rider_default;

  const notAvailableFeatures = rider?.not_available_with_feature_option
    ?.map(eachFeature => eachFeature.split("_").join(" "))
    ?.join(", ");
  const allMemberChange = isSelected => {
    const finalMembers = rider_members?.map(member => ({
      ...member,
      selected: isSelected,
    }));
    return finalMembers;
  };

  const handleRiderOptionChange = riderOption => {
    if (rider?.rider_popup_message && !rider?.isSelected) {
      handlePopUp(() =>
        onChange({
          ...rider,
          isSelected: riderOption.isSelected,
          rider_members: allMemberChange(riderOption?.isSelected) || [],
          options_selected: {
            ...rider.options_selected,
            [riderOption?.key]: riderOption?.selected,
          },
        }),
      );
      return;
    }
    onChange({
      ...rider,
      isSelected: riderOption.isSelected,
      rider_members: allMemberChange(riderOption?.isSelected) || [],
      options_selected: {
        ...rider.options_selected,
        [riderOption?.key]: riderOption?.selected,
      },
    });
  };

  const handleDependentRiderOptionsChange = (riderOptions = [{}, {}]) => {
    //* in case we have to update more than one option at once.
    let updateOptionsObject = {};

    riderOptions.forEach(riderOption => {
      updateOptionsObject = {
        ...updateOptionsObject,
        [riderOption?.key]: riderOption?.selected,
      };
    });

    Object.keys(updateOptionsObject).forEach(key => {
      if (updateOptionsObject[key]) return;
      updateOptionsObject[key] = "";
    });

    onChange({
      ...rider,
      isSelected: riderOptions[0].isSelected,
      options_selected: {
        ...rider.options_selected,
        ...updateOptionsObject,
      },
    });
  };

  const handlePopUp = handlePopfn => {
    handleOpenInfoPopup({
      title: "",
      description: rider?.rider_popup_message,
      buttonText: "OK",
      onClick: handlePopfn,
      hideCloseButton: false,
    });
  };

  const disableToolTip = () => {
    if (notAvailableFeatures && !isRiderDisabled) return false;
    else if (!isRiderDisabled) return true;
    else return false;
  };
  return (
    <RiderCardStyled data-rider-card-loading={isRiderLoading}>
      {flag && (
        <RiderFlagStyled secondary_color={colors.secondary_color}>
          {flag}
        </RiderFlagStyled>
      )}
      {is_rider_recommended && (
        <RiderRecommendationStyled secondary_color={colors.secondary_color}>
          <FHTypography variant="subheading2" weight="medi" color="#fff">
            {"Recommended"}
          </FHTypography>
        </RiderRecommendationStyled>
      )}
      <CustomToolTip
        disable={disableToolTip()}
        description={riderDisabledMessage}
        showInfoIcon={false}
      >
        <RiderPremiumButton
          rider={rider}
          onChange={
            isRiderDisabled
              ? () => {}
              : rider?.rider_popup_message && !rider?.isSelected
              ? () =>
                  handlePopUp(() =>
                    onChange({
                      ...rider,
                      isSelected: true,
                    }),
                  )
              : onChange
          }
          isRiderLoading={isRiderLoading}
          isDisabled={isRiderDisabled}
          riderActionBtnClicked={riderActionBtnClicked}
        />
      </CustomToolTip>

      <RiderInfoAndActionWrapperStyled>
        <FHTypography
          variant="h6"
          weight="semi"
          className={flag ? "rider-name" : ""}
        >
          {rider?.name}
        </FHTypography>

        <Description description={rider?.description} />
        <RiderActionAndPremiumWrapperStyled>
          <RiderActionWrapperStyled className="rider_actions">
            {showRiderActions && (
              <>
                {rider?.options && !rider?.disable_editing ? (
                  <RiderOptionsWrapperStyled>
                    {Object.keys(rider?.options)?.map(riderOptionKey => (
                      <RiderOption
                        option={{
                          options: rider?.options[riderOptionKey],
                          key: riderOptionKey,
                          selected_option_value:
                            rider?.options_selected?.[riderOptionKey],
                          isRiderSelected: rider.isSelected,
                        }}
                        rider={rider}
                        onChange={handleRiderOptionChange}
                        onMultipleChange={handleDependentRiderOptionsChange}
                        option_key={riderOptionKey}
                        key={riderOptionKey}
                        isDisabled={disableToken}
                        configurationObj={
                          rider?.configuration?.options[riderOptionKey] || {}
                        }
                      />
                    ))}
                  </RiderOptionsWrapperStyled>
                ) : (
                  <></>
                )}
                <RiderMembersDropdown
                  rider={rider}
                  onChange={onChange}
                  handlePopUp={handlePopUp}
                />
              </>
            )}
          </RiderActionWrapperStyled>

          {isRiderIncluded ? (
            <RiderPremiumValueWrapperStyled
              colors={colors}
              data-rider-disabled={isRiderDisabled}
              data-rider-selected={
                defaultSelectedRoute ? true : rider?.isSelected
              }
            >
              <RiderPremiumValue>Included</RiderPremiumValue>
            </RiderPremiumValueWrapperStyled>
          ) : (
            rider?.total_premium > 0 && (
              <RiderPremiumValueWrapperStyled
                colors={colors}
                data-rider-disabled={isRiderDisabled}
                data-rider-selected={
                  defaultSelectedRoute ? true : rider?.isSelected
                }
              >
                <RiderPremiumValue>
                  {riderPremiumValue({ rider, includeTax: true })}
                </RiderPremiumValue>
                <RiderTax>Incl GST</RiderTax>
              </RiderPremiumValueWrapperStyled>
            )
          )}
        </RiderActionAndPremiumWrapperStyled>
      </RiderInfoAndActionWrapperStyled>
    </RiderCardStyled>
  );
}
