// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// STYLED COMPONENTS
import {
  HospitalAddress,
  HospitalCardStyled,
  HospitalName,
  HospitalPhoneNo,
  HospitalsContainerStyled,
  HospitalsDisplayContainerStyled,
  InfoHeaderStyled,
  NoHospitalsText,
} from "./CashlessHospitals.style";

export default function HospitalsDisplay({ headerText, hospitalList }) {
  const { colors } = useTheme();

  return (
    <HospitalsDisplayContainerStyled>
      <InfoHeaderStyled colors={colors}>{headerText}</InfoHeaderStyled>

      {hospitalList.length == 0 ? (
        <NoHospitalsText>No hospitals found</NoHospitalsText>
      ) : (
        <HospitalsContainerStyled>
          {!!hospitalList &&
            hospitalList.map(({ name, address, phone }, index) => (
              <HospitalCardStyled key={index}>
                <HospitalName>{name}</HospitalName>
                <HospitalAddress>{address}</HospitalAddress>
                <HospitalPhoneNo>Phone Number:&nbsp;{phone}</HospitalPhoneNo>
              </HospitalCardStyled>
            ))}
        </HospitalsContainerStyled>
      )}
    </HospitalsDisplayContainerStyled>
  );
}
