import Modal from "../Popups/ReusableModal/Modal";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import "styled-components/macro";
import FHTypography from "src/styles/Typography";
import { FLEX_ROW, SHADES } from "src/styles/styleGuide";

const CardModal = ({ title, show, content, handleClose }) => {
  return (
    <Modal
      height="fit-content"
      mobileHeight="fit-content"
      width="fit-content"
      isModalOpen={show}
      onClose={handleClose}
      hideCloseBtn={true}
    >
      <div>
        {title && (
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <button onClick={handleClose}>
              <FaTimes />
            </button>
          </ModalHeader>
        )}
      </div>
      <div>{content}</div>
    </Modal>
  );
};

export default CardModal;

const ModalHeader = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;
  position: relative;
  margin-bottom: 1rem;

  button {
    font-size: 1.2rem;
    color: ${SHADES.HEADING};
  }

  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 40px);
    height: 0.7px;
    background-color: #dee2e6;
    bottom: -1rem;
    left: -20px;
  }
`;

const ModalTitle = styled(FHTypography).attrs(() => ({
  variant: "h5",
  weight: "semi",
}))`
  position: relative;
  line-height: 1.5;
  margin-left: 10px;
  padding: ${({ usedAsReviewCartPopup }) => usedAsReviewCartPopup && "1rem"};
  @media (max-width: 767px) {
    line-height: 1.3;
    margin: 0 0px;
  }
`;
