import { useEffect, useState } from "react";
import { useUrlQueries } from "src/customHooks/useUrlQuery";
import { brokerTenant } from "src/utils/tenantSettings";

function useAutoBasicDetailsPass() {
  const { token } = useUrlQueries();
  const [loginData, setLoginData] = useState(null);
  const [loginLoading, setLoginLoading] = useState(token && true);

  function setData() {
    const data = localStorage.Pos_details
      ? JSON.parse(localStorage.Pos_details)
      : {};

    if (!data?.status) {
      setLoginLoading(false);
    }

    if (data?.seller_type && data?.seller_type !== "U") {
      setLoginLoading(false);
    }

    if (data?.pos_id && data?.seller_type === "U") {
      let newName = data?.seller_name;
      let checkName = data?.seller_name?.split(" ");
      if (checkName?.[1] === "") {
        newName = checkName?.[0] + " .";
      }
      setLoginData({
        name: newName,
        email: data.seller_email,
        mobile: data.seller_mobile,
        sellerId: data.seller_id,
        gender: data.seller_gender || "M",
        seller_type: data?.seller_type,
      });
      setLoginLoading(false);
    }
  }

  useEffect(() => {
    setData();
  }, []);

  window.addEventListener("storage", () => {
    setData();
  });

  if (
    brokerTenant("pinc") ||
    brokerTenant("renew-buy") ||
    process.env.REACT_APP_BLOCK_JOURNEY
  ) {
    return { loginLoading: false, loginData: {} };
  }
  return { loginLoading, loginData };
}

export default useAutoBasicDetailsPass;
