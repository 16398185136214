import FHTypography from "src/styles/Typography";
import {
  FLEX_ROW_CENTER,
  FONT,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components";
import useInfoPopup from "./useInfoPopup";
import { useTheme } from "src/customHooks";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import { useResponsiveCondition } from "src/pos/hooks";
import { DEVICES } from "src/styles/responsiveStyle";

export default function InfoPopup() {
  const { infoPopup, handleCloseInfoPopup } = useInfoPopup();
  const { colors } = useTheme();
  const { width, breakpoint } = useResponsiveCondition(DEVICES.tabletBIG);

  // FUNCTION/s
  function handleButtonClick() {
    if (infoPopup.href !== "") {
      if (infoPopup.openInNewTab) {
        // If openInNewTab is true, open the url in new tab
        window.open(infoPopup.href, "_blank");
      } else {
        window.location.replace(infoPopup.href);
      }
    } else if (infoPopup.onClick) {
      // If there is no href, execute the function passed in onClick
      infoPopup.onClick();
    }
    handleCloseInfoPopup();
  }

  if (!infoPopup.isOpen) return <></>;
  return (
    <InfoPopupContainerStyled colors={colors} position={infoPopup.position}>
      <div className="infoModalContent">
        <section className="infoModal_hero">
          <FaInfoCircle size="2rem" />
        </section>
        {!infoPopup.hideCloseButton && (
          <button className="infoModal_closeBTN" onClick={handleCloseInfoPopup}>
            <FaTimes size={width > breakpoint ? 22 : 18} />
          </button>
        )}
        <FHTypography
          variant="h4"
          weight="semi"
          colorType="heading"
          className="infoModal_title"
        >
          {infoPopup.title}
        </FHTypography>
        {infoPopup.description && (
          <FHTypography
            variant="subheading1"
            weight="base"
            colorType="subHeading"
          >
            {infoPopup.description}
          </FHTypography>
        )}
        <section className="dynamic_component">
          {infoPopup.component && infoPopup.component}
        </section>
        {infoPopup.buttonText && (
          <button
            className={`infoActionBtn ${FONT.subheading1}`}
            onClick={handleButtonClick}
          >
            {infoPopup.buttonText}
          </button>
        )}
      </div>
    </InfoPopupContainerStyled>
  );
}

const InfoPopupContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #5556;
  width: 100%;
  height: 100vh;
  padding: 0 1rem;
  z-index: 1100;

  .infoModalContent {
    text-align: center;
    padding: 20px 40px;
    padding-top: 4rem;
    padding-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    overflow: hidden;
    box-shadow: ${SHADOW.xl};
    border-radius: ${ROUNDED.md};
    background-color: white;
    position: relative;

    ${({ position }) =>
      position === "top" &&
      `align-self: flex-start;
    margin-top: 4rem;`}

    .infoModal_hero {
      background-color: ${props => props.colors.primary_color + "dd"};
      padding: 1rem;
      border-radius: 0px 30px 30px 30px;
      color: ${SHADES.WHITE};
      position: absolute;
      top: 0;
      left: 0;
    }

    .infoModal_closeBTN {
      color: ${SHADES.SUB_HEADING};
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .dynamic_component {
      position: relative;
      ${FLEX_ROW_CENTER};
      max-width: 100%;
    }

    .infoModal_title {
      margin-bottom: 0.5rem;
    }
    .infoActionBtn {
      align-self: center;
      padding: 6px 25px;
      background-color: ${props => props.colors.primary_color};
      border-radius: ${ROUNDED.base};
      color: ${SHADES.WHITE};
      margin-top: 1rem;
      transition: all 0.2s ease;
      :hover {
        box-shadow: 0 0 0 2px ${props => props.colors.primary_color + "88"};
      }

      @media (max-width: 720px) {
        padding: 6px 25px;
      }
    }
  }
`;
