import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { brokerTenant } from "../utils/tenantSettings";
import { STORED_POS_DETAILS, STORED_SSO_USER } from "./utility";
import { getUrlQueries } from "src/utils/url.utility";

const useIsPos = () => {
  const isBasicDetailsRoute = useRouteMatch({
    path: "/input/basic-details",
    exact: true,
  });
  const { enquiryId } = getUrlQueries();
  const { data } = useGetEnquiry();
  const [isPosJourney, setIsPosJourney] = useState(undefined);
  const [ssoData, setSSOData] = useState(null);
  const [ssoEnquiryData, setSSOEnquiryData] = useState(null);
  const { input } = data?.data || {};

  useEffect(() => {
    setSSOData(STORED_SSO_USER());
    // listener that check for localStorage change and update the state
    window.addEventListener("storage", () => {
      if (localStorage.SSO_user) {
        setSSOData(JSON.parse(localStorage.SSO_user));
      } else {
        setSSOData(null);
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (isBasicDetailsRoute && enquiryId && brokerTenant("renew_buy")) {
      setIsPosJourney(true);
      return;
    }

    if (isBasicDetailsRoute && brokerTenant("renew_buy")) {
      localStorage.SSO_user ? setIsPosJourney(true) : setIsPosJourney(false);
      return;
    }

    // Check if the user is already POS  in basic details page
    if (isBasicDetailsRoute && STORED_POS_DETAILS()) {
      if (STORED_POS_DETAILS()?.seller_type) {
        return STORED_POS_DETAILS()?.seller_type === "P"
          ? localStorage.SSO_user
            ? setIsPosJourney(true)
            : setIsPosJourney(false)
          : setIsPosJourney(false);
      } else {
        return localStorage.SSO_user
          ? setIsPosJourney(true)
          : setIsPosJourney(false);
      }
    }

    // Check if the user is already POS other than basic details page using enquiry data from API
    if (!input) return;
    if (
      input?.params?.journey_type === "B2B" ||
      input?.params?.token ||
      input?.params?.username
    ) {
      if (brokerTenant("renew_buy")) {
        setIsPosJourney(true);
      } else if (input?.params?.seller_type === "P") {
        setIsPosJourney(true);
      } else {
        setIsPosJourney(false);
      }

      setSSOEnquiryData(input.params);
    } else {
      setIsPosJourney(false);
      localStorage.removeItem("SSO_user");
      localStorage.removeItem("Pos_details");
    }
    return () => {};
  }, [ssoData, data]);

  return { isPosJourney, ssoData, ssoEnquiryData };
};

export default useIsPos;
