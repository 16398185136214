import React from "react";
import { useTheme } from "src/customHooks";
import { VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_ROW_CENTER,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components";

function FloatButton({
  children,
  onClick = () => {},
  inverted = false,
  heighLight = false,
  disabled = false,
  ...props
}) {
  const { colors } = useTheme();
  return (
    <FloatButtonStyled
      colors={colors}
      onClick={onClick}
      inverted={inverted}
      heighLight={heighLight}
      disabled={disabled}
      {...props}
    >
      {children}
    </FloatButtonStyled>
  );
}

const FloatButtonStyled = styled.button`
  position: relative;
  ${FLEX_ROW_CENTER};
  height: 2.3rem;
  width: 2.3rem;
  background-color: ${({ inverted, colors }) => {
    return inverted ? SHADES.WHITE : colors.primary_color;
  }};
  color: ${({ inverted, colors }) => {
    return !inverted ? SHADES.WHITE : colors.primary_color;
  }};
  border: 1px solid ${({ colors }) => colors.primary_color};
  border-radius: ${ROUNDED.full};
  box-shadow: ${SHADOW.md};

  ${VIEW.md} {
    height: 3rem;
    width: 3rem;
  }

  ::after {
    content: "";
    position: absolute;
    height: 102%;
    width: 102%;
    border-radius: ${ROUNDED.full};
    border: 2px solid ${({ colors }) => colors.primary_color};
    animation: pulse 1s infinite;
    display: ${({ heighLight }) => (heighLight ? "block" : "none")};
  }

  :disabled {
    cursor: not-allowed;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
`;

export default FloatButton;
