// here goes the constants for quotePage
export const QUOTE_FEATURES_CONSTANT = [
  "room_rent_charges",
  "no_claim_bonus",
  "cashless_hospitals",
  "pre_existing_disease_cover",
  "co_payment",
];

export const MULTIYEAR_OPTIONS = [
  { display_name: "1 Year", code: 1 },
  { display_name: "2 Years", code: 2, info: "(Save Upto 10%)" },
  { display_name: "3 Years", code: 3, info: "(Save Upto 20%)" },
];

export const MORE_FILTER_HELPER = {
  worldwide_coverage: "yes_Covered",
  disease_wise_cover: "No",
  free_health_check_up: "yes_Covered",
  maternity_cover: "yes_Covered",
  outpatient_expenses: "yes_Covered",
  co_payment: "No",
  pre_policy_medical_screening: "No",
  room_rent_charges:
    "Up to Sum Insured. - All Categories. - All Categories* - Up to Sum Insured*",
};

export const TOPUP_COVER_TOOLTIP =
  "Sum Insured that wil cover you for your Top Up Plan";
