import { createSlice } from "@reduxjs/toolkit";

const greeting = createSlice({
  name: "greeting",
  initialState: {
    memberGroups: {},
    proposerDetails: {},
    selectedMembers: [],
    displayMembersList: [],
    membersError: false,
    memberErrorMessage: "",
  },
  reducers: {
    refreshUserData: (state, action) => {
      state.proposerDetails = action.payload;
    },
    addToSelectedMembersAction: (state, action) => {
      state.selectedMembers = [...action.payload];
    },
    addErrorToMemberAction: (state, action) => {
      state.membersError = action.payload;
    },
    setMembersErrorMessageAction: (state, action) => {
      state.memberErrorMessage = action.payload;
    },
    setDisplayMembersListAction: (state, action) => {
      state.displayMembersList = [...action.payload];
    },
  },
});

export const {
  refreshUserData,
  addToSelectedMembersAction,
  addErrorToMemberAction,
  setMembersErrorMessageAction,
  setDisplayMembersListAction,
} = greeting.actions;

export default greeting.reducer;
