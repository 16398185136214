// Get the formatted string of all member in the cart for easy display
export const getMembersName = (memberArray = []) => {
  memberArray = memberArray.map(member => member?.split("_").join(" "));
  if (memberArray.length === 1) {
    return memberArray.join("");
  } else if (memberArray.length === 2) {
    return memberArray.join(" and ");
  } else {
    let members = memberArray?.map((item, index) => {
      if (index + 1 === memberArray.length) {
        return `and ${item}`;
      } else if (index === memberArray.length - 2) {
        return `${item} `;
      } else {
        return `${item}, `;
      }
    });
    return members.join("");
  }
};

// Shorten the member name to fit the cart
export const shortName = (memberArray = [], length) => {
  if (length <= 2) {
    if (memberArray.length === 1) {
      return memberArray.join("");
    } else if (memberArray.length === 2) {
      return memberArray.join(" and ");
    } else {
      const thirdValue = memberArray[2];
      let members = memberArray?.map((item, index) => {
        if ([0, 1].includes(index)) {
          return item + ", ";
        } else if ([2].includes(index)) {
          return thirdValue.slice(0, 2) + "...";
        } else {
          return "";
        }
      });
      return members.join("");
    }
  } else {
    let stringValue = memberArray.toString();
    let members = memberArray.map((_, index) => {
      if (index == 0) {
        if (stringValue.length > 9) {
          return stringValue.slice(0, 9) + "...";
        } else {
          return stringValue;
        }
      } else {
        return "";
      }
    });
    return members;
  }
};

export const getCompleteName = (memberArray = []) => {
  if (memberArray.length === 1) {
    return memberArray.join("");
  } else if (memberArray.length === 2) {
    return memberArray.join(", ");
  } else {
    let members = memberArray?.map((item, index) => {
      if (index + 1 === memberArray.length) {
        return `, ${item}`;
      } else if (index === memberArray.length - 2) {
        return `${item} `;
      } else {
        return `${item}, `;
      }
    });
    return members.join("");
  }
};
