import styled from "styled-components";
//  DESIGN SYSTEM
import {
  FLEX_COL,
  FLEX_COL_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  ROUNDED,
} from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import { MAX, VIEW } from "src/styles/responsiveStyle";

const AboutCompanyContainerStyled = styled.div`
  ${FLEX_COL}
  gap:1.5rem;

  ${VIEW.lg} {
    gap: 2.5rem;
  }
`;

const AboutCompanyInfoWrapperStyled = styled.div``;

const FeatureContainerStyled = styled.div`
  ${FLEX_COL}
  gap:1.5rem;

  ${VIEW.lg} {
    gap: 5rem;
    & > div:nth-child(odd) {
      flex-direction: row;
    }

    & > div:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`;

const FeatureWrapperStyled = styled.div`
  ${FLEX_COL_CENTER}
  flex-direction: column-reverse;
  gap: 1.5rem;

  ${MAX.lg} {
    display: none;
    &[data-tab-active="true"] {
      display: flex;
    }
  }

  ${VIEW.lg} {
    ${FLEX_ROW}
    justify-content:space-between;
    align-items: center;
    gap: 6rem;
    .info-wrapper {
      width: 36%;
    }
    .graph-wrapper {
      width: 48%;
    }
  }
`;

const FeatureInfoWrapperStyled = styled.div.attrs(() => ({
  className: "info-wrapper",
}))`
  ${FLEX_COL}
  gap:0.75rem;
`;
const FeatureTitleStyled = styled(FHTypography).attrs(() => ({
  variant: "h2",
  weight: "semi",
}))`
  display: none;
  ${VIEW.lg} {
    display: block;
  }
`;

const FeatureDescriptionStyled = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "base",
}))`
  ::before {
    content: "Why is it important: ";
    color: ${props => props.primary_color};
  }
`;

const CompanyNameStyled = styled(FHTypography).attrs(() => ({
  variant: "h2",
  weight: "semi",
  color: "subHeading",
}))`
  span {
    color: ${props => props.primary_color};
    margin: 0 0.5rem;
  }
  text-align: center;
  ${VIEW.lg} {
    text-align: left;
  }
`;

const CompanyOverViewStyled = styled.div`
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 0.5rem;
  text-align: justify;
  &,
  & * {
    color: var(--heading_color) !important;
  }

  ${VIEW.lg} {
    text-align: left;
  }
`;

const GraphAndReferenceWrapper = styled.div.attrs(() => ({
  className: "graph-wrapper",
}))`
  ${FLEX_COL}
  gap:1rem;
  ${MAX.lg} {
    padding: 1rem;
    border-radius: ${ROUNDED.md};
    background-color: #f3f4f9;
    height: fit-content;
    width: 100%;
  }
`;

const ReferenceTextStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "base",
  colorType: "body",
}))`
  text-align: center;
`;

//  styled components specifically for mobile
const FeatureTabsContainerStyled = styled.div`
  ${FLEX_ROW_CENTER}
  gap:0.75rem;
  width: 72vw;
  max-width: 320px;
  height: 40px;
  margin: 0 auto;
  ${VIEW.lg} {
    display: none;
  }
`;

const FeatureTabStyled = styled.button`
  background-color: #fff;
  color: #000;
  padding: 0.75rem 0.5rem;
  border-radius: ${ROUNDED.base};
  &[data-button-active="true"] {
    background-color: ${props => props.colors.primary_color};
    color: #fff;
  }
`;

export {
  AboutCompanyInfoWrapperStyled,
  CompanyNameStyled,
  CompanyOverViewStyled,
  AboutCompanyContainerStyled,
  FeatureContainerStyled,
  FeatureDescriptionStyled,
  FeatureInfoWrapperStyled,
  FeatureTitleStyled,
  FeatureWrapperStyled,
  GraphAndReferenceWrapper,
  ReferenceTextStyled,
  // styled components only for mobile
  FeatureTabsContainerStyled,
  FeatureTabStyled,
};
