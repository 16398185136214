const companyFeaturesArray = {
  market_sizes: {
    title: "Market Size",
    description:
      "Market size refers to measure of the consumers' preference for a product over other similar products.",
    reference: "Reference: IRDAI Gross Direct premium 2023",
    labelKey: "year",
    dataKey: "premium",
    graphDirection: "vertical",
    dataPointLimit: 3,
  },
  claim_settlement_ratios: {
    title: "Claim Settlement Ratio",
    description:
      "Claim Settlement Ratio of an Insurer is the number of claims settled against the number of claims filed.",
    reference: "Reference: IRDAI Annual Report 2022-2023",
    labelKey: "year",
    dataKey: "percent",
    graphDirection: "vertical",
    dataPointLimit: 3,
  },
  claim_incured_ratios: {
    title: "Claim Incurred Ratio",
    description:
      "Claim Incurred Ratio refers to the net claims paid by an insurance company as against the net premiums earned.",
    reference: " Reference: IRDAI Annual Report 2022-2023",
    labelKey: "year",
    dataKey: "percent",
    graphDirection: "horizontal",
    dataPointLimit: 5,
  },
};

export { companyFeaturesArray };
