import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import {
  setShareModuleStepAction,
  setShareModuleToggleAction,
} from "src/slices/globalComp.slice";
import useQuotesSharing from "./useQuotesSharing";
import {
  initialLoadingState,
  loadingReducer,
  validateElementValue,
} from "../shareModule.utility";
import { brokerTenant } from "src/utils/tenantSettings";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";

export default function useShareModal() {
  const { isShortlistRoute } = useGetRouteMatch();
  const dispatchRDX = useDispatch();
  const { shareModule } = useSelector(state => state.globalComponent);
  const proposerData = useSelector(
    ({ proposalPage }) => proposalPage?.proposalData["Proposer Details"],
  );
  const { data } = useGetEnquiry();
  const enquiryData = data?.data || {};

  const { handleElementQuotesShareClick } = useQuotesSharing();

  //REDUCER/s
  const [isSharing, dispatch] = useReducer(loadingReducer, initialLoadingState);

  // STATE/s
  const [shareResponse, setShareResponse] = useState({
    type: "",
    messageFor: "",
  });

  const [shareAllResponse, setShareAllResponse] = useState("");

  const [shareElementValues, setShareElementValues] = useState({
    email: "",
    sms: "",
    whatsapp: "",
  });
  const [shareElementError, setShareElementError] = useState({
    email: "",
    sms: "",
    whatsapp: "",
  });

  // EFFECT/s
  // ===================================
  // reset share response on share module close & after 5sec

  useEffect(() => {
    if (shareResponse.type) {
      setTimeout(() => {
        setShareResponse({
          type: "",
          messageFor: "",
          message: "",
        });
      }, 5000);
    }
    return () => {};
  }, [shareResponse.type]);

  useEffect(() => {
    if (shareAllResponse) {
      setTimeout(() => {
        setShareAllResponse("");
      }, 5000);
    }
    return () => {};
  }, [shareAllResponse]);

  // set share element values on enquiry data change if not set
  useEffect(() => {
    if (!shareElementValues.email) {
      if (proposerData?.email) {
        setShareElementValues({
          email: proposerData.email || "",
          sms: proposerData.mobile || "",
          whatsapp: proposerData.mobile || "",
        });
      } else if (enquiryData.email) {
        setShareElementValues({
          email: enquiryData.email || "",
          sms: enquiryData.mobile || "",
          whatsapp: enquiryData.mobile || "",
        });
      }
    }
    return () => {};
  }, [shareModule.isOpen]);

  // FUNCTION/s
  // ===================================

  function handleShareElementClick(element) {
    // if current element is empty then show error
    if (!shareElementValues[element]) {
      setShareElementError({
        ...shareElementError,
        [element]: `${element} is required`,
      });
      return;
    }

    if (!validateElementValue(element, shareElementValues[element])) {
      setShareElementError({
        ...shareElementError,
        [element]: `Enter valid ${element === "email" ? "email" : "mobile"}`,
      });
      return;
    }

    if (element === "whatsapp" && !brokerTenant("bajaj")) {
      let url = window.location.href;
      if (shareModule.stage === "SHORTLISTED_QUOTES" && !isShortlistRoute) {
        url = url.replace("quotes", "shortlisted");
      }
      window.open(
        `https://api.whatsapp.com/send?phone=91${shareElementValues[element]}&text=${url}`,
        "_blank",
        "noopener noreferrer",
      );
      return;
    }
    dispatch({ type: element, payload: true });
    //call communication api
    handleElementQuotesShareClick({
      ele: element,
      data: shareElementValues,
      shareModule,
    }).then(res => {
      setShareResponse({
        type: res.statusCode === 200 ? "success" : "error",
        messageFor: element,
        message: res?.data?.data?.message,
      });

      dispatch({ type: element, payload: false });
    });
  }

  const handleShareAllElementClick = (
    SHARE_OPTIONS,
    share_option_visibility = [],
  ) => {
    let optionsToShare = SHARE_OPTIONS.filter(option =>
      share_option_visibility.includes(option?.label),
    );

    // initiate loader for share all
    optionsToShare.map(option => {
      dispatch({ type: option.label, payload: true });
    });

    // if any element is empty then show error
    if (optionsToShare.some(option => !shareElementValues[option.label])) {
      optionsToShare.map(option => {
        if (!shareElementValues[option.label]) {
          setShareElementError({
            ...shareElementError,
            [option.label]: `${option.label} is required`,
          });
        }
      });
      return;
    }

    if (
      optionsToShare.some(
        option =>
          !validateElementValue(option.label, shareElementValues[option.label]),
      )
    ) {
      optionsToShare.map(option => {
        if (
          !validateElementValue(option.label, shareElementValues[option.label])
        ) {
          setShareElementError({
            ...shareElementError,
            [option.label]: `Enter valid ${
              option.label === "email" ? "email" : "mobile"
            }`,
          });
        }
      });
      return;
    }

    if (optionsToShare.some(option => option.label === "whatsapp")) {
      window.open(
        `https://api.whatsapp.com/send?phone=91${shareElementValues["whatsapp"]}&text=${window.location.href}`,
        "_blank",
        "noopener noreferrer",
      );
      optionsToShare = optionsToShare.filter(
        option => option.label !== "whatsapp",
      );
    }

    Promise.all(
      optionsToShare.map(option =>
        handleElementQuotesShareClick({
          ele: option.label,
          data: shareElementValues,
          shareModule,
        }),
      ),
    ).then(res => {
      const shareStatus = res.every(resObj => resObj.statusCode === 200)
        ? "success"
        : "error";
      setShareAllResponse(shareStatus);
      dispatch({ type: "whatsapp", payload: false });
      optionsToShare.map(option => {
        dispatch({ type: option.label, payload: false });
      });
    });
  };

  // function to handle change of share element values
  // All communication input elements are handled by this function
  function handleChangeShareElementValues(e) {
    if (e.target.name !== "email") {
      if (!/^[0-9]*$/.test(e.target.value)) {
        return;
      }
    }

    const { name, value } = e.target;
    setShareElementError({
      ...shareElementError,
      [name]: false,
    });
    setShareElementValues(prevState => ({ ...prevState, [name]: value }));
  }

  // functions to handle opening/close of share module
  // Just extract these function and call then from anywhere share module will work
  // without any dependency
  function handleOpenShareModule({
    isOpen,
    stage,
    step = 2,
    headingIdentifier,
    quotes,
    selectedSectionView,
  }) {
    dispatchRDX(
      setShareModuleToggleAction({
        isOpen,
        stage,
        step,
        headingIdentifier,
        quotes,
        selectedSectionView,
      }),
    );
  }
  function handleStepChangeShareModule({ step }) {
    dispatchRDX(setShareModuleStepAction({ step }));
  }
  function handleCloseShareModule() {
    dispatchRDX(
      setShareModuleToggleAction({ isOpen: false, quotes: shareModule.quotes }),
    );
  }

  return {
    proposerData,
    enquiryData,
    shareModule,
    handleOpenShareModule,
    handleStepChangeShareModule,
    handleCloseShareModule,
    handleChangeShareElementValues,
    shareElementValues,
    shareElementError,
    isSharing,
    shareResponse,
    shareAllResponse,
    handleShareElementClick,
    handleShareAllElementClick,
  };
}
