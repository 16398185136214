import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { envAppBaseURL } from "src/utils/tenantSettings";
import { getUrlQueries } from "src/utils/url.utility";

const FormBuilder = createSlice({
  initialState: {
    asyncOptions: {},
    asyncValues: {},
  },
  name: "formBuilder",
  reducers: {
    setAsyncOptions: (state, action) => {
      state.asyncOptions = { ...state.asyncOptions, ...action.payload };
    },
    setAsyncValues: (state, action) => {
      state.asyncValues = { ...state.asyncValues, ...action.payload };
    },
  },
});
export const { setAsyncOptions, setAsyncValues } = FormBuilder.actions;

export const callApi = (endPoint, param) => {
  return async dispatch => {
    const urlQueryStrings = getUrlQueries();

    const { data } = await axios
      .create({
        baseURL: envAppBaseURL(),
        headers: {
          "Enquiry-Id": urlQueryStrings?.enquiryId,
        },
      })
      .get(envAppBaseURL() + endPoint, { params: param });

    let options = {};

    let values = {};

    let temp = data.data;

    if (endPoint.includes("area")) {
      let newTemp = temp[0];
      newTemp.forEach(innerItem => {
        let [value, key] = Object.values(innerItem);

        options = {
          ...options,
          ...{
            area: {
              ...options[`area`],
              ...{ [key]: value },
            },
          },
        };

        /* if (envAppPhase() === "UAT" || envAppPhase() === "DEV") {
          options = {
            ...options,
            ...{
              area: {
                ...options[`area`],
                ...{ [key]: value },
              },
            },
          };
        } else {
          options = {
            ...options,
            ...{
              [`area_${Object.keys(param)[0].split("_")[1]}`]: {
                ...options[`area_${Object.keys(param)[0].split("_")[1]}`],
                ...{ [key]: value },
              },
            },
          };
        } */
      });
    } else
      Object.keys(temp).forEach(item => {
        if (typeof temp[item] === "string") {
          values = { ...values, [item]: temp[item] };
        } else if (temp[item] instanceof Array) {
          temp[item].forEach(innerItem => {
            let [value, key] = Object.values(innerItem);
            options = {
              ...options,
              ...{ [item]: { ...options[item], ...{ [key]: value } } },
            };
          });
        }
      });

    dispatch(setAsyncOptions(options));
    dispatch(setAsyncValues(values));
  };
};
export default FormBuilder.reducer;
