// REACT ICONS
import { HiOutlineMail } from "react-icons/hi";
import { RiDownload2Line, RiLink, RiPhoneLine } from "react-icons/ri";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  ClaimContactInfoWrapperStyled,
  ClaimFormButtonStyled,
  ContactCardHeader,
  ContactCardHeaderWrapperStyled,
  ContactCardStyled,
  ContactCardValue,
  ContactInfoHeadingStyled,
  ContactInfoWrapperStyled,
  InsuranceContactType,
} from "./ClaimProcess.styled";

export default function ClaimContactInfo({ claimForm, claimProcess }) {
  const { colors } = useTheme();
  return (
    <ClaimContactInfoWrapperStyled>
      <ContactInfoHeadingStyled colors={colors}>
        Please reach out to the Insurer for further queries.
      </ContactInfoHeadingStyled>
      {/*  Insurance company contact */}
      <ContactInfoWrapperStyled>
        <InsuranceContactType colors={colors}>
          Insurance Company&apos;s Contact
        </InsuranceContactType>
        <ContactCardStyled colors={colors}>
          <ContactCardHeaderWrapperStyled>
            <ContactCardHeader>Call Us At</ContactCardHeader>
            <ContactCardValue>
              {claimProcess?.toll_free_number_insurance_comapny ||
                "No Data Available"}
            </ContactCardValue>
          </ContactCardHeaderWrapperStyled>
          <RiPhoneLine />
        </ContactCardStyled>
        <ContactCardStyled colors={colors}>
          <ContactCardHeaderWrapperStyled>
            <ContactCardHeader>Drop Us An Email</ContactCardHeader>
            <ContactCardValue>
              {claimProcess?.email_insurance_comapny || "No Data Available"}
            </ContactCardValue>
          </ContactCardHeaderWrapperStyled>
          <HiOutlineMail />
        </ContactCardStyled>
      </ContactInfoWrapperStyled>
      {/*  Insurance broker contact */}
      {claimProcess?.claim_identity === "both" && (
        <ContactInfoWrapperStyled>
          <InsuranceContactType colors={colors}>
            Insurance Broker&apos;s Contact
          </InsuranceContactType>
          <ContactCardStyled colors={colors}>
            <ContactCardHeaderWrapperStyled>
              <ContactCardHeader>Call Us At</ContactCardHeader>
              <ContactCardValue>
                {claimProcess?.toll_free_number_insurance_broker ||
                  "No Data Available"}
              </ContactCardValue>
            </ContactCardHeaderWrapperStyled>
            <RiPhoneLine />
          </ContactCardStyled>
          <ContactCardStyled colors={colors}>
            <ContactCardHeaderWrapperStyled>
              <ContactCardHeader>Drop Us An Email</ContactCardHeader>
              <ContactCardValue>
                {claimProcess?.email_insurance_broker || "No Data Available"}
              </ContactCardValue>
            </ContactCardHeaderWrapperStyled>
            <HiOutlineMail />
          </ContactCardStyled>
        </ContactInfoWrapperStyled>
      )}
      {/* claim form link */}
      {claimForm?.claim_url ? (
        <ClaimFormButtonStyled
          target="_blank"
          rel="noreferrer"
          href={claimForm?.claim_url}
          colors={colors}
        >
          <FHTypography variant="h5" weight="semi" colorType="heading">
            Claim Form
          </FHTypography>
          {claimForm?.claim_url.toLowerCase().includes(".pdf") ? (
            <RiDownload2Line />
          ) : (
            <RiLink />
          )}
        </ClaimFormButtonStyled>
      ) : (
        <></>
      )}
    </ClaimContactInfoWrapperStyled>
  );
}
