import { createSlice } from "@reduxjs/toolkit";

// Explanation ================
// selectedFilterStack ==> help use to get more quotes according to last value
// baseQuotes ==> contains all fetched quotes for the given set of filters
// differentQuotes ==> list of different category quotes
// baseCombinedQuotes ==> To show the lest of fetched quotes

const initialState = {
  selectedFilterStack: [],
  baseQuotes: [],
  differentQuotes: [],
  baseCombinedQuotes: [],
  diffCategoryQuotes: [],
};

export const differentQuotesSlice = createSlice({
  name: "differentQuotes",
  initialState,
  reducers: {
    setBaseQuotesAction: (state, actions) => {
      state.baseQuotes = actions.payload;
    },
    setDifferentQuotesAction: (state, actions) => {
      state.differentQuotes = actions.payload;
    },
    setBaseCombinedQuotesAction: (state, actions) => {
      state.baseCombinedQuotes = actions.payload;
    },
    setSelectedFilterStackAction: (state, actions) => {
      state.selectedFilterStack = actions.payload;
    },
    setDiffCategoryQuotesAction: (state, actions) => {
      state.diffCategoryQuotes = actions.payload;
    },
  },
});

export const {
  setBaseQuotesAction,
  setDifferentQuotesAction,
  setBaseCombinedQuotesAction,
  setSelectedFilterStackAction,
  setDiffCategoryQuotesAction,
} = differentQuotesSlice.actions;

export default differentQuotesSlice.reducer;
