import { useParams } from "react-router-dom";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { MAX, VIEW } from "src/styles/responsiveStyle";
import styled from "styled-components";
import CartGroupCard from "./CartGroupCard";
import CartSummaryFooter from "./CartSummaryFooter";

function CartSummaryContent({
  closeModal,
  onContinue,
  allClose,
  selectedRiders,
}) {
  const {
    data: {
      data: { groups },
    },
  } = useGetEnquiry();

  const { groupCode } = useParams();

  const currentGroup = groups.find(group => group?.id === +groupCode);
  const filteredGroups = groups.filter(
    group => group.type === currentGroup?.type,
  );

  return (
    <>
      <CartSummaryContainerStyled>
        {filteredGroups.map((group, index) => (
          <CartGroupCard
            key={index}
            group={group}
            closeModal={closeModal}
            allClose={allClose}
            selectedRiders={selectedRiders}
          />
        ))}
      </CartSummaryContainerStyled>
      <CartSummaryFooterStyled>
        <CartSummaryFooter
          onContinue={onContinue}
          closeModal={closeModal}
          groups={filteredGroups}
          selectedRiders={selectedRiders}
        />
      </CartSummaryFooterStyled>
    </>
  );
}

const CartSummaryContainerStyled = styled.div`
  padding-top: 0px;
  max-height: 350px;
  overflow: auto;

  ${VIEW.md} {
    padding: 1rem;
    padding-bottom: 0px;
  }

  ${MAX.md} {
    margin-top: 2rem;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const CartSummaryFooterStyled = styled.div`
  padding-top: 0px;
  
  ${VIEW.md} {
    padding: 1rem;
    padding-bottom: 0px;
  }

  ${MAX.md} {
    margin-top: 2rem;
  }
  
`

export default CartSummaryContent;
