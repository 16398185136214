import React, { memo } from "react";
import "styled-components/macro";
import { FaChevronDown } from "react-icons/fa";
import ReactSelect, { components } from "react-select";
import { useTheme } from "src/customHooks";
import { brokerTenant } from "src/utils/tenantSettings";
import { FWEIGHT } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import { useResponsiveCondition } from "src/pos/hooks";
import { DEVICES } from "src/styles/responsiveStyle";
import { IconWrappwer } from "src/pages/SeeDetailsModal/components/SeeDetailsHeader/SeeDetailsHeader.style";

function QuoteCardSelect({ color, fontSize, maxWidth, ...props }) {
  const { colors } = useTheme();

  return (
    <ReactSelect
      isSearchable={false}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral80: color && color,
          primary: colors.primary_color,
          primary25: colors.primary_shade,
        },
      })}
      classNamePrefix="cover_list"
      components={{ Option, DropdownIndicator, SingleValue }}
      styles={{
        singleValue: provided => ({
          ...provided,
          fontWeight: FWEIGHT.md,
        }),
        option: (provided, state) => {
          return {
            ...provided,
            fontSize: "10px",
            fontWeight: "bold",
            padding: "7px 7px !important",
            textAlign: "left",
            position: brokerTenant("tmibasl") && state.isSelected && "absolute",
            top: "0",
          };
        },
        menuList: provided => {
          return {
            ...provided,
            maxHeight: "180px",
            width: 90,
            maxWidth: 120,
            paddingTop: brokerTenant("tmibasl") ? "30px" : "0",
            "::-webkit-scrollbar": {
              width: "4px",
              height: "0px",
            },
          };
        },
        menu: provided => ({
          ...provided,
          fontWeight: "bold",
          textAlign: "left",
          width: "initial",
        }),
        valueContainer: provided => ({
          ...provided,
          padding: 0,
          fontWeight: "bold",
          minWidth: "60px",
        }),
        indicatorSeparator: () => ({ display: "none" }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          padding: 0,
          color: "black",
          fontWeight: "semibold",
          transition: "all 250ms ease-in-out",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        control: provided => ({
          ...provided,
          fontSize: fontSize || "13px",
          fontWeight: "bold",
          minHeight: "initial",
          width: maxWidth ? "max-content" : "initial",
          outline: "none",
          border: "0 !important",
          // This line disable the blue border
          boxShadow: "0 !important",
          "&:hover": {
            border: "0 !important",
          },
        }),
      }}
      {...props}
    />
  );
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <IconWrappwer>
        <FaChevronDown />
      </IconWrappwer>
    </components.DropdownIndicator>
  );
};

const SingleValue = props => {
  const { width, breakpoint } = useResponsiveCondition(DEVICES.tabletSM);
  return (
    <components.SingleValue {...props}>
      <FHTypography
        variant={width < breakpoint ? "subheading1" : "body1"}
        weight="md"
      >
        {props.children}
      </FHTypography>
    </components.SingleValue>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <FHTypography variant="body2" weight="bold">
        {props.children}
      </FHTypography>
    </components.Option>
  );
};

export default memo(QuoteCardSelect);
