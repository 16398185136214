/*
# Title:- src/components/Page.jsx

#Description:-
         Page file used in all major pages provides the outer skeleton.
         Responsible for printing outer container for all pages

         List of used components:-
          - UserJourney:- Represents user's journey
          - PageLoaders:- Loads page based on the route
          - InfoPopup:- Show any popup handle by globalComponent reducer

#Used In:-
         Used in ALL PAGES as the outer container
*/

import { lazy, useEffect } from "react";
import { useResponsiveCondition } from "src/pos/hooks";
import { DEVICES } from "src/styles/responsiveStyle";
import { CheckEditAccess } from ".";
import FloatElements from "./Common/FloatElements";
import InfoPopup from "./Common/Popups/InfoPopup";
import useCustomHistoryStack from "./GoBackButton/useCustomHistoryStack";
import PageLoaders from "./PageLoader";
import ShareModuleModal from "./ShareModule/ShareModal";
import useSeeDetailsUtility from "src/customHooks/useSeeDetailsUtility";
import SeeDetailsModal from "src/pages/SeeDetailsModal";
import { useSelector } from "react-redux";
import { onWhichPage } from "src/utils/helper";
// Navbar dynamic imports
const Navbar = lazy(() => import("./Navbar/Navbar"));
const NavbarMobile = lazy(() => import("./Navbar/NavbarMobile"));

export function Page({
  children,
  loader,
  backButton: BackButton = <></>,
  id = "",
}) {
  const { width, breakpoint } = useResponsiveCondition(DEVICES.tabletBIG);
  const { buildHistoryStack } = useCustomHistoryStack();
  const bodyOverflow = useSelector(state => state.globalComponent.bodyOverflow);
  const { seeDetailsModalOpen } = useSeeDetailsUtility();

  // EFFECT/s
  useEffect(() => {
    buildHistoryStack();

    if (window?.webengage?.track) {
      // Used in Webengage SDK on BCL pre-prod
      window.webengage.track("Page Viewed", {
        "Page Name": onWhichPage(),
      });
    }
  }, []);

  useEffect(() => {
    document.body.style.overflow = bodyOverflow;
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [bodyOverflow]);

  // COMPONENT RETURN
  return (
    <PageLoaders>
      <div id={id}>
        {loader ? loader : null}
        {width < breakpoint ? (
          <CheckEditAccess>
            <NavbarMobile backButton={BackButton} />
          </CheckEditAccess>
        ) : (
          <Navbar />
        )}
        <div>{children}</div>
        {seeDetailsModalOpen && <SeeDetailsModal />}
        <InfoPopup />
        <ShareModuleModal />
        <FloatElements />
      </div>
    </PageLoaders>
  );
}
