import { Redirect, useRouteMatch } from "react-router-dom";
import { useCheckPolicies } from "src/customHooks/useCheckPolicies";
import FullScreenLoader from "../FullScreenLoader";

export default function CheckPolicies({ children }) {
  const {
    doNotCheckPolicies,
    policiesData,
    policiesFetching,
    policiesUninitialized,
    policiesLoading,
    searchQueries,
  } = useCheckPolicies();

  const isThankYouRoute = useRouteMatch({ path: "/thankyou" });

  if (doNotCheckPolicies) return children;

  if (policiesLoading || policiesUninitialized || policiesFetching)
    return <FullScreenLoader />;

  if (policiesData && policiesData?.length > 0) {
    const policies = policiesData;

    const isPaymentSuccess = policies.every(
      policy => policy.payment_status === "success",
    );

    if (isPaymentSuccess && !isThankYouRoute) {
      window.location.replace(
        `${process.env.PUBLIC_URL}/thankyou${window.location.search}`,
      );
    } else {
      <Redirect
        to={{
          pathname: "/proposal_summary",
          search: `enquiryId=${searchQueries?.enquiryId}`,
        }}
      />;
    }
  }

  return children;
}
