import styled from "styled-components";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
import { ROUNDED, SHADES } from "src/styles/styleGuide";
import { VIEW } from "src/styles/responsiveStyle";

export default function SectionWrapper({
  id,
  heading,
  subHeading,
  children,
  sectionRef,
}) {
  const { colors } = useTheme();

  return (
    <SectionWrapperStyled id={id} ref={sectionRef}>
      <SectionHeader colors={colors}>
        {heading && (
          <SectionHeading secondary_color={colors.secondary_color}>
            {heading}
          </SectionHeading>
        )}
        {subHeading && (
          <SectionSubheading className="section-subheading">
            {subHeading}
          </SectionSubheading>
        )}
      </SectionHeader>
      <SectionChildWrapperStyled>{children}</SectionChildWrapperStyled>
    </SectionWrapperStyled>
  );
}

const SectionHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${VIEW.md} {
    padding: 1rem;
    border-bottom: 2px solid ${SHADES.LIGHT_GRAY};
  }
  .section-subheading {
    line-height: 1.5;
  }
`;

const SectionHeading = styled(FHTypography).attrs(() => ({
  variant: "h4",
  weight: "md",
  colorType: "heading",
}))``;

const SectionSubheading = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "base",
  colorType: "subHeading",
}))``;

const SectionWrapperStyled = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: ${ROUNDED.xl};
  gap: 1rem;

  ${VIEW.md} {
    gap: 0rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }
`;

const SectionChildWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  ${VIEW.md} {
    gap: 1rem;
    padding: 1rem;
  }
`;
