import Spinner from "src/components/Common/Spinner";
import styled from "styled-components";

function AutofillDetailsLoader() {
  return (
    <AutofillDetailsLoaderContainerStyled>
      <div className="autoFillModal">
        <Spinner />
        <h3>Please wait! </h3>
        <p>we are fetching user details.</p>
      </div>
    </AutofillDetailsLoaderContainerStyled>
  );
}

const AutofillDetailsLoaderContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #7777;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  .autoFillModal {
    max-width: 400px;
    width: 100%;
    padding: 10px 20px;
    background-color: #eee;
    color: #222;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
`;

export default AutofillDetailsLoader;
