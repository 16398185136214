import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTheme } from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";

export default function useQRCode() {
  const { pathname } = useLocation();
  const { colors } = useTheme();
  const { shareModule } = useSelector(state => state.globalComponent);
  const share_stage = shareModule.stage || "";
  const posUser =
    localStorage.getItem("Pos_details") &&
    JSON.parse(localStorage.getItem("Pos_details"));

  const { data } = useGetEnquiry();

  const {
    tenant,
    settings: { scan_qr_text },
  } = useFrontendBoot();

  const [pageName, setPageName] = useState("");

  useEffect(() => {
    setPageName(
      Object.keys(scan_qr_text || {}).filter(page => pathname.includes(page)),
    );
  }, []);

  return {
    pageName,
    scan_qr_text,
    share_stage,
    posUser,
    data,
    tenant,
    colors,
  };
}
