import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

export default function useGetQuotesForCanvas() {
  const isComparePageRoute = useRouteMatch({ path: "/compare" });
  const { baseCombinedQuotes } = useSelector(state => state.differentQuotes);

  let pageId = isComparePageRoute ? "printCompare" : "share-quotation";

  async function getQuotationImage() {
    const input = document.getElementById(pageId);
    if (!input) return;
    try {
      const canvas = await html2canvas(input, {
        scrollX: 0,
        scrollY: -window.scrollY,
        allowTaint: true,
        useCORS: true,
        scale: 0.9,
      });

      const imgData = canvas.toDataURL("image/jpeg");
      return imgData.split(",")[1];
    } catch (error) {
      console.error(error);
      return "";
    }
  }
  return {
    baseCombinedQuotes,
    getQuotationImage,
  };
}
