import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  policy_type: {},
  sortBy: {
    value: "",
    sortCacheResult: {},
    isSortFilterChanged: false,
  },
  premium: {
    value: {},
  },
  deductible: {
    value: {},
  },
  covers: {
    value: {},
    loadingOnChange: false,
  },
  multiYear: {
    value: {},
  },
  planType: {
    value: {},
  },
  insurer: {
    value: [],
  },
  moreFilter: {
    value: {},
  },
  mobileFilterObject: {},
};

export const quoteFilterSlice = createSlice({
  name: "quoteFilters",
  initialState,
  reducers: {
    setSortByFilterAction: (state, actions) => {
      state.sortBy = {
        ...state.sortBy,
        value: actions.payload,
        isSortFilterChanged: true,
      };
    },
    setSortResultToCache: (state, actions) => {
      state.sortBy = {
        ...state.sortBy,
        sortCacheResult: {
          ...state.sortBy.sortCacheResult,
          ...actions.payload,
        },
      };
    },

    clearSortCachedResult: state => {
      state.sortBy = { ...state.sortBy, sortCacheResult: {} };
    },

    // premiumFilterActions
    setPremiumFilterAction: (state, actions) => {
      state.allQuotes = { value: [] };
      state.premium = { ...state.premium, value: actions.payload };
    },

    // covers filter actions
    setCoverFilterAction: (state, actions) => {
      state.covers = { ...state.covers, value: actions.payload };
    },
    // deductible filter actions
    setDeductibleFilterAction: (state, actions) => {
      state.deductible = { ...state.deductible, value: actions.payload };
    },

    // multiYear filter actions
    setMultiYearFilterAction: (state, actions) => {
      state.multiYear = { ...state.multiYear, value: actions.payload };
    },

    // planType  filter actions
    setPlanTypeFilterAction: (state, actions) => {
      state.planType = { ...state.planType, value: actions.payload };
    },

    setPolicyTypeFilterAction: (state, actions) => {
      state.policy_type = { ...actions.payload };
    },

    // insurer filter actions
    setInsurerFilterAction: (state, actions) => {
      state.insurer = { ...state.insurer, value: actions.payload };
    },

    // more filter actions
    setMoreFilterAction: (state, actions) => {
      state.moreFilter = { ...state.moreFilter, value: actions.payload };
    },

    // mobileFilters
    setMobileFilterObjectAction: (state, actions) => {
      state.mobileFilterObject = {
        ...state.mobileFilterObject,
        [actions.payload.key]: actions.payload.value,
      };
    },
    clearMobileFilterObjectActions: (state, actions) => {
      state.mobileFilterObject = actions.payload;
    },
  },
});

export const {
  setSortByFilterAction,
  setSortResultToCache,
  clearSortCachedResult,
  setPremiumFilterAction,
  setCoverFilterAction,
  setDeductibleFilterAction,
  setMultiYearFilterAction,
  setPlanTypeFilterAction,
  setPolicyTypeFilterAction,
  setInsurerFilterAction,
  setMoreFilterAction,
  setAllQuotesAction,
  setMobileFilterObjectAction,
  clearMobileFilterObjectActions,
} = quoteFilterSlice.actions;

export default quoteFilterSlice.reducer;
