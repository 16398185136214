import styled from "styled-components/macro";
// DESIGN SYSTEM
import {
  FLEX_COL,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import { VIEW, MAX } from "src/styles/responsiveStyle";
// CUSTOM COMPONENTS
import { Button } from "src/components/Common/Button/ContinueButton";
import IconButton from "src/components/Common/Button/IconButton";

const QuoteInfoWrapperStyled = styled.div`
  border: solid 1px ${SHADES.DROP_SHADE};
  padding: 0.75rem 1.25rem;
  flex: 1;
  border-radius: ${ROUNDED.lg};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  .discount_icon {
    color: rgb(54, 179, 126);
  }

  > span {
    color: #bac3cf;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 0.25rem;

    ${VIEW.xxl} {
      flex-direction: row;
    }
  }
  ${VIEW.xxl} {
    justify-content: center;
    padding: 1.25rem;
  }
`;

const ProductHeaderWrap = styled.div`
  height: 100px;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${SHADOW.lg};
  gap: 0.75rem;
  .logo-name {
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  ${VIEW.xl} {
    justify-content: center;
    padding: 0 1.5rem;
  }
`;

const DesktopSeeDetailsHeaderWrapperStyled = styled.div`
  ${FLEX_ROW_CENTER}
  gap: 1.25rem;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  box-shadow: ${SHADOW.lg};

  .infowrapper {
    ${FLEX_ROW_CENTER}
    justify-content: space-between;
    gap: 1.25rem;
  }

  .logo-name {
    width: 24%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  ${VIEW.lg} {
    gap: 0.5rem;
  }

  ${VIEW.xl} {
    justify-content: space-between;
    padding: 1.25rem;
    gap: 1.25rem;
  }
  border-top-left-radius: ${ROUNDED.xxl};
  border-top-right-radius: ${ROUNDED.xxl};
  ${MAX.lg} {
    display: none;
  }
`;

const ProductName = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "semi",
}))`
  padding: 5px;
  max-width: 60%;
`;

const QuoteInfoHeading = styled(FHTypography).attrs(() => ({
  variant: "subheading2",
  weight: "semi",
}))`
  display: flex;
  align-items: center;
  svg {
    height: fit-content;
    margin: 0 0.25rem;
  }
  ${VIEW.xxl} {
    ::after {
      content: " :";
    }
  }
`;

export const CsrStyled = styled.div`
  & > div {
    display: flex !important;
    gap: 0.1rem;
  }
`;

const QuoteInfoValue = styled(FHTypography).attrs(() => ({
  variant: "body1",
  weight: "md",
}))`
  padding-left: 6px;
`;

const CloseButton = styled.button``;

const ButtonStyled = styled(Button)`
  padding: 0.75rem;
`;

const MobileSeeDetailsNav = styled.div`
  ${FLEX_ROW}
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  width: 100%;
  background-color: ${props => props.primary_color};
  padding: 1.125rem 0.75rem;

  svg {
    font-size: 1.25rem;
  }
`;

const MobileSeeDetailsHeaderStyled = styled.div`
  width: 100%;
  ${VIEW.lg} {
    display: none;
  }
`;

const ProductHeaderWrapperStyled = styled.div`
  ${FLEX_COL}
  gap: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 0 0.5rem;
  width: 100%;
  height: 104px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 16px 0px;
  background-color: #fff;
`;

const ICInfoWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

const ICDetailsWrapper = styled.div`
  ${FLEX_ROW}
  justify-content:space-evenly;
  height: fit-content;
  & > div:not(:last-child) {
    padding-right: 0.5rem;
    border-right: 1px solid ${SHADES.LIGHT_GRAY};

    @media screen and (max-width: 375px) {
      padding-right: 0.2rem;
    }
  }
`;

const ICDetailStyled = styled.div`
  ${FLEX_COL}
  gap:0.5rem;
  padding-right: 0.5rem;
  .info_icon {
    margin-left: 0.2rem;
  }
  @media screen and (max-width: 445px) {
    gap: 0.1rem;
    padding-right: 0;
  }
`;

const ICDetailName = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "md",
  colorType: "subHeading",
}))`
  .discount_icon {
    color: rgb(54, 179, 126);
  }
`;

const ICDetailValue = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "semi",
  colorType: "heading",
}))``;

const PremiumWrapperStyled = styled.div`
  display: flex;
  column-gap: 8px;
`;

const QuoteDetailLoader = styled.div`
  width: 3.5rem;
  height: 1.25rem;
  border-radius: ${ROUNDED.base};

  ${VIEW.md} {
    height: 1rem;
  }

  ${VIEW.lg} {
    width: 4rem;
    height: 1.25rem;
  }

  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, 0.5) 50%,
      hsla(0, 0%, 100%, 0) 80%
    ),
    #f1f1f1;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: Animation 1.5s ease-in-out alternate infinite;

  @keyframes Animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }
`;

const IconButtonStyled = styled(IconButton)`
  padding: 0.5rem;
  background-color: ${SHADES.WHITE};
  box-shadow: ${SHADOW.lg};
  font-size: 0.75rem;
  svg {
    color: #000 !important;
  }
  ${VIEW.xl} {
    font-size: 1rem;
  }
`;

const IconWrappwer = styled.div`
  width: fit-content;
  @media screen and (max-width: 445px) {
    svg {
      font-size: 10px;
    }
  }
`;

export {
  QuoteInfoWrapperStyled,
  ProductHeaderWrap,
  DesktopSeeDetailsHeaderWrapperStyled,
  IconButtonStyled,
  ProductName,
  QuoteInfoHeading,
  QuoteInfoValue,
  CloseButton,
  ButtonStyled,
  QuoteDetailLoader,
  // mobile styled components
  MobileSeeDetailsNav,
  MobileSeeDetailsHeaderStyled,
  ProductHeaderWrapperStyled,
  ICInfoWrapper,
  ICDetailsWrapper,
  ICDetailStyled,
  ICDetailName,
  ICDetailValue,
  PremiumWrapperStyled,
  IconWrappwer,
};
