const TOPUP_PATHS = ["topup", "top-up", "supertop", "top_up"];

// function to check if the current journey is renewal
export function isRenewals() {
  if (
    window.location.host.toLowerCase().includes("renewal") ||
    process.env.PUBLIC_URL.toLowerCase().includes("renewal")
  ) {
    return true;
  }
  return false;
}

export function isPortability() {
  if (
    window.location.host.toLowerCase().includes("portability") ||
    process.env.PUBLIC_URL.toLowerCase().includes("portability")
  ) {
    return true;
  }
  return false;
}

// function to check if the current journey is topup
export function checkTopup() {
  let isTopup = false;

  TOPUP_PATHS.forEach(val => {
    const fullURL =
      window.location.host.toLowerCase() + process.env.PUBLIC_URL.toLowerCase();
    if (fullURL.includes(val)) {
      isTopup = true;
      return;
    }
  });
  return isTopup;
}
