import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/api";
import { policyPdf } from "../../ThankYouPage/serviceApi";
import {
  getProposal,
  PaymentStatus,
  saveProposal,
  fetchUnderWritingMQ,
  submitProposal,
  getMedicalUrls,
  getMedicalLetter,
} from "./serviceApi";
import toast from "react-hot-toast";
import { setIsContinueBtn } from "../schema.slice";

const kycSectionInitial = {
  redirectionUtility: [],
  ckyc_reference_id: "",
  canContinue: true,
  kycFailed: false,
};

const proposal = createSlice({
  name: "proposal",
  initialState: {
    proposalData: {},
    proposalDataLoading: true,
    isLoading: false,
    medicalUnderwritingLetters: {},
    Payment: false,
    showBMI: false,
    mediUnderwritting: false,
    showPlanNotAvail: false,
    showNSTP: false,
    activeIndex: 0,
    policyStatus: [],
    noForAllChecked: false,
    kycSection: kycSectionInitial,
    docUploadUtility: {
      showDocFields: false,
    },
    showGroupOTPVerificationModal: false,
    formRenderingModal: {
      visible: false,
      json: {},
    },
    proposalRefetchModal: {
      visible: false,
      title: "",
    },
    proposalRefetchConfirmationModal: {
      visible: false,
      title: "",
      forNameUpdate: false,
      updateProposalData: false,
      currentCartId: null,
    },
    policyLoading: true,
    failedBmiData: {},
    insuredDetailsResponse: {},
    medicalUrlsRuleEngine: false,
    bmiFailBlock: false,
    failedBmiBlockJourney: false,
    underWritingStatus: [],
    muApplicationResult: "",
    showErrorPopup: {
      show: false,
      head: "",
      msg: "",
      onCloseCallBack: () => {},
    },
    selectedIcs: [],
    isPopupOn: false,
    groupMappedProposalNumber: {},
    failedAsyncPanNumber: "",
    updatedTenurePremium: null,
    retryKYCSection: false,
    proposalSectionHeight: 0,
    showRedoKYCPopup: false,
    isStatusBtnInitiate: false,
  },
  reducers: {
    setProposalData: (state, action) => {
      !Object.keys(action.payload)?.length
        ? (state.proposalData = {})
        : (state.proposalData = { ...state.proposalData, ...action.payload });
      state.isLoading = false;
    },

    setShowErrorPopup: (state, action) => {
      state.showErrorPopup = action.payload;
    },
    noForAllCheckedFalse: state => {
      state.noForAllChecked = false;
    },
    noForAllCheckedTrue: state => {
      state.noForAllChecked = true;
    },
    clearProposalData: state => {
      state.proposalData = {};
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setPolicyStatus: (state, { payload }) => {
      state.policyStatus = payload;
      state.policyLoading = false;
    },
    setInsuredDetailsResponse: (state, { payload }) => {
      state.insuredDetailsResponse = payload;
    },
    setMedicalUnderwritingLetters: (state, { payload }) => {
      state.medicalUnderwritingLetters = payload;
    },
    setPayment: (state, { payload }) => {
      state.Payment = payload;
    },
    setFailedBmiData: (state, { payload }) => {
      state.failedBmiData = payload;
    },
    setMedUnderwritting: (state, { payload }) => {
      state.mediUnderwritting = payload;
    },
    setShowBMI: (state, { payload }) => {
      state.showBMI = payload;
    },
    setShowPlanNotAvail: (state, { payload }) => {
      state.showPlanNotAvail = payload;
    },
    setShowNSTP: (state, { payload }) => {
      state.showNSTP = payload;
    },
    setProposalDataLoading: (state, { payload }) => {
      state.proposalDataLoading = payload;
    },
    setActiveIndex: (state, { payload }) => {
      state.activeIndex = payload;
    },
    setMedicalUrlsRuleEngine: (state, { payload }) => {
      state.medicalUrlsRuleEngine = payload;
    },
    setIsPopupOn: (state, { payload }) => {
      state.isPopupOn = payload;
    },
    setFailedBmiBlockJourney: (state, { payload }) => {
      state.failedBmiBlockJourney = payload;
    },
    setBmiFailBlock: (state, { payload }) => {
      state.bmiFailBlock = payload;
    },
    setUnderWritingStatus: (state, { payload }) => {
      state.underWritingStatus = payload;
    },
    setMuApplicationResult: (state, { payload }) => {
      state.muApplicationResult = payload;
    },
    setGroupMappedProposalNumber: (state, { payload }) => {
      state.groupMappedProposalNumber = payload;
    },
    setFailedAsyncPanNumber: (state, { payload }) => {
      state.failedAsyncPanNumber = payload;
    },
    setUpdatedTenurePremium: (state, { payload }) => {
      state.updatedTenurePremium = payload;
    },
    setRetryKYCSection: (state, { payload }) => {
      state.retryKYCSection = payload;
    },
    setProposalSectionHeight: (state, { payload }) => {
      state.proposalSectionHeight = payload;
    },
    setCKYCReferenceId: (state, { payload }) => {
      state.kycSection.ckyc_reference_id = payload;
    },
    setKYCRedirectionURL: (state, { payload }) => {
      state.kycSection.redirectionUtility = payload;
    },
    setKYCCanContinue: (state, { payload }) => {
      state.kycSection.canContinue = payload;
    },
    setShowRedoKYCPopup: (state, { payload }) => {
      state.showRedoKYCPopup = payload;
    },
    setKYCFailed: (state, { payload }) => {
      state.kycSection.kycFailed = payload;
    },
    setKYCRedirectionUrlViaForm: (state, { payload }) => {
      state.kycSection.redirectionUtility.meta_data = payload.meta_data;
      state.kycSection.redirectionUtility.redirection_url_via_form =
        payload.doRedirect;
    },
    setDocUploadUtility: (state, { payload }) => {
      state.docUploadUtility.showDocFields = payload;
    },
    setProposalRefetchModal: (state, { payload }) => {
      state.proposalRefetchModal = {
        ...state.proposalRefetchModal,
        ...payload,
      };
    },
    setRevalidateKYCVerificationUtility: (state, { payload }) => {
      state.kycSection.redirectionUtility[0].revalidateUtility = {
        ...state.kycSection.redirectionUtility[0].revalidateUtility,
        ...payload,
      };
    },
    setShowGroupOTPVerificationModal: (state, { payload }) => {
      state.showGroupOTPVerificationModal = payload;
    },
    setFormRenderingModal: (state, { payload }) => {
      state.formRenderingModal = payload;
    },
    setProposalRefetchConfirmationModal: (state, { payload }) => {
      state.proposalRefetchConfirmationModal = {
        ...state.proposalRefetchConfirmationModal,
        ...payload,
      };
      //* If confirmation modal is on then there's no need for ordinary refetch modal.
      if (payload?.visible) {
        state.proposalRefetchModal = {
          visible: false,
          title: "",
        };
      }
    },
    clearKYCSection: state => {
      state.kycSection = kycSectionInitial;
    },
    clearMedicalDetails: state => {
      const currentProposalData = state.proposalData;
      delete currentProposalData?.["Medical Details"];
      state.proposalData = currentProposalData;
    },
    setIsStatusBtnInitiate: (state, action) => {
      state.isStatusBtnInitiate = action.payload;
    },
  },
});
export const {
  setIsLoading,
  setProposalData,
  clearProposalData,
  setPayment,
  setMedUnderwritting,
  setShowPlanNotAvail,
  setShowBMI,
  setShowNSTP,
  setActiveIndex,
  noForAllCheckedFalse,
  noForAllCheckedTrue,
  setShowErrorPopup,
  setFailedBmiData,
  setPolicyStatus,
  pushLoadingStack,
  setIsPopupOn,
  setInsuredDetailsResponse,
  setBmiFailBlock,
  setFailedBmiBlockJourney,
  setUnderWritingStatus,
  setMedicalUnderwritingLetters,
  setMedicalUrlsRuleEngine,
  setKYCCanContinue,
  setProposalDataLoading,
  setMuApplicationResult,
  setGroupMappedProposalNumber,
  setFailedAsyncPanNumber,
  clearMedicalDetails,
  setKYCRedirectionURL,
  setKYCFailed,
  setKYCRedirectionUrlViaForm,
  setDocUploadUtility,
  setProposalRefetchModal,
  setProposalRefetchConfirmationModal,
  setRevalidateKYCVerificationUtility,
  setCKYCReferenceId,
  clearKYCSection,
  setShowGroupOTPVerificationModal,
  setFormRenderingModal,
  setUpdatedTenurePremium,
  setRetryKYCSection,
  setProposalSectionHeight,
  setShowRedoKYCPopup,
  setIsStatusBtnInitiate,
} = proposal.actions;

export const getMedicalUnderwritingStatus = (
  medicalLetterCallback = () => {},
) => {
  return async dispatch => {
    try {
      dispatch(setIsStatusBtnInitiate(true));
      const { data } = await fetchUnderWritingMQ();
      if (typeof data !== "string" && data?.length) {
        dispatch(setUnderWritingStatus(data));
        dispatch(getMUResultLetters(medicalLetterCallback));
        dispatch(setIsStatusBtnInitiate(false));
      } else {
        dispatch(setIsStatusBtnInitiate(false));
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      dispatch(setIsStatusBtnInitiate(false));
      toast.error("Failed to get underwriting status. Please retry.");
    }
  };
};

export const saveProposalData = (
  proposalData = {},
  next = () => {},
  fail = () => {},
) => {
  return async (dispatch, state) => {
    try {
      let prevState = state();

      let prevProposalData = prevState.proposalPage.proposalData;

      let prevCart = prevState.cart;

      dispatch(setIsLoading(true));

      const response = await saveProposal(proposalData);

      dispatch(api.util.invalidateTags(["ProposalSummaryUpdate"]));

      dispatch(setProposalData(proposalData));

      if (response.statusCode === 200) {
        next({
          responseData: response.data,
          prevProposalData,
          prevCart,
          updatedProposalData: state().proposalPage.proposalData,
        });
        dispatch(setIsContinueBtn(false));
      } else if (!response.data) {
        if (typeof response.errors === "object") {
          Object.keys(response.errors).map(i => {
            dispatch(
              setShowErrorPopup({
                show: true,
                head: i,
                msg: response.errors[i],
              }),
            );
          });
        } else {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "Error",
              msg: response.message,
            }),
          );
        }
      } else if (response?.errors) {
        dispatch(setIsContinueBtn(false));
        Object.keys(response.errors).map(i => {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: i,
              msg: response.errors[i],
            }),
          );
        });
      } else if (response?.data?.redirection_url) {
        dispatch(
          setKYCRedirectionURL([
            {
              redirection_url: response.data.redirection_url,
              group_id: response.data?.group_id,
            },
          ]),
        );
        dispatch(setIsLoading(false));
      } else if (response?.message) {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "Error",
            msg: response.message,
          }),
        );
      }
    } catch (err) {
      console.error("The save failed error => ", err);
      fail();
      dispatch(setIsLoading(false));
      toast.error("Failed to save current section data. Please retry.");
    }
  };
};

export const fetchPdf = () => {
  return async dispatch => {
    try {
      const { data } = await policyPdf();
      dispatch(setPolicyStatus(data?.data));
    } catch (err) {
      console.error(err);
    }
  };
};

export const getProposalData = (successCallBack = () => {}) => {
  return async (dispatch, state) => {
    try {
      dispatch(setProposalDataLoading(true));

      const { data, statusCode } = await getProposal();

      if (statusCode === 200) {
        const responseData = {};

        const { activeIndex } = state().proposalPage;

        Object.keys(data.data).forEach(item => {
          if (!(data.data[item] instanceof Array)) {
            responseData[item] = data?.data[item];
          }
        });

        dispatch(setProposalData(responseData));

        activeIndex !== 0 &&
          !activeIndex &&
          dispatch(
            setActiveIndex(
              Object.keys(responseData).length >= 4
                ? 3
                : Object.keys(responseData).length,
            ),
          );

        successCallBack && successCallBack();
      }
      dispatch(setProposalDataLoading(false));
    } catch (err) {
      toast.error("Failed to get proposal data. Please retry.");
      dispatch(setProposalDataLoading(false));
    }
  };
};

// error constructor
function UserException(message) {
  this.message = message;
  this.name = "UserException";
}

export const submitProposalData = ({
  next = () => {},
  enquiryId = "",
  groups = [],
  fail = () => {},
}) => {
  return async dispatch => {
    try {
      const res = await submitProposal({ enquiryId: enquiryId });

      if (res.statusCode === 200) {
        //* Proposal Number Extraction Code Against Group Code ---------------------------
        let groupMappedProposalNumberObj = {};

        res?.data?.data?.forEach(item => {
          let matchedGroupCode = groups?.find(
            group =>
              JSON.stringify(group?.members) === JSON.stringify(item?.members),
          )?.id;

          groupMappedProposalNumberObj = {
            ...groupMappedProposalNumberObj,
            [matchedGroupCode]: item.proposal_no,
          };
        });

        dispatch(setGroupMappedProposalNumber(groupMappedProposalNumberObj));
        //* ----------------------------------------------------------------------------

        if (res.data.data.some(obj => !!obj?.premium_changed_due_to_bmi)) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg:
                res.data?.data?.find(obj => obj?.premium_revised_popup_message)
                  ?.premium_revised_popup_message ||
                "Based on your proposal form details your premium is revised by the insurer.",
              handleClose: () => {
                next();
              },
            }),
          );
        } else if (
          res.data.data.some(obj => !!obj?.credit_score_discount_availed)
        ) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: "Credit Score Discount has been availed for you!",
              handleClose: () => {
                next();
              },
            }),
          );
        } else if (
          res.data.data.some(obj => !!obj?.premium_changed_due_to_loading)
        ) {
          dispatch(
            setShowErrorPopup({
              show: true,
              head: "",
              msg: `Premium has changed to ₹${res?.data?.data?.[0]?.total_premium} due to loading.`,
              handleClose: () => {
                next();
              },
            }),
          );
        } else {
          next();
        }
      } else if (res?.data?.CKYCDetailsNotMatching) {
        throw {
          message: res.message,
          CKYCDetailsNotMatching: res.data.CKYCDetailsNotMatching,
        };
      } else if (res.message) {
        throw new UserException(res.message);
      } else if (res?.data?.is_otp_sent) {
        dispatch(setShowGroupOTPVerificationModal(true));
      }
    } catch (err) {
      toast.error("Failed to submit proposal. Please retry.");

      fail(err);

      if (err.message) {
        dispatch(
          setShowErrorPopup({
            show: true,
            head: "",
            msg: err.message,
            CKYCDetailsNotMatching: err?.CKYCDetailsNotMatching || false,
          }),
        );
      }
    }
  };
};

export const getPaymentStatus = data => {
  return async dispatch => {
    try {
      const response = await PaymentStatus(data);

      if (response?.data) {
        dispatch(setPayment(response?.data[0].status === "success"));
      }
    } catch (err) {
      alert(err);
    }
  };
};

export const getMedicalUrlsRuleEngine = callBack => {
  return async dispatch => {
    try {
      const response = await getMedicalUrls();
      if (response?.data?.data?.members) {
        dispatch(setMedicalUrlsRuleEngine(response?.data?.data?.members));
      }
      callBack && callBack();
    } catch (err) {
      alert(err);
    }
  };
};

export const getMUResultLetters = callback => {
  return async dispatch => {
    try {
      const response = await getMedicalLetter();

      callback && callback();

      if (response?.data) {
        dispatch(setMedicalUnderwritingLetters(response?.data));
        dispatch(setIsLoading(false));
        dispatch(setIsStatusBtnInitiate(false));
      }
    } catch (err) {
      dispatch(setIsLoading(false));
      dispatch(setIsStatusBtnInitiate(false));
      alert(err);
    }
  };
};

export default proposal.reducer;
