// TRY ADDING THE SCROLL TOP BEHAVIOR
import React from "react";
import styled from "styled-components";
import {
  FLEX_COL,
  FONT,
  FWEIGHT,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import { VIEW } from "src/styles/responsiveStyle";

const TextInput2 = ({
  label,
  name,
  type,
  placeholder,
  autoComplete,
  value,
  onChange,
  onPaste,
  onKeyDown,
  onBlur,
  maxLength,
  inputRef,
  style,
  autoFocus,
  id,
  disabled = false,
  className = "",
}) => {
  return (
    <InputContainerStyled>
      <label htmlFor={id}>
        <FHTypography variant="subheading1" weight="md" colorType="body">
          {label}
        </FHTypography>
      </label>
      <input
        ref={inputRef}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onPaste={onPaste}
        style={style}
        disabled={disabled}
        className={`${FONT.h6} ${className}`}
      />
    </InputContainerStyled>
  );
};

export default React.memo(TextInput2);

const InputContainerStyled = styled.div`
  cursor: text;
  width: 100%;
  ${FLEX_COL};
  position: relative;
  padding: 0 0.3rem;
  padding-top: 3.5rem;

  label {
    padding: 0 !important;
    position: absolute;
    top: 0.5rem;
    left: 0.8rem;
    z-index: 1;
  }

  input {
    padding: 0 0.8rem;
    padding-top: 1.25rem;
    font-weight: ${FWEIGHT.md};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: ${ROUNDED.md};
    border: 1px solid ${SHADES.DARK_GRAY};
    background-color: transparent;
    &:disabled {
      user-select: none;
      opacity: 0.75;
    }
  }

  ${VIEW.md} {
    padding-top: 4rem;
  }
  & label {
    padding: 19px 0.75rem;
    color: #505b6d;
    font-weight: 600;
    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
