// REACT ICONS
import { RiSearchLine } from "react-icons/ri";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// STYLED COMPONENTS
import {
  CurrentCityStyled,
  SearchBarOuter,
  SearchBarWrapperStyled,
  SearchInput,
} from "./CashlessHospitals.style";

export default function HospitalSearchBar({
  searchText,
  setSearchText,
  currentCity,
}) {
  const { colors } = useTheme();

  const searchInputChangeHandler = e => {
    setSearchText(e.target.value);
  };

  return (
    <SearchBarWrapperStyled>
      <CurrentCityStyled colors={colors}>{currentCity}</CurrentCityStyled>
      <SearchBarOuter>
        <SearchInput
          type="text"
          onChange={searchInputChangeHandler}
          placeholder="Search Hospitals"
          value={searchText}
        />
        <RiSearchLine
          size={window.innerWidth < 470 ? "26px" : "28px"}
          color="#777777"
        />
      </SearchBarOuter>
    </SearchBarWrapperStyled>
  );
}
