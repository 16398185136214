import { useEffect, useState } from "react";

export const useResponsiveCondition = (breakpoint = 620) => {
  // responsive condition
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width, breakpoint };
};
