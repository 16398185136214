import { BsWhatsapp } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiMessage2Fill } from "react-icons/ri";

// Inputs for share module
const SHARE_OPTIONS = [
  { label: "email", placeholder: "Email", icon: <MdEmail /> },

  {
    label: "whatsapp",
    placeholder: "Mobile No.",
    icon: <BsWhatsapp />,
  },
  {
    label: "sms",
    placeholder: "Mobile No.",
    icon: <RiMessage2Fill />,
  },
];

// Canvas header heading text array
const CANVAS_HEADER_HEADING_TEXT = ["Insurer", "Premium", "Cover", "Proceed"];

// Share module stages
const SHARE_MODULE_STAGES = {
  quote: "QUOTE",
  shortlisted: "SHORTLISTED_QUOTES",
  compare: "COMPARE",
  proposal: "PROPOSAL",
  summary: "PROPOSAL_SUMMARY",
  product: "RENEWAL_PRODUCT_DETAILS",
};

export { SHARE_OPTIONS, CANVAS_HEADER_HEADING_TEXT, SHARE_MODULE_STAGES };
