import { useState } from "react";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  ClaimFileButtonStyled,
  ClaimFileButtonWrapperStyled,
  ClaimFilingContentParagraph,
  ClaimFilingContentWrapperStyled,
  ClaimFilingInfoContainerStyled,
} from "./ClaimProcess.styled";

export default function ClaimFilingInfo({ claimFilingData }) {
  const { colors } = useTheme();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <ClaimFilingInfoContainerStyled>
      <FHTypography variant="h5" weight="md" colorType="heading">
        How do I file a claim ?
      </FHTypography>
      <ClaimFileButtonWrapperStyled>
        {claimFilingData?.map(({ title }, index) => (
          <ClaimFileButtonStyled
            key={index}
            colors={colors}
            onClick={() => setActiveTab(index)}
            data-tab-active={activeTab === index}
          >
            <FHTypography variant="h6" weight="md">
              {title}
            </FHTypography>
          </ClaimFileButtonStyled>
        ))}
      </ClaimFileButtonWrapperStyled>
      <ClaimFilingContentWrapperStyled>
        <ClaimFilingContentParagraph
          dangerouslySetInnerHTML={{
            __html: claimFilingData?.[activeTab]?.data,
          }}
        />
      </ClaimFilingContentWrapperStyled>
    </ClaimFilingInfoContainerStyled>
  );
}
