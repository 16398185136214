import { FaChevronDown } from "react-icons/fa";
import { useTheme } from "../customHooks";
import Select, { components } from "react-select";
import FHTypography from "src/styles/Typography";
import CustomToolTip from "./Common/CustomTooltip";

const CustomSelect = ({
  optionsList,
  selectedOption,
  handleChange,
  tooltip_text = "",
  isDisabled = false,
}) => {
  const { colors } = useTheme();
  return (
    <CustomToolTip description={tooltip_text} showInfoIcon={false}>
      <Select
        isSearchable={false}
        menuPlacement="auto"
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            background: colors.primary_shade,
            primary: colors.primary_shade,
            primary25: colors.primary_color,
          },
        })}
        isDisabled={isDisabled}
        components={{ DropdownIndicator, Option, SingleValue }}
        styles={{
          option: provided => ({
            ...provided,
            fontSize: "13px",
            padding: "7px 7px !important",
            textAlign: "left",
            color: "black",
            cursor: "pointer",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: colors.primary_shade,
            },
          }),
          menuList: provided => ({
            ...provided,
            maxHeight: 140,
            "::-webkit-scrollbar": {
              width: "4px",
              height: "0px",
              margin: "0px 0px",
            },
          }),
          menu: provided => ({
            ...provided,
            fontWeight: "bold",
            textAlign: "left",
            overflow: "auto",
          }),
          valueContainer: provided => ({
            ...provided,
            padding: 0,
            fontWeight: "bold",
          }),
          indicatorSeparator: () => ({ display: "none" }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
            color: "var(--body_color)",
            cursor: "pointer",
            transition: "all 150ms ease",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
          }),
          control: provided => ({
            ...provided,
            fontSize: "14px",
            backgroundColor: colors.primary_shade,
            padding: "5px 5px",
            minHeight: "initial",
            minWidth: "90px",
            outline: "none",
            border: "0 !important",
            cursor: "pointer",
            boxShadow: "0 !important",
            "&:hover": {
              border: "0 !important",
            },
          }),
        }}
        options={optionsList?.map(option => ({
          value: option?.code,
          label: option?.display_name,
          key: option?.code,
        }))}
        value={{
          value: selectedOption?.code,
          label: selectedOption?.display_name,
          key: selectedOption?.code,
        }}
        onChange={handleChange}
      />
    </CustomToolTip>
  );
};

export default CustomSelect;

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown />
    </components.DropdownIndicator>
  );
};

export const Option = props => {
  return (
    <components.Option {...props}>
      <FHTypography variant="subheading1" weight="md" colorType="heading">
        {props.children}
      </FHTypography>
    </components.Option>
  );
};

export const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <FHTypography variant="subheading1" weight="md" colorType="heading">
        {props.children}
      </FHTypography>
    </components.SingleValue>
  );
};
