import { useUpdatePosCoverMutation } from "src/api/api";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";
import { useUpdateEnquiry } from "src/customHooks";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { removeHtmlTagsFromString } from "src/utils/string.utility";

export default function usePosSwitch() {
  const [update] = useUpdatePosCoverMutation();
  const { enquiryData } = useGetEnquiry();
  const { updateEnquiry } = useUpdateEnquiry();
  const {
    journeyType,
    data: {
      settings: { pos_nonpos_switch_message },
    },
  } = useFrontendBoot();
  const { handleOpenInfoPopup } = useInfoPopup();

  const handleB2BToB2C = async ({
    onSuccess = () => {},
    onError = () => {},
    onPopupClick = () => {},
  }) => {
    const res = await update({ trace_id: enquiryData?.trace_id });
    if (res?.data?.status) {
      localStorage.removeItem("SSO_user");
      localStorage.removeItem("Pos_details");
      await updateEnquiry({
        params: {
          journeyType: "B2C",
          journey_initiated_from_url: `${
            window.location.origin + process.env.PUBLIC_URL
          }`,
          source: journeyType,
        },
      });
      onSuccess();
      handleOpenInfoPopup({
        title: "",
        description:
          res?.data?.message_for_popup ||
          removeHtmlTagsFromString(pos_nonpos_switch_message),
        hideCloseButton: false,
        buttonText: "OK",
        onClick: onPopupClick,
      });
    } else {
      onError();
      handleOpenInfoPopup({
        title: "",
        description:
          res?.data?.message_for_popup ||
          "Unable to Fetch Data. Please contact your Administrator",
        hideCloseButton: false,
        buttonText: "OK",
      });
    }
  };

  return { handleB2BToB2C };
}
