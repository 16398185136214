/* READ-IT
    This file contains code that will block the journey without login for different brokers
    ENV key: REACT_APP_BLOCK_JOURNEY=true
    used in: PageLoader.jsx (AllValidations component)
*/
import { useEffect } from "react";
import { BLOCK_JOURNEY } from "src/components/Common/Popups/InfoPopup/InfoPopup.helpers";
import useInfoPopup from "src/components/Common/Popups/InfoPopup/useInfoPopup";
import {
  doBlockJourney,
  getRedirection,
} from "src/components/Common/Popups/utilities/blockJourney.utility";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useUrlQueries } from "src/customHooks/useUrlQuery";
import { STORED_TOKEN_SSO } from "src/pos/utility";
import { useGetEnquiry } from "./useGetCommonQueries";

export default function useBlockJourney() {
  const { handleOpenInfoPopup } = useInfoPopup();
  const { tenant } = useFrontendBoot();
  const { token } = useUrlQueries();
  const { data, isLoading } = useGetEnquiry();

  const renewalDomain = tenant?.health_renewal_frontend_domain;
  const isRenewalsDomain = renewalDomain === window.location.host;
  const { input } = data?.data || {};

  useEffect(() => {
    if (!tenant) {
      return () => {};
    }

    const doBlock = doBlockJourney({
      urlToken: STORED_TOKEN_SSO() || token,
      enquiryToken: input,
      isLoading: isLoading,
      isRenewalsDomain,
    });

    if (doBlock) {
      handleOpenInfoPopup({
        ...BLOCK_JOURNEY,
        href: getRedirection({ tenant }),
      });
    }
    return () => {};
  }, [token, input, isLoading, tenant]);

  return {};
}
