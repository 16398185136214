import React from "react";
import FHTypography from "src/styles/Typography";
import { MAX, VIEW } from "src/styles/responsiveStyle";
import styled from "styled-components";
import CartToggleProductCTA from "./CartToggleProductCTA";
import { figureToWords } from "src/utils/helper";
import { stringLengthCutter } from "src/utils/string.utility";
import ICLogoBase from "../Common/ICLogo";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { usePortJourneyConfig } from "src/customHooks";
import { ROUNDED, SHADES, SHADOW } from "src/styles/styleGuide";

function CartProductSummaryCard({
  cartEntry,
  groupCode,
  showQuoteDeleteMsg,
  group,
  closeModal,
  allClose,
  setShowQuoteDeleteMsg,
  setQuoteDeleteMsg,
  quoteDeleteMsg,
  ...props
}) {
  const {
    deductible,
    sum_insured,
    premium,
    tenure,
    product: {
      company: { alias },
      name,
    },
  } = cartEntry;
  const { planTypeDecider } = usePortJourneyConfig();

  const { journeyType } = useFrontendBoot();

  return (
    <ProductSummaryCardStyled
      showQuoteDeleteMsg={showQuoteDeleteMsg}
      {...props}
    >
      <ProductInfoWrapperStyled>
        <ICLogoWrapperStyled>
          <ICLogoBase alias={alias} />
        </ICLogoWrapperStyled>
        <ProductNameWrapperStyled title={name}>
          <ProductNameStyled>{stringLengthCutter(name, 48)}</ProductNameStyled>
        </ProductNameWrapperStyled>
        <ToggleProductCTAWrapperStyled withinInfo>
          <CartToggleProductCTA
            group={group}
            closeModal={closeModal}
            allClose={allClose}
            quoteDeleteMsg={quoteDeleteMsg}
            setQuoteDeleteMsg={setQuoteDeleteMsg}
            showQuoteDeleteMsg={showQuoteDeleteMsg}
            setShowQuoteDeleteMsg={setShowQuoteDeleteMsg}
          />
        </ToggleProductCTAWrapperStyled>
      </ProductInfoWrapperStyled>
      <ProductDataWrapperStyled>
        {journeyType === "health" ? (
          <>
            <ProductDataStyled>
              <ProductDataLabelStyled>Plan Type</ProductDataLabelStyled>
              <ProductDataValueStyled>
                {planTypeDecider(groupCode)}
              </ProductDataValueStyled>
            </ProductDataStyled>
            <SeparatorStyled />
          </>
        ) : (
          <></>
        )}
        {deductible ? (
          <>
            <ProductDataStyled>
              <ProductDataLabelStyled>Deductible</ProductDataLabelStyled>
              <ProductDataValueStyled>
                ₹ {figureToWords(deductible)}
              </ProductDataValueStyled>
            </ProductDataStyled>
            <SeparatorStyled />
          </>
        ) : null}

        <ProductDataStyled>
          <ProductDataLabelStyled>Sum Insured</ProductDataLabelStyled>
          <ProductDataValueStyled>
            ₹ {figureToWords(sum_insured)}
          </ProductDataValueStyled>
        </ProductDataStyled>

        <SeparatorStyled />

        <ProductDataStyled>
          <ProductDataLabelStyled>Premium</ProductDataLabelStyled>
          <ProductDataValueStyled>
            ₹ {parseInt(premium).toLocaleString("en-IN")}
          </ProductDataValueStyled>
        </ProductDataStyled>

        <SeparatorStyled />

        <ProductDataStyled>
          <ProductDataLabelStyled>Tenure</ProductDataLabelStyled>
          <ProductDataValueStyled>
            {tenure >= 2 ? `${tenure + " Years"}` : `${tenure + " Year"}`}
          </ProductDataValueStyled>
        </ProductDataStyled>
      </ProductDataWrapperStyled>
      <ToggleProductCTAWrapperStyled>
        <CartToggleProductCTA
          group={group}
          closeModal={closeModal}
          allClose={allClose}
          quoteDeleteMsg={quoteDeleteMsg}
          setQuoteDeleteMsg={setQuoteDeleteMsg}
          showQuoteDeleteMsg={showQuoteDeleteMsg}
          setShowQuoteDeleteMsg={setShowQuoteDeleteMsg}
        />
      </ToggleProductCTAWrapperStyled>
    </ProductSummaryCardStyled>
  );
}

//   STYLED COMPONENTS
const ProductSummaryCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column nowrap;
  border: 1px solid ${SHADES.LIGHT_GRAY};
  border-radius: ${ROUNDED.lg};
  box-shadow: ${SHADOW.sm};
  margin: 12px 0;
  padding: 0;
  height: auto !important;
  overflow: hidden;
  ${VIEW.md} {
    flex-flow: nowrap;
    margin: 16px 0;
    padding: 14px;
    box-shadow: ${SHADOW.md};
  }
`;

const ProductInfoWrapperStyled = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  ${MAX.md} {
    width: 100%;
    padding: 12px;
  }
`;

const ICLogoWrapperStyled = styled.div`
  max-width: 50%;
`;

const ProductNameWrapperStyled = styled.div`
  margin-left: 16px;
  flex: 1;
`;

const ProductNameStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "md",
}))``;

const ProductDataWrapperStyled = styled.div`
  padding: 0 8px;
  margin: 0px 12px;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-left: 1.25px solid #dce2ec;
  border-right: 1.25px solid #dce2ec;
  ${MAX.md} {
    width: 100%;
    border: none;
    padding: 6px 3px;
    margin: 0px;
    background-color: rgb(240, 243, 247);
  }
`;

const ProductDataStyled = styled.div`
  display: flex;
  flex-direction: column;

  ${MAX.md} {
    flex-grow: 1;
    font-size: 11px;
    text-align: center;
  }
`;

const ProductDataLabelStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "semi",
}))`
  line-height: 24px;
`;

const ProductDataValueStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading2",
  weight: "md",
  colorType: "body",
}))`
  line-height: 24px;
`;

const SeparatorStyled = styled.div`
  margin: 0px 8px;
  width: 1.125px;
  height: 40px;
  background-color: #dce2ec;
  ${MAX.md} {
    background-color: transparent;
    margin: 0px 2x;
  }
`;

const ToggleProductCTAWrapperStyled = styled.div`
  display: ${props => {
    return props.withinInfo ? "none" : "block";
  }};
  ${MAX.md} {
    display: ${props => {
      return props.withinInfo ? "block" : "none";
    }};
  }
`;

export default CartProductSummaryCard;
