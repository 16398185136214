// CUSTOM HOOKS
import { useAdditionalDiscounts, useFeatureOptions } from "src/customHooks";
// UTILS
import {
  isRiderSelected,
  isAffectsOtherRiders,
} from "src/utils/productDetailsPageUtils";

export default function useGetRiderQueryOptions({ groupCode, riders }) {
  const { getSelectedFeatureOptions } = useFeatureOptions();
  const updatedFeatureOptions = getSelectedFeatureOptions(groupCode);

  const { getSelectedAdditionalDiscountsAlias } = useAdditionalDiscounts();

  const selected_additional_discounts =
    getSelectedAdditionalDiscountsAlias(groupCode);

  const affectsOtherRiders = riders
    ?.filter(isRiderSelected)
    ?.filter(isAffectsOtherRiders)
    ?.map(rider => rider.alias);

  let selectedRidersAlias = "";
  if (affectsOtherRiders?.length) {
    selectedRidersAlias = affectsOtherRiders;
  }

  let optionsSelected = {};

  //? Only consider the option-selected when rider is selected.
  riders?.filter(isRiderSelected)?.forEach(rider => {
    if (rider.options_selected) {
      optionsSelected = {
        ...optionsSelected,
        ...rider.options_selected,
      };
    }
  });

  const additionalUrlQueries = Object.keys(optionsSelected)
    ?.map(opt => `${opt}=${optionsSelected[opt]}`)
    ?.join("&");

  const ridersWithTruthyMembers = riders
    ?.filter(rider => rider.rider_members?.length > 0)
    ?.filter(rider => rider.rider_members.some(member => member.selected));

  const selectedRiderMemberUrlQueries = ridersWithTruthyMembers
    ?.map(({ alias, rider_members }) => {
      const selectedMembers = rider_members
        .filter(member => member.selected)
        .map(member => member.code)
        .join(",");
      return `${alias}_rider_members=${selectedMembers}`;
    })
    .join("&");

  const feature_options_query = Object.keys(updatedFeatureOptions)
    .map(key => `${key}=${updatedFeatureOptions[key]}`)
    ?.join("&");

  return {
    additionalUrlQueries,
    feature_options_query,
    selectedRiderMemberUrlQueries,
    selected_riders: selectedRidersAlias,
    selected_additional_discounts,
  };
}
