const acceptedEmailExtensions = {
  ".com": ".com",
  ".org": ".org",
  ".in": ".in",
  ".outlook": ".outlook",
  ".co.in": ".co.in",
  ".rediff": ".rediff",
  ".net": ".net",
  ".co": ".co",
  ".co.jp": ".co.jp",
  ".info": ".info",
  ".local": ".local",
  ".bike": ".bike",
  ".jll.com": ".jll.com",
};

const verifyDomain = emailString => {
  const lastIndex = emailString.lastIndexOf(".");
  const lengthOfString = emailString.length;
  const str = emailString.slice(lastIndex, lengthOfString);
  if (acceptedEmailExtensions[str]) {
    return true;
  }
  return false;
};

export function isEnquiryOptional(value, setting, isPosJourney) {
  return isPosJourney && setting?.b2b_enquiry_optionals?.includes(value);
}
const validateEmail = str => {
  var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (verifyDomain(str)) {
    return str.match(pattern);
  } else {
    return false;
  }
};

export function validateFullName({
  fullNameInput,
  setFullNameErrors = () => {},
}) {
  let isValidate = true;

  if (fullNameInput?.value?.trim().split(" ").length > 4) {
    setFullNameErrors({ message: "Please enter a valid Full Name." });
    isValidate = false;
  }

  if (fullNameInput?.value?.length === 0) {
    setFullNameErrors({ message: "Full Name is required." });
    isValidate = false;
  }

  return isValidate;
}

export function validateMobileInput({
  mobileInput,
  setMobileErrors = () => {},
}) {
  let isValidate = true;
  if (
    (mobileInput?.value?.length > 0 &&
      !/[6-9]{1}[0-9]{9}/.test(mobileInput.value)) ||
    /^\d*(\d)\1{9}\d*$/.test(mobileInput.value)
  ) {
    setMobileErrors({ message: "Please enter a valid Mobile no." });
    isValidate = false;
  }
  if (mobileInput?.value?.length === 0) {
    setMobileErrors({ message: "Mobile No. is required." });
    isValidate = false;
  }
  return isValidate;
}

export function validateEmailInput({ emailInput, setEmailErrors = () => {} }) {
  let isValidate = true;
  if (emailInput?.value?.length > 0 && !validateEmail(emailInput.value)) {
    setEmailErrors({ message: "Please enter a valid Email id." });
    isValidate = false;
  }
  if (emailInput?.value?.length === 0) {
    setEmailErrors({ message: "Email is required." });
    isValidate = false;
  }

  return isValidate;
}

export default function validateInput({
  settings,
  fullNameInput,
  emailInput,
  mobileInput,
  setFullNameErrors = () => {},
  setEmailErrors = () => {},
  setMobileErrors = () => {},
  isPosJourney,
}) {
  let isValidate = true;

  if (
    (!isEnquiryOptional("name", settings, isPosJourney) &&
      // fullNameInput?.value?.trim().split(" ").length < 2) ||
      fullNameInput?.value?.length > 0 &&
      fullNameInput?.value?.length < 3) ||
    (!isEnquiryOptional("name", settings, isPosJourney) &&
      /\s{2,}/.test(fullNameInput?.value))
  ) {
    setFullNameErrors({ message: "Please enter a valid Full Name." });
    isValidate = false;
  }
  if (
    !isEnquiryOptional("name", settings, isPosJourney) &&
    fullNameInput?.value?.length === 0
  ) {
    setFullNameErrors({ message: "Full Name is required." });
    isValidate = false;
  }

  //  ============ mobile validations ==================
  if (
    (mobileInput?.value?.length > 0 &&
      !/[6-9]{1}[0-9]{9}/.test(mobileInput.value)) ||
    /^\d*(\d)\1{9}\d*$/.test(mobileInput.value)
  ) {
    setMobileErrors({ message: "Please enter a valid Mobile no." });
    isValidate = false;
  }
  if (mobileInput?.value?.length === 0) {
    if (!isEnquiryOptional("mobile", settings, isPosJourney)) {
      setMobileErrors({ message: "Mobile No. is required." });
      isValidate = false;
    }
  }

  //  ==================== email validations ==================
  if (emailInput?.value?.length > 0 && !validateEmail(emailInput.value)) {
    setEmailErrors({ message: "Please enter a valid Email id." });
    isValidate = false;
  }
  if (
    !isEnquiryOptional("email", settings, isPosJourney) &&
    emailInput?.value?.length === 0
  ) {
    setEmailErrors({ message: "Email is required." });
    isValidate = false;
  }

  return isValidate;
}
