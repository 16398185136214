import styled from "styled-components/macro";
// DESIGN SYSTEM
// import { CircleLoader } from "src/components";
import FHTypography from "src/styles/Typography";
import {
  FLEX_COL_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import { VIEW } from "src/styles/responsiveStyle";

// Styled Components for RiderCard.jsx
const RiderCardStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: ${ROUNDED.lg};
  border: 1px solid ${SHADES.LIGHT_GRAY};
  min-height: 120px;
  align-self: start;
  max-height: fit-content;

  &[data-rider-card-loading="true"]::before {
    position: absolute;
    content: "";
    inset: 0;
    background-color: ${SHADES.WHITE};
    animation: card-loading 1.5s ease-in-out infinite;
    z-index: 2;
    @keyframes card-loading {
      0% {
        opacity: 0.5;
      }
      25% {
        opacity: 0.4;
      }
      50% {
        opacity: 0.2;
      }
      75% {
        opacity: 0.4;
      }
      100% {
        opacity: 0.5;
      }
    }
  }

  ${VIEW.md} {
    min-height: 160px;
  }
`;

const RiderDescriptionWrapperStyled = styled.div`
  max-width: 100%;
  flex: 1;
  &,
  h6 {
    display: inline;
    margin-right: 0.25rem;
    line-height: 1.1;
  }
`;

const RiderInfoAndActionWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  padding: 0.5rem;
  user-select: none;
  ${VIEW.md} {
    padding: 0.75rem;
  }
  .rider-name {
    width: 75%;
  }
`;

const RiderOptionsWrapperStyled = styled.div`
  ${FLEX_ROW}
  justify-content:flex-start;
  flex-flow: wrap;
  align-items: center;
  gap: 0.5rem;
`;

const RiderFlagStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading2",
  weight: "md",
  color: "#fff",
}))`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25rem 1.25rem;
  text-align: center;
  border-radius: 0 0 0 1.25rem;
  background: ${({ secondary_color }) => secondary_color};
`;

const RiderRecommendationStyled = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  right: -0.5rem;
  padding: 0.4rem 1rem;
  padding-right: 1.5rem;
  border-radius: 0 8px 0 8px;
  background: ${({ secondary_color }) => secondary_color};
  color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  ${VIEW.md} {
    right: -0.6rem;
  }
  ::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 0;
    border-style: solid;
    height: 0;
    border-width: 0.45rem 0.45rem 0 0;
    border-color: ${({ secondary_color }) => secondary_color} transparent
      transparent transparent;
    ${VIEW.md} {
      top: 100%;
      right: 0;
      border-width: 0.65rem 0.65rem 0 0;
    }
  }
`;
const PremiumButtonAndGstTextWrapperStyled = styled.div`
  ${FLEX_COL_CENTER}
  gap:0.5rem;
  .gst-text {
    border-bottom: 1px dashed #000;
  }
`;

const RiderActionWrapperStyled = styled.div`
  ${FLEX_ROW}
  gap: 0.5rem;
  flex-flow: row wrap;
  align-items: center;
`;

const RiderActionAndPremiumWrapperStyled = styled.div`
  ${FLEX_ROW}
  gap:1rem;
  .rider_actions {
    flex: 1;
  }
`;

const RiderPremiumButtonStyled = styled.button`
  height: 100%;
  ${FLEX_ROW_CENTER}
  gap: 0.5rem;
  padding: 0 0.7rem;
  border-top-left-radius: ${ROUNDED.lg};
  border-bottom-left-radius: ${ROUNDED.lg};
  background-color: ${SHADES.WHITE};
  cursor: pointer;
  width: fit-content;

  input {
    width: 1rem;
    height: 1rem;
    cursor: inherit;
  }

  svg {
    width: 1.125rem;
    height: 1.125rem;
    stroke-width: 0.25;
  }

  &[data-rider-selected="true"] {
    background-color: ${props => props.colors.primary_shade};
    svg {
      color: ${props => props.colors.primary_color};
    }
  }

  &[data-action-btn-click="true"] {
    opacity: 0.9;

    &::before {
      position: absolute;
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      border-radius: ${ROUNDED.full};
      background-color: ${props => props.colors.primary_color};
      z-index: -1;
      animation-name: ripple;
      animation-duration: 1000ms;
      animation-delay: 75ms;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    @keyframes ripple {
      from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
      }

      to {
        opacity: 0;
        transform: scale3d(2, 2, 1);
      }
    }
  }

  &[data-rider-disabled="true"] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

// Styled Components for index.jsx
const RiderCardWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  ${VIEW.md} {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const RiderPremiumValueWrapperStyled = styled.div`
  ${FLEX_ROW_CENTER}
  gap: 0.5rem;
  padding: 0.3rem;
  min-width: max-content;
  height: fit-content;
  border: 1px solid ${SHADES.LIGHT_GRAY};
  border-radius: ${ROUNDED.md};
  background-color: ${SHADES.WHITE};

  &[data-rider-disabled="true"] {
    opacity: 0.6;
  }

  &[data-rider-selected="true"] {
    background-color: ${props => props.colors.primary_shade};
    border-color: ${props => props.colors.primary_color};
    svg {
      color: ${props => props.colors.primary_color};
    }
  }
`;

const RiderPremiumValue = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "md",
  colorType: "heading",
}))``;
const RiderTax = styled(FHTypography).attrs(() => ({
  variant: "body2",
  weight: "md",
  colorType: "body",
}))`
  text-decoration: underline dotted #6b737f;
`;

export {
  RiderCardStyled,
  RiderDescriptionWrapperStyled,
  RiderInfoAndActionWrapperStyled,
  RiderOptionsWrapperStyled,
  RiderFlagStyled,
  PremiumButtonAndGstTextWrapperStyled,
  RiderPremiumButtonStyled,
  RiderCardWrapperStyled,
  RiderActionWrapperStyled,
  RiderPremiumValueWrapperStyled,
  RiderActionAndPremiumWrapperStyled,
  RiderPremiumValue,
  RiderTax,
  RiderRecommendationStyled,
};
