import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetEnquiriesQuery, useGetFrontendBootQuery } from "src/api/api";
import { useUrlQueries } from "./useUrlQuery";
import { brokerTenant } from "src/utils/tenantSettings";

// custom hook to get enquiry data from api or cache
// it contains all the data related to enquiry plus
// current group object (according to url it give you current group object)
// and group code from URL
function useGetEnquiry() {
  const { enquiryId } = useUrlQueries();
  const { groupCode } = useParams();
  const response = useGetEnquiriesQuery(undefined, {
    skip: !enquiryId,
  });

  // EFFECT/s
  useEffect(() => {
    const { params } = response?.data?.data?.input || {};
    if (params?.pos_code && brokerTenant("renew_buy")) {
      localStorage.setItem(
        "SSO_user",
        JSON.stringify({
          first_name: params?.first_name || params?.pos_name,
          pos_code: params?.pos_code,
          username: params.username,
          token: "",
          is_Gi_certified: params?.is_Gi_certified,
          is_pos_from_enquiry: params?.pos_name && true,
        }),
      );
      window.dispatchEvent(new Event("storage"));
    }
    return () => {};
  }, [response]);

  const groups = response?.data?.data?.groups || [];
  const input = response?.data?.data?.input || {};
  const enquiryData = response?.data?.data || {};
  const cartGroups =
    response?.data?.data.cart_items?.map(item => item?.group?.id) || [];
  const currentGroupObject = groups?.find(
    group => group?.id === parseInt(groupCode),
  );

  return {
    ...response,
    currentGroupObject,
    groupCode,
    input,
    groups,
    enquiryData,
    cartGroups,
  };
}

// custom hook to get Frontend-boot data from api or cache
// it contains all the data related to Frontend-boot
const subJourneyTypeOptions = {
  renew: "renewal",
  port: "port",
  new: "new",
};
function useFrontendBoot() {
  const { data, ...query } = useGetFrontendBootQuery();

  const { data: enquiryData } = useGetEnquiry();

  // VARIABLE/s
  const tenantName = data?.tenant?.name;
  const tenantAlias = data?.tenant?.alias;

  const policy_type_decider = {
    new: "new_business",
    renewal: "renewal_policy",
    port: "portability",
  };

  let journeyType = enquiryData?.data?.section
    ? enquiryData?.data?.section
    : "health";

  let subJourneyType = "";
  let policyType = "";
  if (enquiryData?.data) {
    journeyType = enquiryData?.data?.section;
    subJourneyType = subJourneyTypeOptions[enquiryData?.data?.type];
    policyType =
      policy_type_decider[subJourneyTypeOptions[enquiryData?.data?.type]];
  }
  if (
    data?.tenant?.health_port_frontend_domain === window?.location?.hostname
  ) {
    subJourneyType = "port";
  }
  return {
    enquiryData,
    query,
    journeyType,
    tenantName,
    subJourneyType,
    policyType,
    tenantAlias,
    data,
    tenant: data?.tenant,
    settings: data?.settings,
    insuredMembers: enquiryData?.data?.input?.members,
    groups: enquiryData?.data?.groups,
    renewal_policy_status: enquiryData?.data?.renewal_policy,
    costeffectivecalculation: data?.costeffectivecalculation,
  };
}

export { useFrontendBoot, useGetEnquiry };
