/*

#Title:- InputPage/HeaderCard

#Description:- Renders header information and available plans

#Components Used:- 
- PlanList:- Renders list of plans

#Used In:-
- InputPage.js
*/

import { memo } from "react";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { getJourneyText } from "../inputPage.utility";
import {
  ContentBanner,
  HeaderCardStyled,
  InputPlanCardStyled,
} from "./HeaderCard.styled";
import PlanList from "./PlanList";
import { isPortability } from "src/utils/checkJourney";

function HeaderCard({ content }) {
  const { colors } = useTheme();
  const { settings, tenantAlias } = useFrontendBoot();

  return (
    <HeaderCardStyled>
      <InputPlanCardStyled
        settings={settings}
        tenantAlias={tenantAlias}
        primaryColor={colors.primary_color}
        primaryShade={colors.primary_shade}
      >
        <FHTypography variant="h3" weight="md">
          {getJourneyText().title}
        </FHTypography>

        {content.banner && content.banner !== "" ? (
          <ContentBanner
            settings={settings}
            tenantAlias={tenantAlias}
            primaryColor={colors.primary_color}
            dangerouslySetInnerHTML={{
              __html: content.banner,
            }}
          />
        ) : (
          <>
            <FHTypography variant={isPortability()? "h1": "h1Big"} weight="md">
              {getJourneyText().subTitle}
            </FHTypography>
            <PlanList />
          </>
        )}
      </InputPlanCardStyled>
    </HeaderCardStyled>
  );
}

export default memo(HeaderCard);
