import { useParams } from "react-router-dom";
import styled from "styled-components";
import { MAX } from "src/styles/responsiveStyle";
// CUSTOM COMPONENTS
import { Riders } from "src/pages/ProductDetails/components/Sections/RiderSection";
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";

export default function AddOnCoverages({ quote }) {
  let { groupCode } = useParams();

  groupCode = groupCode ? parseInt(groupCode) : quote?.group?.id;
  const isOnProposalOrLaterRoute = matchCurrentRoute({
    matcher: "gte",
    route: "proposal",
  });
  return (
    <AddonCoverageWraper>
      <Riders
        groupCode={groupCode}
        quote={quote}
        showRiderActions={
          isOnProposalOrLaterRoute ||
          matchCurrentRoute({
            matcher: "lte",
            route: "productdetails",
          })
        }
      />
    </AddonCoverageWraper>
  );
}

const AddonCoverageWraper = styled.div`
  ${MAX.lg} {
    margin-bottom: 9rem;
  }
`;
