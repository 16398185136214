import { Fragment, useState } from "react";
// REACT ICONS
import { HiChevronDown } from "react-icons/hi";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// STYLED COMPONENTS
import {
  MobileTabCardStyled,
  MobileTabContentWrapperStyled,
  MobileTabToggleButtonStyled,
  PlanItemDescription,
  PlanItemTitle,
  PlanTabMobileWrapperStyled,
} from "./PlanDetails.style";
// CUSTOM COMPONENTS
import FeatureDefinitionMobile from "./FeatureDefinitionMobile";
import styled from "styled-components";

export default function PlanTabMobile({ data, item }) {
  const showAsBulletList = ["permanent_exclusions", "major_exclusions"];

  const [toggle, setToggle] = useState(false);
  const { colors } = useTheme();

  const handleTabToggle = () => setToggle(!toggle);

  return (
    <PlanTabMobileWrapperStyled
      data-tab-open={toggle}
      activeBorderColor={colors.primary_color}
    >
      <div>
        <MobileTabCardStyled>
          <div className="info-wrapper">
            <PlanItemTitle>{item.title}</PlanItemTitle>
            <PlanItemDescription onClick={handleTabToggle}>
              {item.description}
            </PlanItemDescription>
          </div>
          <MobileTabToggleButtonStyled
            data-tab-open={toggle}
            onClick={handleTabToggle}
          >
            <HiChevronDown />
          </MobileTabToggleButtonStyled>
        </MobileTabCardStyled>
        <MobileTabContentWrapperStyled data-tab-open={toggle}>
          <div>
            {data?.map((item, i) => {
              if (+item.is_visible === 0) return <></>;
              return (
                <Fragment key={i}>
                  {showAsBulletList.includes(item.code) && (
                    <ul>
                      {item?.value
                        ?.split("\n")
                        .filter(data => data)
                        .map(itemdata => {
                          let bulletIndex = itemdata.indexOf("•");
                          let newItem = "";
                          if (bulletIndex > -1) {
                            newItem = itemdata.substring(bulletIndex + 1);
                          } else {
                            newItem = itemdata;
                          }
                          return <List key={itemdata}>{newItem}</List>;
                        })}
                    </ul>
                  )}
                  {!["co_payment", ...showAsBulletList].includes(
                    item?.code,
                  ) && (
                    <FeatureDefinitionMobile
                      description={item.description}
                      icon={item.icon}
                      header={item.header}
                      value={item.value}
                      short_description={item.short_description}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        </MobileTabContentWrapperStyled>
      </div>
    </PlanTabMobileWrapperStyled>
  );
}

const List = styled.li`
  font-size: 11px;
  border-radius: 3px;
  padding: 3px;
  line-height: 1.6;
  list-style-type: disc;
  &::marker {
    font-size: 14px;
  }
  &:hover {
    background-color: #de9b9e29;
  }
`;
