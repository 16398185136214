import { FaBookmark, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { api } from "src/api/api";
import FloatButton from "src/components/Common/Button/FloatButton";
import { useTheme } from "src/customHooks";
import { useUrlQueries } from "src/customHooks/useUrlQuery";
import useShortlistedPlans from "src/pages/ShortlistedPage/hooks/useShortlistedPlans";
import { useResponsiveCondition } from "src/pos/hooks";
import { FLEX_ROW_CENTER, SHADES } from "src/styles/styleGuide";
import styled from "styled-components";

export function ShortlistFloat() {
  const isQuoteRoute = useRouteMatch({ path: "/quotes" });
  const { groupCode } = useParams();
  const history = useHistory();

  const { width, breakpoint } = useResponsiveCondition(780);
  const { colors } = useTheme();
  const { enquiryId } = useUrlQueries();
  const { getPlanByGroup, replaceShortlistedPlans } = useShortlistedPlans();
  const dispatch = useDispatch();
  const shortlistedQuotes = getPlanByGroup(groupCode);
  const clearHandler = () => {
    replaceShortlistedPlans();
  };

  if (width > breakpoint || !isQuoteRoute || !shortlistedQuotes.length)
    return <></>;
  return (
    <ShortlistFloatElementStyled>
    <FloatButton>
      {shortlistedQuotes.length ? (
        <CloseButtonStyled onClick={clearHandler} colors={colors}>
          <FaTimes />
        </CloseButtonStyled>
      ) : (
        <></>
      )}

      <ShortListLengthStyled
        colors={colors}
        onClick={() => {
          dispatch(api.util.invalidateTags(["shortlist"]));
          history.push(`/shortlisted/${groupCode}?enquiryId=${enquiryId}`);
        }}
      >
        <FaBookmark size={16} />
        <span>{shortlistedQuotes.length || <></>}</span>
      </ShortListLengthStyled>
    </FloatButton>
    </ShortlistFloatElementStyled>
  );
}

export default ShortlistFloat;

const CloseButtonStyled = styled.button`
  position: absolute;
  font-size: 0.8rem;
  top: -8px;
  right: -5px;
  padding: 1px;
  color: ${props => props.colors.primary_color};
  border-radius: 50%;
  border: 2px solid ${props => props.colors.primary_color};
  background-color: #fff;
  ${FLEX_ROW_CENTER};
`;

const ShortListLengthStyled = styled.span`
  color: ${SHADES.WHITE};
  border-radius: 50%;
  position: relative;
  ${FLEX_ROW_CENTER};
  font-size: 14px;
  font-weight: 600;

  span {
    font-size: 0.8rem;
    font-weight: 600;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -55%);
    color: ${props => props.colors.primary_color};
  }
`;

const ShortlistFloatElementStyled = styled.span`
position: absolute;
bottom: 50px;
`
