import { createSlice } from "@reduxjs/toolkit";

const customPlanSlice = createSlice({
  initialState: {
    customPlans: {},
    isCustomPlanTourDone: sessionStorage.getItem("customPlanTourDone"),
  },
  name: "customPlan",
  reducers: {
    setCustomPlans: (state, action) => {
      state.customPlans = { ...state.customPlans, ...action.payload };
    },
    setIsCustomPlanTourDone: (state, action) => {
      state.isCustomPlanTourDone = action.payload;
    },
  },
});

export const { setCustomPlans, setIsCustomPlanTourDone } =
  customPlanSlice.actions;

export default customPlanSlice.reducer;
