import { some } from "lodash";
// CUSTOM HOOKS
import {
  useGetClaimProcessQuery,
  useGetProductBrochureQuery,
} from "src/api/api";
import { useClaimBanner } from "src/customHooks";
// STYLED COMPONENTS
import { ClaimProcessContainerStyled } from "./ClaimProcess.styled";
// CUSTOM COMPONENTS
import ClaimFilingInfo from "./ClaimFilingInfo";
import ClaimContactInfo from "./ClaimContactInfo";
import CardSkeletonLoader from "src/components/Common/card-skeleton-loader/CardSkeletonLoader";

export default function ClaimProcess({ quote }) {
  const {
    product: { company, id },
  } = quote;

  const claimProcessQuery = useGetClaimProcessQuery(company.id);

  const productBrochureQuery = useGetProductBrochureQuery(id);

  const isLoading = some([
    claimProcessQuery.isLoading,
    claimProcessQuery.isUninitialized,
    productBrochureQuery.isLoading,
    productBrochureQuery.isUninitialized,
  ]);

  const { claimBannerArray, shouldShowClaimBanner } = useClaimBanner();

  if (isLoading) return <CardSkeletonLoader />;

  const claimProcess = claimProcessQuery?.data;
  const claimForm = (productBrochureQuery?.data || [])[0];

  const claimFilingInfo = Object.keys(claimFilingSteps).map(info => ({
    title: claimFilingSteps[info],
    data: claimProcess[info],
  }));

  return (
    <ClaimProcessContainerStyled>
      <ClaimContactInfo claimProcess={claimProcess} claimForm={claimForm} />
      <ClaimFilingInfo
        claimFilingData={
          shouldShowClaimBanner ? claimBannerArray : claimFilingInfo
        }
      />
    </ClaimProcessContainerStyled>
  );
}

const claimFilingSteps = {
  cashless_claim: "Cashless Claim",
  document_required: "Documents Required",
  reimbursement_claim: "Reimbursement Claim",
};
