import styled from "styled-components";

export const UnrecoverableErrorStyled = styled.div`
  max-width: 750px;
  width: 100%;
  max-height: 450px;
  height: 100%;
  background-color: white;
  border-top: 4px solid #ad1010;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px 2px #ccc;
  position: relative;
  @media (max-width: 720px) {
    max-width: 350px;
    max-height: 300px;
  }

  .errorHead {
    h1 {
      font-size: 1.8rem;
      font-weight: 600;
      margin-top: 5px;

      @media (max-width: 720px) {
        font-size: 1.1rem;
      }
    }

    p {
      font-size: 1.1rem;
      color: #ad1010;
      @media (max-width: 720px) {
        font-size: 0.8rem;
      }
    }
  }

  .errorBody {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 1.3rem;
      font-weight: 600;
      @media (max-width: 720px) {
        margin-top: 5px;
        font-size: 1rem;
      }
    }

    img {
      width: 240px;
      @media (max-width: 720px) {
        width: 120px;
      }
    }
  }

  .errorActions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    button {
      padding: 5px 25px;
      border: 2px solid #ad1010;
      color: #ad1010;
      border-radius: 10px;
      font-size: 1rem;
      cursor: pointer;
      margin: 0 10px;

      @media (max-width: 720px) {
        font-size: 0.8rem;
        padding: 3px 15px;
      }
    }
  }
`;

export const OuterDivStyled = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
