/*
    Title: useSentry.js
    Description: This hook used to initialize sentry tool for the application.
    Used In: src/index.js
*/

import { brokerAppPhase } from "src/utils/tenantSettings";
import * as Sentry from "@sentry/react";

export default function initializeSentry() {
  const config = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["SyntaxError", "ChunkLoadError"],
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
  };
  if (
    process.env.REACT_APP_SENTRY_DSN &&
    brokerAppPhase("PROD") &&
    process.env.NODE_ENV === "production"
  ) {
    Sentry.init(config);
  }
}
