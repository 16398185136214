// REACT ICONS
import { RiDownload2Line } from "react-icons/ri";
// CUSTOM HOOKS
import { useTheme } from "src/customHooks";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  DownloadCardContainerStyled,
  DownloadTabStyled,
  DownloadTabWrapperStyled,
} from "./PlanDetails.style";

export default function DownloadCard({ brochureUrl, policyWordingUrl }) {
  return (
    <DownloadCardContainerStyled>
      <FHTypography variant="h5" weight="semi" colorType="heading">
        Downloads
      </FHTypography>
      <FHTypography variant="subheading1" weight="base" color="body">
        Please refer to the Product Brochure or Policy Wording for a better
        understanding for the product coverages and exclusions.
      </FHTypography>
      <DownloadTabWrapperStyled>
        {brochureUrl && (
          <DownloadTab url={brochureUrl} title="Product Brochure" />
        )}
        {policyWordingUrl && (
          <DownloadTab url={policyWordingUrl} title="Policy Wording" />
        )}
      </DownloadTabWrapperStyled>
    </DownloadCardContainerStyled>
  );
}

function DownloadTab({ url, title }) {
  const { colors } = useTheme();
  return (
    <DownloadTabStyled colors={colors}>
      <FHTypography variant="h6" weight="md" colorType="heading">
        {title}
      </FHTypography>
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <RiDownload2Line />
      </a>
    </DownloadTabStyled>
  );
}
