import { useTheme } from "src/customHooks";
import {
  CashlessEverywhereWrapper,
  CashlessViewDetailsWrapper,
} from "./CashlessHospitals.style";
import FHTypography from "src/styles/Typography";
import { useState } from "react";
import styled from "styled-components";
import { FLEX_ROW_CENTER, ROUNDED, SHADES } from "src/styles/styleGuide";
import Modal from "src/components/Common/Popups/ReusableModal/Modal";
import { IoMegaphoneSharp } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import { MAX, VIEW } from "src/styles/responsiveStyle";

export default function CashlessEverywhere({ isModalavailable = false }) {
  const [viewDetails, setViewDetials] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [showCashlessEverywhere, setShowCashlessEverywhere] = useState(true);
  const { colors } = useTheme();

  const handleBackIcon = () => {
    setShowCashlessEverywhere(false);
  };

  const handleShowViewDetials = () => {
    isModalavailable
      ? setViewDetailsModal(!viewDetailsModal)
      : setViewDetials(!viewDetails);
  };

  return (
    <>
      {showCashlessEverywhere && (
        <CashlessEverywhereWrapper
          colors={colors}
          data-show-as-modal={isModalavailable}
        >
          <div className="sectionwrapper">
            <div className="announceicon">
              <IoMegaphoneSharp />
            </div>
            <FHTypography
              variant="subheading1"
              weight="semi"
              colorType="subHeading"
            >
              Cashless Everywhere Claims Available now in Non- Networked and
              Networked Hospitals.{" "}
              <button className="link" onClick={handleShowViewDetials}>
                {viewDetails ? "Hide Details" : "View Details"}
              </button>
            </FHTypography>
            <BackIconStyled onClick={handleBackIcon}>
              <FaTimes size={14} />
            </BackIconStyled>
          </div>
          {viewDetails && (
            <CashlessEveryWhereContent isModalavailable={isModalavailable} />
          )}
        </CashlessEverywhereWrapper>
      )}
      {viewDetailsModal && (
        <CashlessVeiwDetailsModal
          isModalavailable={isModalavailable}
          onClick={handleShowViewDetials}
        />
      )}
    </>
  );
}

function CashlessEveryWhereContent({ isModalavailable = false }) {
  const { colors } = useTheme();

  return (
    <>
      <CashlessViewDetailsWrapper
        colors={colors}
        data-show-as-modal={isModalavailable}
      >
        <FHTypography variant="h6" weight="semi" className="heading">
          Cashless Everywhere Claims Available now in Non- Networked and
          Networked Hospitals.{" "}
        </FHTypography>

        <FHTypography
          variant="subheading2"
          colorType="subHeading"
          weight="md"
          className="subheading"
        >
          Now you claim a settlement and get treatment even if the hospital
          doesn’t come under the list of Networked Hospitals with your insurer
        </FHTypography>

        <div className="steps">
          <FHTypography
            variant="h6"
            weight="semi"
            colorType="subHeading"
            className="question"
          >
            How does it work ?
          </FHTypography>
          <ul>
            <li>
              <FHTypography
                variant="subheading2"
                colorType="subHeading"
                weight="md"
              >
                For an emergency or scheduled hospital stay, admission is
                possible to any desired hospital.
              </FHTypography>
            </li>
            <li>
              <FHTypography
                variant="subheading2"
                colorType="subHeading"
                weight="md"
              >
                Let your insurer settle the claims with any hospitals you
                choose.
              </FHTypography>
            </li>
          </ul>
        </div>
        <div className="steps">
          <FHTypography
            variant="h6"
            weight="semi"
            colorType="subHeading"
            className="question"
          >
            Ways to claim :
          </FHTypography>
          <ul>
            <li>
              <FHTypography
                variant="subheading2"
                colorType="subHeading"
                weight="md"
              >
                There is now an option for the customer, that they can initiate
                the claiming procedure with the insurance company at least 48
                hours before the getting admitted
              </FHTypography>
            </li>
            <li>
              <FHTypography
                variant="subheading2"
                colorType="subHeading"
                weight="md"
              >
                Customer needs to inform the insurance company 48 hours before,
                in the case of emergency Treatment
              </FHTypography>
            </li>
          </ul>
        </div>
        <div className="steps">
          <FHTypography
            variant="h6"
            weight="semi"
            colorType="subHeading"
            className="question"
          >
            Kindly Note :
          </FHTypography>
          <ul>
            <li>
              <FHTypography
                variant="subheading2"
                colorType="subHeading"
                weight="md"
              >
                The claims should settle as per the terms of the policy and
                cashless facility should be available as pr the operating of the
                insurance company
              </FHTypography>
            </li>
            <li>
              <FHTypography
                variant="subheading2"
                colorType="subHeading"
                weight="md"
              >
                Standard T&C apply - Facility available is subject to hospital
                acceptance. Not available in hospitals that have been
                blacklisted by perticular insurer.
              </FHTypography>
            </li>
          </ul>
        </div>

        <FHTypography
          variant="h6"
          weight="semi"
          colorType="subHeading"
          className="question"
        >
          For more info, click on :{" "}
          <a
            href="https://www.gicouncil.in/news-media/events/press-release-launch-of-cashless-everywhere/"
            className="anchor"
            rel="noreferrer"
            target="_blank"
          >
            https://www.gicouncil.in/news-media/events/press-release-launch-of-cashless-everywhere/
          </a>{" "}
        </FHTypography>
      </CashlessViewDetailsWrapper>
    </>
  );
}

function CashlessVeiwDetailsModal({ isModalavailable, onClick }) {
  const { colors } = useTheme();

  return (
    <Modal
      isModalOpen={true}
      height="fit-content"
      mobileHeight="fit-content"
      hideCloseBtn={true}
    >
      <CashlessEveryWhereContent isModalavailable={isModalavailable} />
      <ButtonWrapper>
        <OKButton colors={colors} onClick={onClick}>
          <FHTypography variant="h6">Okay Got it !</FHTypography>
        </OKButton>
      </ButtonWrapper>
    </Modal>
  );
}

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

const OKButton = styled.button`
  width: fit-content;
  padding: 0.5rem;
  border: 2px solid ${props => props.colors.primary_color};
  border-radius: ${ROUNDED.md};
  color: ${props => props.colors.primary_color};
  transition: 0.2s;

  :hover {
    background-color: ${props => props.colors.primary_color};
    color: #ffff;
  }
`;

const BackIconStyled = styled.div`
  position: absolute;
  top: -18px;
  right: -18px;
  height: 1.2rem;
  width: 1.2rem;
  ${FLEX_ROW_CENTER};
  font-size: 9px;
  color: ${props => props?.colors?.primary_color + "99"};
  background-color: #fff;
  border: 1px solid ${SHADES.DARK_GRAY};
  border-radius: ${ROUNDED.full};
  cursor: pointer;

  ${MAX.md} {
    top: -18px;
    right: -18px;
  }

  ${VIEW.md} {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1rem;
  }
`;
