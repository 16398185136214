import {
  FooterContainer,
  BottomContainer,
  TopContainer,
} from "./footer-stylesheet";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { images } from "../../../assets/logos/logo";
import "styled-components/macro";
import { brokerAppPhase } from "src/utils/tenantSettings";
import styled from "styled-components";
import { FLEX_COL, FLEX_ROW } from "src/styles/styleGuide";
import { VIEW } from "src/styles/responsiveStyle";
// import UibFooter from "./UibFooter";

const Footer = () => {
  const { ...frontendBoot } = useFrontendBoot();

  const { alias } = frontendBoot?.data?.tenant;
  return (
    <>
      {alias === "sriyah" ? (
        <FooterContainer>
          <TopContainer>
            <CustomContainerStyled>
              <RowStyled>
                <div className="col_1">
                  <div className="f_widget about-widget pl_40">
                    <h3 className="f-title f_600 f_size_18 mb_40">
                      Insurances
                    </h3>
                    <ul className="list-unstyled f_list">
                      {" "}
                      <li>
                        <a
                          href={
                            brokerAppPhase("PROD")
                              ? "https://car.nammacover.com/car/lead-page"
                              : "https://uatmotor.nammacover.com/car/lead-page"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Car Insurance
                        </a>
                      </li>{" "}
                      <li>
                        <span>Health Insurance</span>
                      </li>{" "}
                      <li>
                        <span>Super Top Up Insurance</span>
                      </li>{" "}
                    </ul>{" "}
                  </div>
                </div>
                <div className="col_2">
                  {" "}
                  <div className="f_widget about-widget">
                    {" "}
                    <h3 className="f-title f_600 f_size_18 mb_40">
                      At a glance
                    </h3>{" "}
                    <ul className="list-unstyled f_list">
                      {" "}
                      <li>
                        <a
                          href={
                            brokerAppPhase("PROD")
                              ? "https://nammacover.com/about_us"
                              : "https://uat.nammacover.com/about_us"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          About Us
                        </a>
                      </li>{" "}
                      <li>
                        <a
                          rel="noreferrer"
                          href={
                            brokerAppPhase("PROD")
                              ? "https://nammacover.com/terms_of_use"
                              : "https://uat.nammacover.com/terms_of_use"
                          }
                          target="_blank"
                        >
                          Terms Of Use
                        </a>
                      </li>{" "}
                      <li>
                        <a
                          href={
                            brokerAppPhase("PROD")
                              ? "https://nammacover.com/grievance_policy"
                              : "https://uat.nammacover.com/grievance_policy"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Grievance policysss
                        </a>
                      </li>{" "}
                    </ul>{" "}
                  </div>{" "}
                </div>
                <div className="col_3">
                  {" "}
                  <div className="f_widget company_widget">
                    {" "}
                    <a
                      href={
                        brokerAppPhase("PROD")
                          ? "https://nammacover.com/"
                          : "https://uat.nammacover.com/"
                      }
                      className="f-logo"
                    >
                      <img
                        src={images["sriyah"]}
                        className="logo_ic"
                        alt=""
                        loading="lazy"
                      />
                    </a>
                    <div className="widget-wrap">
                      <p className="color_grey_f">
                        <p>
                          An initiative of Sriyah Insurance Brokers Pvt. Ltd.
                        </p>
                        <br />
                        <p>
                          Sriyah Insurance Brokers Pvt. Ltd. is an Insurance
                          Regulatory & Development Authority of India (IRDAI)
                          approved direct insurance broker.
                        </p>
                      </p>{" "}
                      <p className="f_400 f_p f_size_15 l_height34 color_grey_f">
                        <span>Email:</span>{" "}
                        <a
                          href={`mailto:care@nammacover.com`}
                          className="f_400"
                        >
                          care@nammacover.com
                        </a>
                      </p>{" "}
                      <p className="f_400 f_p f_size_15  l_height34 color_grey_f">
                        <span>Phone:</span>{" "}
                        <a href={`tel:18002030504`} className="f_400">
                          18002030504
                        </a>
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              </RowStyled>
            </CustomContainerStyled>
          </TopContainer>

          <BottomContainer>
            <div>
              <BottomRowStyled>
                <div>
                  <p className=" f_400 color_grey">
                    IRDAI Registration No. 203 | License valid till : 26/08/2027
                    | Category : Direct Broker | CIN : U66010KA2003PTC031462{" "}
                    <br />
                    Member of Insurance Brokers Association of India (IBAI).
                    Insurance is the subject matter of solicitation. <br />
                    For a seamless experience, use the latest version of Chrome,
                    Firefox or Edge <br />
                    <br /> Copyright 2022 Sriyah Insurance Brokers Pvt. Ltd.
                  </p>
                </div>
              </BottomRowStyled>
            </div>
          </BottomContainer>
        </FooterContainer>
      ) : (
        <></>
      )}
      {/* {alias === "uib" && <UibFooter />} */}
    </>
  );
};

export default Footer;

const CustomContainerStyled = styled.div``;

const RowStyled = styled.div`
  ${FLEX_COL}

  ${VIEW.md} {
    ${FLEX_ROW}
  }
  .col_1 {
    ${VIEW.md} {
      width: 25%;
    }
    ${VIEW.xs} {
      padding-left: 2rem;
    }
    ${VIEW.sm} {
      padding-left: 5rem;
    }
  }
  .col_2 {
    ${VIEW.md} {
      width: 25%;
    }

    ${VIEW.xs} {
      padding-left: 2rem;
    }
    ${VIEW.sm} {
      padding-left: 5rem;
    }
  }
  .col_3 {
    ${VIEW.md} {
      width: 50%;
    }
    ${VIEW.xs} {
      padding-left: 2rem;
    }
    ${VIEW.sm} {
      padding-left: 5rem;
    }
  }
  .logo_ic {
    width: 220px !important;
  }
`;

const BottomRowStyled = styled.div`
  text-align: center;
`;
