import styled from "styled-components";
import FHTypography from "src/styles/Typography";
import { MAX, VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_ROW,
  ROUNDED,
  FLEX_COL,
  FLEX_ROW_CENTER,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";

const ClaimProcessContainerStyled = styled.div`
  ${FLEX_ROW}
  flex-direction: column-reverse;
  gap: 1rem;
  ${VIEW.md} {
    gap: 1.5rem;
    flex-direction: row;
  }
`;

const ClaimContactInfoWrapperStyled = styled.div`
  ${FLEX_COL}
  gap:1rem;
  ${VIEW.lg} {
    max-width: 400px;
  }
`;

const ContactInfoHeadingStyled = styled(FHTypography).attrs(() => ({
  variant: "h5",
  weight: "semi",
  colorType: "heading",
}))`
  background-color: ${props => props.colors.secondary_shade};
  border-radius: ${ROUNDED.md};
  margin-bottom: 0.5rem;
  padding: 1rem;
`;

const ContactInfoWrapperStyled = styled.div`
  ${FLEX_COL}
  padding:0 0.5rem;
  gap: 1rem;
`;

const InsuranceContactType = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "md",
}))`
  padding: 0.75rem;
  background-color: ${props => props.colors.primary_color};
  color: #fff;
  border-radius: ${ROUNDED.md};
`;

const ContactCardStyled = styled.div`
  ${FLEX_ROW_CENTER}
  border:1px solid rgb(201, 201, 201);
  border-radius: ${ROUNDED.lg};
  padding: 0.75rem;
  svg {
    color: ${props => props.colors.primary_color};
    font-size: 1.75rem;
  }
`;

const ContactCardHeaderWrapperStyled = styled.div`
  ${FLEX_COL}
  flex:1;
  gap: 0.5rem;
`;

const ContactCardHeader = styled(FHTypography).attrs(() => ({
  variant: "h5",
  weight: "semi",
  colorType: "heading",
}))``;

const ContactCardValue = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "base",
  colorType: "subHeading",
}))``;

const ClaimFormButtonStyled = styled.a`
  ${FLEX_ROW}
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding: 1rem;
  background-color: ${props => props.colors.secondary_shade};
  border-radius: ${ROUNDED.md};
  svg {
    color: ${props => props.colors.primary_color};
    font-size: 1.75rem;
  }
`;

const ClaimFilingInfoContainerStyled = styled.div`
  ${FLEX_COL}
  flex:1;
  gap: 2rem;
`;

const ClaimFileButtonWrapperStyled = styled.div`
${FLEX_ROW}
  ${MAX.lg} {
    ${FLEX_ROW}
    margin: 0 auto;
    max-width: 380px;
    border: 1px solid ${SHADES.WHITE};
    box-shadow: ${SHADOW.md};
  }
  ${VIEW.lg} {
    max-width: auto;
  }
`;

const ClaimFileButtonStyled = styled.button`
  ${MAX.lg} {
    padding: 0.5rem;
    color: #000;
    margin-right: 12px;
    position: relative;
    &[data-tab-active="true"] {
      background-color: ${props => props.colors.primary_color};
      color: #fff;
    }
  }

  ${VIEW.lg} {
    position: relative;
    padding: 0.75rem 1.25rem;
    border-radius: ${ROUNDED.full};

    &[data-tab-active="true"] {
      background-color: ${props => props.colors.primary_color};
      color: #fff;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 15px solid ${props => props.colors.primary_color};
      }
    }
  }
`;

const ClaimFilingContentWrapperStyled = styled.div``;

const ClaimFilingContentParagraph = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "base",
  colorType: "heading",
}))`
  & u {
    color: black;
    line-height: 3;
  }

  & a {
    color: blue;
  }

  p {
    margin-bottom: 0.75rem;
  }

  * {
    font-family: inherit !important;
    font-size: inherit !important;
    &:empty {
      display: none;
    }
  }
`;

export {
  ClaimContactInfoWrapperStyled,
  ClaimFormButtonStyled,
  ClaimProcessContainerStyled,
  ContactCardHeader,
  ContactCardHeaderWrapperStyled,
  ContactCardStyled,
  ContactCardValue,
  ContactInfoHeadingStyled,
  ContactInfoWrapperStyled,
  InsuranceContactType,
  ClaimFilingInfoContainerStyled,
  ClaimFileButtonStyled,
  ClaimFileButtonWrapperStyled,
  ClaimFilingContentWrapperStyled,
  ClaimFilingContentParagraph,
};
