import { useState } from "react";
import Modal from "../components/Common/Popups/ReusableModal/Modal";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import styled from "styled-components";
import {
  useEmailInput,
  useNameInput,
  useNumberInput,
  useTheme,
} from "../customHooks/index";
import useIsPos from "../pos/useIsPos";
import { HeadingPrimary, SecondaryFont } from "../styles/Types";
import validateInput from "../utils/inputPageUtils";
import { mobile } from "../utils/mediaQueries";
import { Button } from "./Common/Button/ContinueButton";
import { handleCommunicationRequest } from "./ShareModule/shareModule.api";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import TextInput2 from "./TextInput2";

const TalkToUsContent = ({ setSuccess }) => {
  const { colors } = useTheme();
  const { isPosJourney } = useIsPos();
  const { settings, enquiryData } = useFrontendBoot();

  let inputData = {
    name: enquiryData?.data?.name,
    email: enquiryData?.data?.email,
    mobile: enquiryData?.data?.mobile,
  };
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailErrors] = useState({});
  const [mobileError, setMobileErrors] = useState({});
  const [fullNameError, setFullNameErrors] = useState({});
  const fullNameInput = useNameInput(inputData?.name || "", setFullNameErrors);
  const mobileInput = useNumberInput(inputData?.mobile || "", setMobileErrors, {
    maxLength: 10,
  });
  const emailInput = useEmailInput(inputData?.email || "", setEmailErrors);

  const onCallHandler = async e => {
    e.preventDefault();
    const validation = validateInput({
      settings,
      fullNameInput,
      emailInput,
      mobileInput,
      setEmailErrors,
      setFullNameErrors,
      setMobileErrors,
    });

    if (validation) {
      setLoader(true);
      const response = await handleCommunicationRequest({
        email: emailInput?.value,
        mobile: mobileInput?.value,
        name: fullNameInput?.value,
        mode: ["EMAIL"],
        stage: "TALK_TO_US",
      });
      setLoader(false);
      setSuccess(`${response.statusCode}`.startsWith("2"));
    }
  };

  const notDisplayValue = settings?.contact_us_not_display_contact_form;

  const trimString = string => {
    let start = string?.indexOf("{");
    let end = string?.indexOf("}");
    let sentence = string?.slice(0, start);
    let contactValue = string?.slice(start + 2, end);
    return {
      sentence,
      contactValue,
    };
  };

  return (
    <Wrapper colors={colors}>
      <Heading>
        {isPosJourney
          ? settings?.contact_us_title_pos
          : settings?.contact_us_title}
      </Heading>
      <Subtitle>
        {isPosJourney
          ? settings?.contact_us_subtitle_pos
          : settings?.contact_us_subtitle}
      </Subtitle>
      {notDisplayValue?.includes("lead_form") ? (
        <></>
      ) : (
        <FormWrapper
          onSubmit={onCallHandler}
          fullNameError={fullNameError}
          mobileError={mobileError}
          emailError={emailError}
        >
          <div>
            <TextInput2
              name="name"
              label="Name"
              placeholder="Name"
              type="text"
              maxLength={60}
              className="form_name"
              {...fullNameInput}
            />
            {fullNameError?.message && (
              <ErrorMessage>{fullNameError.message}</ErrorMessage>
            )}
          </div>

          <div>
            <TextInput2
              name="mobile"
              label="Phone number"
              placeholder="Phone number"
              type="number"
              minLength={10}
              className="form_mobile"
              {...mobileInput}
            />

            {mobileError?.message && (
              <ErrorMessage>{mobileError?.message}</ErrorMessage>
            )}
          </div>

          <div>
            <TextInput2
              name="email"
              label="Email"
              placeholder="Email"
              className="form_email"
              type="email"
              {...emailInput}
            />
            {emailError?.message && (
              <ErrorMessage>{emailError?.message}</ErrorMessage>
            )}
          </div>
          <Button type="submit" className="form_btn" loader={loader}>
            CONTACT ME
          </Button>
        </FormWrapper>
      )}
      {notDisplayValue?.includes("contact") ? (
        <></>
      ) : (
        <>
          {settings?.contact_us_not_display_contact_form?.includes(
            "lead_form",
          ) ? (
            <></>
          ) : (
            <>{settings?.contact_us_phone_label && <Divider />}</>
          )}
          <div>
            <Subtitle>
              {isPosJourney
                ? trimString(settings?.contact_us_phone_label_pos)?.sentence
                : trimString(settings?.contact_us_phone_label)?.sentence}
              <a
                className="anchor"
                href={`tel:${
                  isPosJourney
                    ? trimString(settings?.contact_us_phone_label_pos)
                        ?.contactValue
                    : trimString(settings?.contact_us_phone_label)?.contactValue
                }`}
              >
                {isPosJourney
                  ? trimString(settings?.contact_us_phone_label_pos)
                      ?.contactValue
                  : trimString(settings?.contact_us_phone_label)?.contactValue}
              </a>
            </Subtitle>
          </div>
        </>
      )}
      {settings?.contact_us_not_display_contact_form?.includes("email") ? (
        <></>
      ) : (
        <>
          {settings?.contact_us_not_display_contact_form?.includes(
            "lead_form",
          ) &&
          settings?.contact_us_not_display_contact_form?.includes("contact") ? (
            <></>
          ) : (
            <>{settings?.contact_us_email_label && <Divider />}</>
          )}
          <Title>
            {isPosJourney
              ? trimString(settings?.contact_us_email_label_pos)?.sentence
              : trimString(settings?.contact_us_email_label)?.sentence}
            <a
              className="anchor"
              href={`mailto:${
                isPosJourney
                  ? trimString(settings?.contact_us_email_label_pos)
                      ?.contactValue
                  : trimString(settings?.contact_us_email_label)?.contactValue
              }`}
            >
              {isPosJourney
                ? trimString(settings?.contact_us_email_label_pos)?.contactValue
                : trimString(settings?.contact_us_email_label)?.contactValue}
            </a>
          </Title>
        </>
      )}
    </Wrapper>
  );
};

const TalkToUsPopup = ({ show, onClose, setScrollOFF = () => {} }) => {
  const [success, setSuccess] = useState(false);

  return (
    <Modal
      isModalOpen={show}
      onClose={() => {
        setScrollOFF && setScrollOFF(false);
        onClose();
      }}
      width="fit-content"
      height="fit-content"
      mobileHeight="fit-content"
      className={`noselect`}
    >
      {!success ? (
        <>
          <TalkToUsWrapper>
            <TalkToUsContent setSuccess={setSuccess} />
          </TalkToUsWrapper>
        </>
      ) : (
        <Success
          title={"Details recieved"}
          subtitle={`We will get in touch with you super soon`}
          onClose={() => {
            setSuccess(false);
            onClose();
          }}
        />
      )}
    </Modal>
  );
};

const Divider = () => {
  return (
    <FlexWrapper>
      <Line />
      {"OR"}
      <Line />
    </FlexWrapper>
  );
};

export default TalkToUsPopup;

function Success({ title, subtitle, onClose }) {
  const { colors } = useTheme();

  return (
    <SuccessWrapper>
      <Banner color={colors.primary_color}>
        <IconWrapper>
          <IoIosCheckmarkCircleOutline
            size={120}
            color={colors.primary_color}
          />
        </IconWrapper>
      </Banner>
      <ContentWrapper>
        <HeadingPrimary>{title}</HeadingPrimary>
        <SecondaryFont className="font_color">{subtitle}</SecondaryFont>

        <Button className="success_btn" onClick={onClose}>
          OK
        </Button>
      </ContentWrapper>
    </SuccessWrapper>
  );
}

const Heading = styled.div`
  font-size: 24px;
  color: #666;
  text-transform: uppercase;
  margin-top: 0px !important;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
`;

const Title = styled.p`
  font-size: 14px;
  color: #666;
`;

const Wrapper = styled.div`
  display: grid;
  text-align: center;
  padding: 0 50px;
  min-width: 350px;
  & > * {
    margin: 7px 0;
  }

  & > p {
    margin: 0 !important;
  }

  ${mobile} {
    padding: 0 20px;
  }

  .talk-to-us-button {
    margin: 0 !important;
  }
  .anchor {
    color: ${({ colors }) => colors.primary_color};
  }
`;

const FormWrapper = styled.form`
  display: grid;
  grid-gap: 10px;

  div {
    width: 100%;
  }
  .form_name {
    text-transform: capitalize;
    border: ${({ fullNameError }) =>
      fullNameError?.message?.length > 0 && "1px solid red"};
  }
  .form_mobile {
    border: ${({ mobileError }) => mobileError?.message && "1px solid red"};
  }
  .form_email {
    border: ${({ emailError }) => emailError?.message && "1px solid red"};
  }
  .form_btn {
    height: 40px;
    width: 100px;
    margin: 0 auto;
  }
`;

const Line = styled.hr`
  width: 100px;
  background-color: #666;
  height: 1px;
  margin: 10px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  width: 100%;
  margin: 0;
  text-align: left;
`;

const Banner = styled.div`
  width: 100%;
  height: 40%;
  border-radius: 0.3rem 0.3rem 0 0;
  background: ${props => props.color};
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const IconWrapper = styled.span`
  width: 110px;
  height: 110px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateY(50%);
`;

const ContentWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  .font_color {
    color: #777;
  }
  .success_btn {
    margin-top: 20px;
    width: 70px;
    border-radius: 7px;
  }
`;

const SuccessWrapper = styled.div`
  height: calc(450px);
`;

const TalkToUsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
`;
