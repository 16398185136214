import { useState } from "react";
import { toast } from "react-hot-toast";
import { BsCheck, BsX } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useGetCartQuery } from "src/api/api";
import {
  useQuote,
  useQuotesCompare,
  useTheme,
  useUrlEnquiry,
} from "src/customHooks";
import FHTypography from "src/styles/Typography";
import { VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_ROW,
  FLEX_ROW_CENTER,
  PALETTE,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import styled from "styled-components/macro";
import CartToggleProductCTA from "./CartToggleProductCTA";
import RenderProductSummaryCard from "./RenderProductSummaryCard";
import { getMembersName } from "./cartSummary.utility";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import { useDispatch } from "react-redux";
import { removeQuoteFromCart } from "src/pages/Cart/cart.slice";

function CartGroupCard({ group, closeModal, allClose, selectedRiders }) {
  const { colors } = useTheme();
  const history = useHistory();
  const { enquiryId } = useUrlEnquiry();
  const { currentGroupObject } = useGetEnquiry();
  const { data } = useGetCartQuery();
  const { reset } = useQuotesCompare();
  const { isCompareRoute } = useGetRouteMatch();
  const { deleteQuote } = useQuote();
  const dispatch = useDispatch();

  // VARIABLE/S
  const { members } = group;

  let cartEntry = null;
  if (data?.data) {
    cartEntry = data.data.find(cartEntry => cartEntry?.group?.id === group?.id);
  }

  // STATE/s
  const [quoteDeleteMsg, setQuoteDeleteMsg] = useState(false);
  const [showQuoteDeleteMsg, setShowQuoteDeleteMsg] = useState(false);

  // FUNCTION/s

  function handleDeleteClick() {
    deleteQuote(cartEntry.id);
    dispatch(removeQuoteFromCart(cartEntry.group?.id));
  }

  function handleQuoteDeleteAction(shouldDelete = false) {
    if (!shouldDelete) {
      setQuoteDeleteMsg(false);
      setShowQuoteDeleteMsg(false);
      return;
    }
    if (isCompareRoute) {
      reset();
    }
    handleDeleteClick();
    closeModal?.();
    toast("Plan Deleted Successfully !!", {
      id: "shareQuote",
      position: "top-center",
      style: {
        background: colors.primary_color,
        color: "#fefefe",
      },
    });
    allClose();
    history.push({
      pathname: `/quotes/${group?.id}`,
      search: `enquiryId=${enquiryId}&pincode=${currentGroupObject.pincode}&city=${currentGroupObject.city}`,
    });
  }

  return (
    <CartGroupCardContainerStyled colors={colors}>
      <div className="groupCard_head">
        <MemberTitleStyled colors={colors}>
          {members.join(" + ")?.split("_").join("-")}
        </MemberTitleStyled>
        {!cartEntry && (
          <CartToggleProductCTA
            group={group}
            closeModal={closeModal}
            allClose={allClose}
            quoteDeleteMsg={quoteDeleteMsg}
            setQuoteDeleteMsg={setQuoteDeleteMsg}
            showQuoteDeleteMsg={showQuoteDeleteMsg}
            setShowQuoteDeleteMsg={setShowQuoteDeleteMsg}
          />
        )}
      </div>
      {showQuoteDeleteMsg && (
        <div className="quoteDelete_message">
          <FHTypography variant="subheading1" weight="md">
            Are you sure you want to delete the selected plan
            <strong>{" " + cartEntry?.product?.name}</strong> for
            <Strong>{" " + getMembersName(members)}</Strong>?
          </FHTypography>
          <div className="quoteDelete_actions">
            <button
              className="quoteDelete_button "
              onClick={() => handleQuoteDeleteAction(true)}
            >
              <BsCheck size={23} color={PALETTE.success.main} />
            </button>
            <button
              className="quoteDelete_button"
              onClick={() => handleQuoteDeleteAction(false)}
            >
              <BsX size={23} color={PALETTE.error.main} />
            </button>
          </div>
        </div>
      )}
      <RenderProductSummaryCard
        selectedRiders={selectedRiders}
        group={group}
        closeModal={closeModal}
        allClose={allClose}
        showQuoteDeleteMsg={showQuoteDeleteMsg}
        setShowQuoteDeleteMsg={setShowQuoteDeleteMsg}
        quoteDeleteMsg={quoteDeleteMsg}
        setQuoteDeleteMsg={setQuoteDeleteMsg}
      />
    </CartGroupCardContainerStyled>
  );
}

// STYLED

const CartGroupCardContainerStyled = styled.div`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  min-width: max-content;
  .groupCard_head {
    ${FLEX_ROW};
    justify-content: space-between;
    align-items: center;
  }

  .quoteDelete_message {
    margin-top: 12px;
    ${FLEX_ROW};
    gap: 0.5rem;
    align-items: center;

    .quoteDelete_actions {
      ${FLEX_ROW_CENTER};
      gap: 0.2rem;
    }
    .quoteDelete_button {
      border: 1px solid ${SHADES.LIGHT_GRAY};
      border-radius: ${ROUNDED.xl};
    }

    ${VIEW.md} {
      ${FLEX_ROW};
      justify-content: space-between;

      .quoteDelete_actions {
        gap: 1rem;
      }
      .quoteDelete_button {
        padding: 0.2rem 1rem;
        border-radius: ${ROUNDED.md};
      }
    }
  }
`;

const MemberTitleStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "semi",
}))`
  padding: 0.3rem 0;
  padding-left: 0.8rem;
  position: relative;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 2px;
    background-color: ${({ colors }) => colors.secondary_color};
  }
`;

const Strong = styled.strong`
  text-transform: capitalize;
`;

export default CartGroupCard;
