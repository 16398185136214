import { useState, useEffect } from "react";

export default function useSearchHospitals(hospitals = [], searchText = "") {
  const [foundHospitals, setFoundHospitals] = useState(hospitals);

  useEffect(() => {
    if (searchText) {
      const searchHospitalResult = hospitals.filter(data =>
        data.name.toLowerCase().includes(searchText.toLowerCase()),
      );
      setFoundHospitals(searchHospitalResult);
    } else {
      setFoundHospitals(hospitals);
    }
  }, [hospitals, searchText]);

  return foundHospitals;
}
