import { memo, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Backdrop from "src/components/Common/Backdrop";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import {
  FLEX_ROW,
  FLEX_ROW_CENTER,
  FONT,
  FWEIGHT,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import styled from "styled-components";
import CanvasQuotesList from "../components/CanvasQuotesList";
import ShareModuleFinalStep from "../components/ShareModuleFinalStep";
import ShareModuleShortlistStep from "../components/ShareModuleShortlistStep";
import useShareModal from "../hooks/useShareModal";
import { SHARE_MODULE_STAGES } from "../shareModule.constant";
import useHandleBodyScroll from "src/customHooks/useHandleBodyScroll";

function ShareModuleModal() {
  const {
    proposerData,
    enquiryData,
    shareModule,
    handleCloseShareModule,
    handleStepChangeShareModule,
  } = useShareModal();
  const { colors } = useTheme();
  const { hideBodyOverflow, unsetBodyOverflow } = useHandleBodyScroll();

  // useEffect of HideBodyOverflow
  useEffect(() => {
    if (shareModule.isOpen) {
      hideBodyOverflow();
    } else {
      unsetBodyOverflow();
    }
  }, [shareModule.isOpen]);

  if (!shareModule.isOpen) return null;
  return (
    <ShareModuleModalContainerStyled colors={colors}>
      <div className="shareModule_modal">
        <section className="shareModule_modal_head">
          <FHTypography variant="h4" weight="semi">
            Hi {proposerData?.name || enquiryData.name}, please select how
            you&apos;d like to share the
            {" " + shareModule.headingIdentifier}.
          </FHTypography>
          <button
            onClick={handleCloseShareModule}
            className="shareModule_modal_closeBtn"
          >
            <FaTimes size={20} />
          </button>
        </section>
        {shareModule.stage === SHARE_MODULE_STAGES.shortlisted && (
          <section className="shareModule_stepSelector">
            <button
              className={`${FONT.h6} ${
                shareModule.step == 1 && "selected_btn"
              }`}
              onClick={() => handleStepChangeShareModule({ step: 1 })}
            >
              1. Select plans
            </button>
            <button
              className={`${FONT.h6}  ${
                shareModule.step == 2 && "selected_btn"
              }`}
              onClick={() => handleStepChangeShareModule({ step: 2 })}
            >
              2. Share via
            </button>
          </section>
        )}
        {shareModule.step === 1 && <ShareModuleShortlistStep />}
        {shareModule.step === 2 && <ShareModuleFinalStep />}
        <CanvasQuotesList />
      </div>

      {/* backdrop */}
      <Backdrop bgColor={SHADES.HEADING + "55"} />
    </ShareModuleModalContainerStyled>
  );
}

const ShareModuleModalContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 1rem;
  z-index: 1100;
  ${FLEX_ROW_CENTER};

  .shareModule_modal {
    position: relative;
    max-width: 40rem;
    width: 100%;
    min-height: 27rem;
    background-color: #fff;
    z-index: 1100;
    border-radius: ${ROUNDED.xxl};
    overflow: hidden;

    &_head {
      ${FLEX_ROW};
      justify-content: space-between;
      align-items: center;
      background-color: ${SHADES.LIGHT_GRAY};
      padding: 1rem 1.5rem;

      .shareModule_modal_closeBtn {
        min-width: 2rem;
        align-self: flex-start;
      }
    }

    .shareModule_stepSelector {
      padding: 0.5rem;
      background-color: ${SHADES.WHITE};
      border-top: 1px solid ${SHADES.DARK_GRAY};
      gap: 2rem;
      ${FLEX_ROW_CENTER};

      button {
        position: relative;
        font-weight: ${FWEIGHT.semi};
        color: ${SHADES.BODY};
        padding: 0 0.5rem;
      }

      .selected_btn {
        color: ${({ colors }) => colors.primary_color};
        ::after {
          border-radius: ${ROUNDED.xl};
          content: "";
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          width: 100%;
          height: 0.2rem;
          background-color: ${({ colors }) => colors.primary_color};
        }
      }
    }
  }
`;

export default memo(ShareModuleModal);
