import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { SHADOW } from "src/styles/styleGuide";

const CustomToolTip = ({
  children,
  description = "",
  showInfoIcon = true,
  disable = false,
  position = "bottom",
  theme = "dark",
}) => {
  if (disable) return <>{children}</>;

  return (
    <Tooltip
      title={description}
      position={position}
      size="small"
      arrow={true}
      sticky={true}
      hideOnClick="persistent"
      trigger="mouseenter click"
      style={{
        maxWidth: "300px",
      }}
      theme={theme}
      html={
        theme === "light" && (
          <div
            style={{
              boxShadow: SHADOW.xxl,
            }}
          >
            {description}
          </div>
        )
      }
    >
      {children}
      {description?.length > 0 && showInfoIcon && <AiOutlineInfoCircle />}
    </Tooltip>
  );
};

export default CustomToolTip;
