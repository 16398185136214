import { RiLoader4Fill, RiShareForwardFill } from "react-icons/ri";
import { useFrontendBoot } from "src/customHooks/useGetCommonQueries";
import { useTheme } from "src/customHooks";
import FHTypography from "src/styles/Typography";
import {
  FLEX_COL,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  FONT,
  FWEIGHT,
  PALETTE,
  ROUNDED,
  SHADES,
} from "src/styles/styleGuide";
import styled from "styled-components";
import useShareModal from "../hooks/useShareModal";
import { SHARE_OPTIONS } from "../shareModule.constant";
import ShareModuleOtherActions from "./ShareModuleOtherActions";
import { DEVICES, VIEW } from "src/styles/responsiveStyle";
import { useResponsiveCondition } from "src/pos/hooks";
import { Fragment } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { CiCircleRemove } from "react-icons/ci";
import { FaTelegramPlane } from "react-icons/fa";
import { Button } from "src/components/Common/Button/ContinueButton";
import { brokerTenant } from "src/utils/tenantSettings";

function ShareModuleFinalStep() {
  const { width, breakpoint } = useResponsiveCondition(DEVICES.tabletSM);
  const {
    handleChangeShareElementValues,
    shareElementValues,
    shareElementError,
    isSharing,
    shareResponse,
    shareAllResponse,
    handleShareElementClick,
    handleShareAllElementClick,
  } = useShareModal();

  const { settings } = useFrontendBoot();
  const { share_option_visibility } = settings || {};

  const { colors } = useTheme();
  return (
    <ShareModuleModalContentContainerStyled colors={colors}>
      {SHARE_OPTIONS.map(option => {
        // we have to add ! here
        if (!share_option_visibility.includes(option.label)) return <></>;

        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              handleShareElementClick(option.label);
            }}
            key={option.label}
            className="share_element_inputContainer"
          >
            <label
              htmlFor={`share_${option.label}`}
              className="share_element_label"
            >
              <section className="share_element_icon">
                <span>{option.icon}</span>
              </section>
              <input
                type="text"
                name={option.label}
                id={option.label}
                value={shareElementValues[option.label]}
                onChange={handleChangeShareElementValues}
                autoComplete="off"
                placeholder={option.placeholder}
                maxLength={option.label !== "email" ? 10 : 100}
              />
              <button
                className={`shareBtn ${FONT.subheading1}`}
                type="submit"
                disabled={isSharing[option.label]}
              >
                {width > breakpoint ? (
                  <Fragment>
                    <span>Share</span>
                    {isSharing[option.label] && (
                      <RiLoader4Fill className="rotate" size={20} />
                    )}
                  </Fragment>
                ) : isSharing[option.label] ? (
                  <RiLoader4Fill className="rotate" size={20} />
                ) : (
                  <RiShareForwardFill size={20} />
                )}
              </button>
            </label>
            <FHTypography
              variant="subheading2"
              weight="md"
              type="error"
              className="shareModule_messages"
            >
              {shareElementError[option.label]}
            </FHTypography>
          </form>
        );
      })}
      {brokerTenant("fyntune") && (
        <ShareAllButton
          primaryColor={colors.primary_color}
          onClick={() =>
            handleShareAllElementClick(SHARE_OPTIONS, share_option_visibility)
          }
        >
          Share to all communication channels
          {Object.keys(isSharing).every(
            status => isSharing[status] === true,
          ) ? (
            <RiLoader4Fill className="rotate" size={20} />
          ) : (
            <FaTelegramPlane size={20} />
          )}
        </ShareAllButton>
      )}
      <ShareModuleOtherActions />

      <section className="shareModule_content_foot">
        <FHTypography variant="h5" weight="semi" colorType="heading">
          * Please note that the premium may vary in future.
        </FHTypography>
      </section>
      {
        // show error or success response
        shareAllResponse ? (
          <ShareModuleResponseTextStyled type={shareAllResponse}>
            <FHTypography
              variant="h6"
              weight="md"
              className="shareModule_messages"
            >
              {shareAllResponse === "success"
                ? "Shared to all communication channels successfully"
                : "Failed to share over all communication channels!"}
            </FHTypography>
            {shareAllResponse === "success" && <BsCheckCircle size={20} />}
          </ShareModuleResponseTextStyled>
        ) : shareResponse.type ? (
          <ShareModuleResponseTextStyled type={shareResponse.type}>
            {shareResponse.type === "success" ? (
              <FHTypography
                variant="h6"
                weight="md"
                className="shareModule_messages"
              >
                {shareResponse?.message}
              </FHTypography>
            ) : (
              <FHTypography
                variant="h6"
                weight="md"
                className="shareModule_messages"
              >
                Something Went Wrong
              </FHTypography>
            )}

            {shareResponse.type === "success" ? (
              <BsCheckCircle size={20} />
            ) : (
              <CiCircleRemove size={20} />
            )}
          </ShareModuleResponseTextStyled>
        ) : (
          <></>
        )
      }
    </ShareModuleModalContentContainerStyled>
  );
}

const ShareModuleResponseTextStyled = styled.div`
  padding: 1rem 0;
  background-color: ${({ type }) => PALETTE[type].main + "22"};
  color: ${({ type }) => PALETTE[type].main};
  border-radius: ${ROUNDED.base};
  ${FLEX_ROW_CENTER};
  gap: 0.6rem;
`;

const ShareModuleModalContentContainerStyled = styled.div`
  ${FLEX_COL};
  padding: 1rem 1.5rem;
  gap: 0.5rem;

  ${VIEW.md} {
    gap: 1rem;
  }

  .share_element_inputContainer {
    width: 100%;
    ${FLEX_COL};
    gap: 0.5rem;
  }

  .share_element_label {
    overflow: hidden;
    ${FLEX_ROW};
    width: 100%;
    border: 2px solid ${SHADES.LIGHT_GRAY};
    border-radius: ${ROUNDED.lg};

    .share_element_icon {
      padding: 0.3rem;
      position: relative;
      ${FLEX_ROW_CENTER};
      span {
        ${FLEX_ROW_CENTER};
        width: 2rem;
        height: 2rem;
        background-color: ${({ colors }) => colors.primary_color + "22"};
        border-radius: ${ROUNDED.full};
        color: ${({ colors }) => colors.primary_color};
      }

      ${VIEW.md} {
        padding: 0.5rem;
        span {
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1.5rem;
        }
      }
    }

    input {
      width: 70%;
      padding: 0.5rem 0.5rem;
      border: none;
      font-size: 0.9rem;
      font-weight: ${FWEIGHT.md};

      ${VIEW.xs} {
        flex: 1;
      }

      ${VIEW.md} {
        padding: 0.8rem 0.5rem;
        font-size: 1.1rem;
      }
    }

    .shareBtn {
      ${FLEX_ROW_CENTER};
      gap: 0.5rem;
      background-color: ${({ colors }) => colors.primary_color};
      color: ${SHADES.WHITE};
      padding: 0 1.2rem;
      border-radius: none;
      font-weight: ${FWEIGHT.md};

      ${VIEW.md} {
        min-width: 8rem;
      }

      :disabled {
        filter: brightness(0.9);
        cursor: not-allowed;
      }
    }
  }

  .shareModule_content_foot {
    padding: 1rem 0.5rem;
    text-align: center;
    background-color: ${SHADES.WHITE};
    border-radius: ${ROUNDED.md};
    ${FLEX_ROW_CENTER};
  }

  .shareModule_messages {
    ::first-letter {
      text-transform: capitalize;
    }
  }
`;

const ShareAllButton = styled(Button)`
  background-color: #fff;
  border: 2px solid ${props => props.primaryColor};

  & h6 {
    ${FLEX_ROW_CENTER};
    gap: 0.4rem;
    color: ${props => props.primaryColor};
  }
  &:hover {
    background-color: ${props => props.primaryColor};
    h6 {
      color: #fff;
    }
  }
`;

export default ShareModuleFinalStep;
