import { StyledWrapper } from "./OfflinePage.style.js";
import "styled-components/macro";
import { base64ToImageSrc } from "src/utils/helper.js";
import noConnectionBase64 from "../../assets/base64/noConnection.js";

export default function OfflinePage() {
  return (
    <StyledWrapper imageSize="550px">
      <img
        src={base64ToImageSrc({
          base64: noConnectionBase64,
          imageMimetype: "image/png",
        })}
        alt="No internet connection."
        loading="lazy"
      />
      <h1>Please check your internet connection.</h1>
    </StyledWrapper>
  );
}
