import React from "react";
import { useGetCartQuery } from "src/api/api";
import CardSkeletonLoader from "../Common/card-skeleton-loader/CardSkeletonLoader";
import CartProductSummaryCard from "./CartProductSummaryCard";

function RenderProductSummaryCard({
  group,
  showQuoteDeleteMsg,
  closeModal,
  allClose,
  setShowQuoteDeleteMsg,
  quoteDeleteMsg,
  setQuoteDeleteMsg,
  ...props
}) {
  const { data, isLoading, isUninitialized } = useGetCartQuery();

  if (isLoading || isUninitialized) return <CardSkeletonLoader />;

  let cartEntry = null;

  if (data.data) {
    cartEntry = data.data.find(cartEntry => cartEntry?.group?.id === group?.id);
  }

  if (!cartEntry) return null;

  return (
    <CartProductSummaryCard
      cartEntry={cartEntry}
      groupCode={cartEntry?.group?.id}
      showQuoteDeleteMsg={showQuoteDeleteMsg}
      group={group}
      closeModal={closeModal}
      allClose={allClose}
      setShowQuoteDeleteMsg={setShowQuoteDeleteMsg}
      setQuoteDeleteMsg={setQuoteDeleteMsg}
      quoteDeleteMsg={quoteDeleteMsg}
      {...props}
    />
  );
}

export default RenderProductSummaryCard;
