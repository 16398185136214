import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//  CUSTOM HOOKS
import { useCart } from "src/customHooks";

export default function useRiderLoading({ groupCode }) {
  const { getCartEntry } = useCart();
  const cartEntry = getCartEntry(groupCode);
  const updatedTenure = cartEntry?.tenure || null;

  const { ridersLoading } = useSelector(({ productPage }) => productPage);

  const [currentTenure, setCurrentTenure] = useState(updatedTenure);
  const tenureChanged = currentTenure !== updatedTenure;
  let shouldLoadRiders = false;

  useEffect(() => {
    if (!ridersLoading) {
      setCurrentTenure(updatedTenure);
    }

    return () => {};
  }, [ridersLoading]);

  if (tenureChanged) {
    shouldLoadRiders = tenureChanged;
  }

  return ridersLoading || shouldLoadRiders;
}
