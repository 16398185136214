// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  FeaturesTabListStyled,
  FeatureTabContainer,
} from "./PlanDetails.style";
// CUSTOM COMPONENTS
import FeatureDefinition from "./FeatureDefinition";

export default function FeatureTab({ data }) {
  const showAsBulletList = ["permanent_exclusions", "major_exclusions"];

  if (+data?.is_visible == 0) return <></>;

  if (showAsBulletList.includes(data?.code)) {
    return (
      <FeatureTabContainer>
        <ul>
          {data.value
            .split("\n")
            .filter(item => item)
            .map((item, index) => {
              const bulletIndex = item.indexOf("•");
              const newItem =
                bulletIndex > -1 ? item.substring(bulletIndex + 1) : item;

              return (
                <FeaturesTabListStyled
                  key={index}
                  isSingle={
                    data.value.split("\n").filter(item => item).length === 1
                  }
                >
                  <FHTypography variant="h6" weight="base" colorType="heading">
                    {newItem}
                  </FHTypography>
                </FeaturesTabListStyled>
              );
            })}
        </ul>
      </FeatureTabContainer>
    );
  }

  if (!["co_payment", ...showAsBulletList].includes(data?.code)) {
    return (
      <FeatureTabContainer>
        <FeatureDefinition data={data} />
      </FeatureTabContainer>
    );
  }
}
