import { brokerTenant } from "./tenantSettings";

// function that extract search params
function getUrlQueries() {
  const queries = window.location.search?.split("?")[1];
  const paramsList = queries?.split("&");
  const paramsObject = {};
  paramsList?.length &&
    paramsList?.forEach(data => {
      const value = data.search("=");
      const itemName = data.slice(0, value);
      const itemValue = data.slice(value + 1, data.length);
      paramsObject[itemName] = itemValue;
    });
  return paramsObject;
}

// bajaj campaign url params get listed key and send them to create enquiry
function getBajajUrlUTM() {
  const urlQueries = getUrlQueries();
  if (!brokerTenant("bajaj")) return {};
  return {
    lead_source: urlQueries.lead_source,
    utm_source: urlQueries.utm_source,
    utm_medium: urlQueries.utm_medium,
    utm_campaign: urlQueries.utm_campaign,
    insurance_unassigned_user: urlQueries.insurance_unassigned_user,
  };
}

export { getBajajUrlUTM, getUrlQueries };
