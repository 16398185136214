import React from "react";
import { RiLoader4Fill } from "react-icons/ri";
import styled from "styled-components";

const Spinner = ({ size = 25, color }) => {
  return (
    <SpinnerStyled color={color}>
      <RiLoader4Fill size={size} className="rotate icon_color" />
    </SpinnerStyled>
  );
};

export default Spinner;

const SpinnerStyled = styled.div`
  display: inline-block;
  .icon_color {
    color: ${props => props.color};
  }
`;
