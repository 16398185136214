import { useRouteMatch, useHistory } from "react-router-dom";
import { Button } from "src/components/Common/Button/ContinueButton";
import { useQuote, useTheme } from "src/customHooks";
import styled from "styled-components";

import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { calculateTotalPremium } from "src/utils/helper";
import { amount } from "src/utils/string.utility";
import { FLEX_COL, FLEX_ROW } from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import { useSelector, useDispatch } from "react-redux";
import { VIEW } from "src/styles/responsiveStyle";
import { brokerTenant } from "src/utils/tenantSettings";
import { setSeeDetailsModalAction , setCartSummaryModalAction} from "src/slices/utility.slice";


export default function MobileSeeDetailsFooter({ quote, onClose }) {
  const {
    buyQuote,
    queryState: { isLoading },
  } = useQuote();

  const history = useHistory();
  const dispatch = useDispatch()
  const { journeyType } = useFrontendBoot();
  const { groups, currentGroupObject, enquiryData } = useGetEnquiry();

  const { total_premium } = quote;

  const { riders = [] } = useSelector(state => state.productPage);
  const selectedRiders = riders.filter(rider => rider.isSelected);

  const handlePremiumClick = () => {
    buyQuote(quote, selectedRiders).then(() => {
      if (
        brokerTenant("fyntune") &&
        (groups.length === 1 || currentGroupObject.type === "all")
      ) {
        history.push(
          `/productdetails/${currentGroupObject?.id}?enquiryId=${enquiryData?.enquiry_id}`,
        );
        onClose?.();
        return;
      }
      dispatch(setCartSummaryModalAction(true))
      dispatch(setSeeDetailsModalAction(false))
    });
  };

  const { colors } = useTheme();

  const isQuoteRoute = useRouteMatch({ path: "/quotes" });
  const isShortlistRoute = useRouteMatch({ path: "/shortlisted" });

  const netPremium = calculateTotalPremium(
    {
      total_premium,
      health_riders: selectedRiders,
      top_up_riders: selectedRiders,
    },
    {},
    journeyType,
  );

  return (
    <>
      <MobileSeeDetailsFooterStyled>
        <TotalPremiumWrapperStyled>
          <FHTypography variant="h5" weight="semi" colorType="heading">
            Total Premium&nbsp;:
          </FHTypography>
          <FHTypography variant="h4" weight="bold" color={colors.primary_color}>
            {amount(netPremium)}
          </FHTypography>
        </TotalPremiumWrapperStyled>

        {(isQuoteRoute || isShortlistRoute) && (
          <ProceedButtonStyled onClick={handlePremiumClick} loader={isLoading}>
            Proceed To Buy
          </ProceedButtonStyled>
        )}
      </MobileSeeDetailsFooterStyled>
    </>
  );
}

const MobileSeeDetailsFooterStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TotalPremiumWrapperStyled = styled.div`
  ${FLEX_COL}
  gap:0.5rem;
  ${VIEW.sm} {
    ${FLEX_ROW}
  }
`;

const ProceedButtonStyled = styled(Button).attrs(() => ({
  btnId: "see-detail-proceed-btn",
  font: "h5",
}))`
  padding: 1rem 1.5rem;
`;
