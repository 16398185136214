import { useState } from "react";
import { numToLakh } from "src/utils/string.utility";
import { envAppBaseURL } from "../utils/tenantSettings";
import { useParams } from "react-router-dom";
import { useFrontendBoot } from "./useGetCommonQueries";
import { getUrlQueries } from "src/utils/url.utility";
import useGetRouteMatch from "./useGetRouteMatch";

function getBrokerURL() {
  return window.location.origin + process.env.REACT_APP_BASE_ROUTE;
}

function getQuotesWithLogo(companies, quotes) {
  const logoArray = quotes?.map(quote => {
    return {
      ...quote,
      logo: companies[quote.company_alias]?.logo,
    };
  });
  return logoArray;
}

function getFeatures(quotes, journeyType) {
  const newQuotesWithFeatures = quotes?.map(quote => {
    const featureList = JSON.parse(sessionStorage.getItem(quote?.product?.id));
    const currentProductFeaturesBySumInsures = featureList?.map(feature => {
      return {
        feature_name: feature?.name,
        content: feature?.sum_insureds[quote.sum_insured]?.features,
      };
    });

    // add isSelected to mandatory or included riders
    const riders =
      quote?.[`${journeyType}_riders`]?.map(rider => {
        return rider.is_mandatory || rider.is_rider_default
          ? { ...rider, isSelected: true }
          : rider;
      }) ?? [];

    return {
      product: quote.product,
      sum_insured: numToLakh(quote.sum_insured),
      cashless_hospitals_count: quote.cashless_hospitals_count,
      company_alias: quote.company_alias,
      deductible: quote.deductible,
      tenure: quote.tenure,
      tax_amount: quote.tax_amount,
      service_tax: quote.service_tax,
      supports_port: quote.supports_port,
      premium: quote.premium,
      total_premium: sessionStorage.getItem(
        `premium${quote.product.id}${quote.sum_insured}`,
      ),
      features: currentProductFeaturesBySumInsures,
      feature_options: quote?.selectedFeatureOptions ?? [],
      riders,
      usp_message: quote?.usp_message,
    };
  });

  return newQuotesWithFeatures;
}

export default function useDownloadPDF({ quotes, selectedSectionView }, mode) {
  const [pdfFetchLoading, setPdfFetchLoading] = useState(false);
  const { data, journeyType } = useFrontendBoot();
  const { groupCode } = useParams();
  const enquiryData = getUrlQueries();
  const [url, setUrl] = useState("");
  const { isCompareRoute } = useGetRouteMatch();

  async function downloadComparePDF(redirect = true) {
    const newQuotes = getFeatures(quotes, journeyType);
    const pdfBodyContent = JSON.stringify({
      quotes: getQuotesWithLogo(data?.companies, newQuotes),
      groupCode: groupCode,
      selectedSectionView: selectedSectionView,
      broker_name: data?.tenant?.name,
      broker_contact_email: data?.settings?.email,
      broker_url: getBrokerURL(data?.tenant?.alias),
      broker_logo: data?.settings?.logo,
      is_pos: false,
      pos_name: "Test",
      pos_number: 7845120369,
    });
    try {
      setPdfFetchLoading(true);

      const response = await fetch(`${envAppBaseURL()}compare/share_pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "enquiry-id": enquiryData.enquiryId,
        },
        body: pdfBodyContent,
      });

      if (response.status === 200) {
        if (mode === "share") {
          const data = await response.json();
          if (isCompareRoute) {
            return data.pdf_path;
          } else {
            setPdfFetchLoading(false);
            setUrl(data.pdf_path);
          }
        } else {
          response.json().then(data => {
            if (data) {
              setPdfFetchLoading(false);
              setUrl(data.pdf_path);
              if (redirect) {
                let anchor = document.createElement("a");
                document.body.appendChild(anchor);
                anchor.style = "display: none";
                anchor.setAttribute("href", data?.pdf_path);
                anchor.setAttribute("target", "_blank");
                anchor.click();
                document.body.removeChild(anchor);
              }
            }
          });
        }
      } else {
        console.error(response.message);
        setPdfFetchLoading(false);
      }
      setPdfFetchLoading(false);
    } catch (error) {
      console.error(error);
      setPdfFetchLoading(false);
    }
  }

  return { pdfFetchLoading, downloadComparePDF, url, setUrl };
}
