/*
# Title:- src/components/PageLoader.jsx
# Used In:-
- This component is used in Page.jsx
*/

import { useEffect } from "react";
import { some } from "lodash";
import { useDispatch } from "react-redux";
import { LoadCart, LoadEnquiries, LoadShortlistedQuotes } from ".";
import { useGetFrontendBootQuery } from "src/api/api";
import CheckPolicies from "./Common/CheckPolicies";
import { setFrontendData } from "src/FrontendBoot/reducer/frontendBoot.slice";
import { networkStatusChecker } from "src/utils/helper";
import OfflinePage from "./OfflinePage/OfflinePage";
import InternalServerErrorPage from "./ServerErrorPages/InternalServerErrorPage";
import MaintenancePage from "./ServerErrorPages/MaintenancePage";
import { envTenant } from "src/utils/tenantSettings";
import useBlockJourney from "src/customHooks/useBlockJounrey";
import FullScreenLoader from "./Common/FullScreenLoader";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";

function PageLoaders({ children, ...props }) {
  const dispatch = useDispatch();
  const {
    isRootRoute,
    isBasicDetailsRoute,
    isQuoteRoute,
    isCompareRoute,
    isProposalRoute,
    isProductDetailsRoute,
    isProposalSummaryRoute,
    isThankYouRoute,
    isRecommendRoute,
    isRecommendResultRoute,
    isShortlistRoute,
    isPaymentFailureRoute,
    isRenewalDetailsRoute,
    isChooseYourJourneyPage,
    isPortabilityRoute,
  } = useGetRouteMatch();

  // TODO : ANY NEW PAGE/ROUTE WILL ONLY WORK IF IT IS ADDED HERE.
  const isNotFoundRoute = !some([
    isQuoteRoute,
    isProductDetailsRoute,
    isProposalRoute,
    isProposalSummaryRoute,
    isCompareRoute,
    isThankYouRoute,
    isRecommendRoute,
    isRecommendResultRoute,
    isShortlistRoute,
    isPaymentFailureRoute,
  ]);

  const {
    isLoading,
    isUninitialized,
    error: serverError,
    data: frontendBoot,
    isSuccess,
  } = useGetFrontendBootQuery(undefined, {});

  useEffect(() => {
    if (isSuccess) {
      dispatch(setFrontendData(frontendBoot));
    }
    return () => {};
  }, [isSuccess]);

  if (isLoading || isUninitialized) {
    return <FullScreenLoader />;
  }

  if (serverError?.originalStatus === 503) {
    return <MaintenancePage />;
  }

  if (serverError?.originalStatus === 500 || serverError) {
    return networkStatusChecker({
      tenant: envTenant(),
      offlineJSX: <OfflinePage />,
      serverErrorJSX: <InternalServerErrorPage />,
      status: serverError?.status,
    });
  }

  if (
    some([
      isRootRoute,
      isBasicDetailsRoute,
      isRenewalDetailsRoute,
      isChooseYourJourneyPage,
      isPortabilityRoute,
      isNotFoundRoute,
    ])
  ) {
    return <AllValidations>{children}</AllValidations>;
  }

  return (
    <LoadEnquiries {...props}>
      <CheckPolicies>
        <LoadCart>
          <LoadShortlistedQuotes>
            <AllValidations>{children}</AllValidations>
          </LoadShortlistedQuotes>
        </LoadCart>
      </CheckPolicies>
    </LoadEnquiries>
  );
}

// This component is used for all the client side validation on journey before it starts
function AllValidations({ children }) {
  useBlockJourney();
  return <>{children}</>;
}

export default PageLoaders;
