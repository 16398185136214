import styled from "styled-components";

export default function CardSkeletonLoader({ noOfCards = 1 }) {
  return Array(noOfCards)
    .fill("-")
    .map((_, i) => (
      <LoaderStyled key={i}>
        <div id="u_0_v">
          <div className="lightui1">
            <div className="lightui1-shimmer">
              <div className="_2iwr"></div>
              <div className="_2iws"></div>
              <div className="_2iwt"></div>
              <div className="_2iwu"></div>
              <div className="_2iwv"></div>
              <div className="_2iww"></div>
              <div className="_2iwx"></div>
              <div className="_2iwy"></div>
              <div className="_2iwz"></div>
              <div className="_2iw-"></div>
              <div className="_2iw_"></div>
              <div className="_2ix0"></div>
            </div>
          </div>
        </div>
      </LoaderStyled>
    ));
}

const LoaderStyled = styled.div`
  #u_0_v {
    width: 100%;
  }

  .lightui1 {
    border: 1px solid;
    border-color: #bdbdbd;
    border-radius: 2px;
    padding: 20px;
    background: #ffffff;
    margin-bottom: 20px;
  }

  .lightui1-shimmer {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #d8d8d8;
    background-image: linear-gradient(
      to right,
      #d8d8d8 0%,
      #afafaf 20%,
      #d8d8d8 40%,
      #d8d8d8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 104px;
    position: relative;
  }

  .lightui1-shimmer div {
    background: #ffffff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
  }

  .lightui1b-shimmer {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f0f0f0;
    background-image: linear-gradient(
      to right,
      #f0f0f0 0%,
      #d8d8d8 20%,
      #f0f0f0 40%,
      #f0f0f0 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 104px;
    position: relative;
  }

  .lightui1b-shimmer div {
    background: #ffffff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
  }

  ._2iwo {
    height: 200px;
    padding: 12px;
  }

  .__z8 {
    height: 150px;
    padding: 12px;
  }

  div._2iwr {
    height: 40px;
    left: 40px;
    right: auto;
    top: 0;
    width: 8px;
  }

  div._2iws {
    height: 8px;
    left: 48px;
    top: 0;
  }

  div._2iwt {
    left: 50%;
    top: 8px;
  }

  div._2iwu {
    height: 12px;
    left: 48px;
    top: 14px;
  }

  div._2iwv {
    left: 31%;
    top: 26px;
  }

  div._2iww {
    height: 10px;
    left: 48px;
    top: 32px;
  }

  div._2iwx {
    height: 20px;
    top: 40px;
  }

  div._2iwy {
    left: 80%;
    top: 60px;
  }

  div._2iwz {
    height: 13px;
    top: 66px;
  }

  div._2iw- {
    left: 90%;
    top: 79px;
  }

  div._2iw_ {
    height: 13px;
    top: 85px;
  }

  div._2ix0 {
    left: 60%;
    top: 98px;
  }

  @-webkit-keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  @-webkit-keyframes prideShimmer {
    from {
      background-position: top left;
    }
    to {
      background-position: top right;
    }
  }
  ._4-u5 {
    background-color: #ffffff;
  }

  ._4-u7 {
    background-color: #ffffff;
  }

  ._57d8 {
    background-color: #ffffff;
  }

  ._4-u8 {
    background-color: #ffffff;
  }
`;
