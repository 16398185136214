import { createSlice } from "@reduxjs/toolkit";

const productPageSlice = createSlice({
  initialState: {
    steps: 1,
    currentSection: "additional-riders",
    expandMobile: false,
    additionalDiscounts: [],
    ridersLoading: false,
    defaultRidersLoading: false,
    editMembersModal: false,
    riderActionBtnClicked: false,
    effectiveCostModalOpen: false,
    currentTaxSlab: 30,
    totalPremiumForEffectiveCost: 0,
    riders: [],
    showRenewalKYCPopup: false,
    featureDependentRiders: {},
  },
  name: "product",
  reducers: {
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setexpandMobile: (state, action) => {
      state.expandMobile = action.payload;
    },
    setAdditionalDiscounts: (state, action) => {
      state.additionalDiscounts = action.payload;
    },
    setRidersLoading(state, { payload }) {
      state.ridersLoading = payload;
    },
    resetCheckedRiders(state) {
      state.checkedRiders = {};
    },
    setDefaultRidersLoading(state, action) {
      state.defaultRidersLoading = action.payload;
    },
    setEditMembersModal(state, action) {
      state.editMembersModal = action.payload;
    },
    setRiderActionBtnClicked(state, action) {
      state.riderActionBtnClicked = action.payload;
    },
    toggleEffectiveCostModal(state) {
      state.effectiveCostModalOpen = !state.effectiveCostModalOpen;
    },
    setCurrentTaxSlab(state, ation) {
      state.currentTaxSlab = ation.payload;
    },
    setRiders(state, action) {
      state.riders = action.payload;
    },
    setShowRenewalKYCPopup(state, action) {
      state.showRenewalKYCPopup = action.payload;
    },
    setFeatureDependentRiders: (state, action) => {
      state.featureDependentRiders = action.payload;
    },
  },
});

export const {
  setSteps,
  setCurrentSection,
  setexpandMobile,
  setAdditionalDiscounts,
  setRidersLoading,
  resetCheckedRiders,
  setDefaultRidersLoading,
  setEditMembersModal,
  setRiderActionBtnClicked,
  toggleEffectiveCostModal,
  setCurrentTaxSlab,
  setRiders,
  setShowRenewalKYCPopup,
  setFeatureDependentRiders,
} = productPageSlice.actions;

export default productPageSlice.reducer;
export const selectAdditionalDiscounts = state =>
  state.productPage.additionalDiscounts;
