import { useEffect, useState } from "react";

import useIsPos from "../pos/useIsPos";
import { checkTopup } from "src/utils/checkJourney";
import { useFrontendBoot } from "./useGetCommonQueries";

export function usePos(currentForm) {
  const { isPosJourney } = useIsPos();
  const defaultValue = {
    banner: "",
    question: "",
  };

  function returnDefaultString(value) {
    if (value === "<p><br></p>") {
      return "";
    } else {
      return value;
    }
  }
  const {
    data: { settings, tenant },
  } = useFrontendBoot();
  const [posContent, setPosContent] = useState(defaultValue);

  useEffect(() => {
    let place = currentForm;
    if (currentForm.startsWith("location")) {
      place = "location";
    }
    if (settings) {
      switch (place) {
        case "basic-details":
          isPosJourney
            ? setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner_pos)
                  : returnDefaultString(settings?.basic_detail_banner_pos),
                question: returnDefaultString(
                  settings?.basic_detail_question_pos,
                ),
              })
            : setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner)
                  : returnDefaultString(settings?.basic_detail_banner),
                question: returnDefaultString(settings?.basic_detail_question),
              });
          break;
        case "members":
          isPosJourney
            ? setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner_pos)
                  : returnDefaultString(settings?.input_members_banner_pos),
                question: returnDefaultString(
                  settings?.input_members_question_pos,
                ),
              })
            : setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner)
                  : returnDefaultString(settings?.input_members_banner),
                question: returnDefaultString(settings?.input_members_question),
              });
          break;
        case "plantype":
          isPosJourney
            ? setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner_pos)
                  : returnDefaultString(settings?.plan_type_banner_pos),
                question: returnDefaultString(settings?.plan_type_question_pos),
              })
            : setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner)
                  : returnDefaultString(settings?.plan_type_banner),
                question: returnDefaultString(settings?.plan_type_question),
              });
          break;
        case "location":
          isPosJourney
            ? setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner_pos)
                  : returnDefaultString(settings?.location_banner_pos),
                question: returnDefaultString(settings?.location_question_pos),
              })
            : setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner)
                  : returnDefaultString(settings?.location_banner),
                question: returnDefaultString(settings?.location_question),
              });
          break;
        case "medicalHistory":
          isPosJourney
            ? setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner_pos)
                  : returnDefaultString(settings?.medical_history_banner_pos),
                question: returnDefaultString(
                  settings?.medical_history_question_pos,
                ),
              })
            : setPosContent({
                banner: checkTopup({ tenant })
                  ? returnDefaultString(settings?.deductible_banner)
                  : returnDefaultString(settings?.medical_history_banner),
                question: returnDefaultString(
                  settings?.medical_history_question,
                ),
              });
          break;
        case "deductible":
          isPosJourney
            ? setPosContent({
                banner: returnDefaultString(settings?.deductible_banner_pos),
                question: returnDefaultString(
                  settings?.deductible_question_pos,
                ),
              })
            : setPosContent({
                banner: returnDefaultString(settings?.deductible_banner),
                question: returnDefaultString(settings?.deductible_question),
              });
          break;
        default:
          setPosContent({
            banner: "",
            question: "",
          });
          break;
      }
    }
    return () => {};
  }, [settings, currentForm, isPosJourney]);

  return { posContent };
}
