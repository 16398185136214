import styled from "styled-components";

export const FooterContainer = styled.div`
  font-size: 16px;
  /* margin-right: -42%; */
  width: 100%;
  .footer_container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    /* position: absolute;
              bottom: -57px; */
    margin-top: 50px;
    background-color: rgb(226, 225, 229);
    width: 100%;
    overflow: hidden;
    padding: 18px 30px;
    font-size: 14px;
    text-align: center;
    font-family: "Inter-Regular";
    font-weight: 400;
    &:after {
      box-sizing: border-box;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
  span {
    font-weight: 600;
  }
  .pad_x {
    padding: 0px 20px;
  }
  a {
    font: 300 16px/20px "Poppins", sans-serif;
    color: #1f1f1f;
    position: relative;
    transition: color 0.2s linear;
    display: inline-block;
    :hover {
      color: #f30909;
      text-decoration: none;
      outline: 0 !important;
      ::after {
        transform: scale(1, 1);
      }
    }
    ::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 0;
      background: red;
      transform: scale(0, 1);
      transform-origin: 0% 100%;
      transition: transform 0.3s ease;
    }
  }
  h3 {
    font-family: "basiersquaremed", Arial, sans-serif;
    margin-top: 5px;
    text-shadow: none;
    color: inherit;
  }
  .color_grey_f {
    color: #1f1f1f;
  }
  .mb_40 {
    margin-bottom: 40px;
  }
  .f_600 {
    font-weight: 600;
  }
  .f_400 {
    font-weight: 400;
  }
  .f_p {
    font-family: "Poppins", sans-serif;
  }
  .l_height34 {
    line-height: 34px;
  }
  .f_size_15 {
    font-size: 15px;
  }
  .f_subscribe {
    position: relative;
    margin-top: 13px;
    margin-bottom: 12px;
  }
  .f_subscribe .form-control {
    font: 300 14px "Poppins", sans-serif;
    color: #333;
    border: 1px solid #e2e7f3;
    border-radius: 3px;
    background: #e9ecf3;
    padding-left: 25px;
    height: 54px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .f_size_18 {
    font-size: 18px;
  }
  .f_widget.about-widget .f_list {
    margin-bottom: 0px;
  }
  .f_widget.about-widget .f_list li {
    margin-bottom: 15px;
  }

  .f_subscribe button {
    position: absolute;
    right: 25px;
    background: transparent;
    padding: 0;
    color: #222d39;
    font-size: 16px;
    top: 52%;
    transform: translateY(-50%);
  }
`;

export const TopContainer = styled.div`
  border-bottom: 1px solid #e9ecf3;
  padding: 120px 0px 70px;
  background: #e0e0e0;
  display: flex;

  .logo_ic {
    width: 100px;
    height: auto;
  }
  .pl_40 {
    padding-left: 40px;
    @media screen and (max-width: 992px) {
      padding-left: 0px;
    }
  }
  .f_social_icon a {
    width: 46px;
    height: 46px;
    border-radius: 50% !important;
    font-size: 14px;
    line-height: 45px;
    color: #858da8;
    display: inline-block;
    background: #ebeef5;
    text-align: center;
    transition: all 0.2s linear;
    :hover {
      background: #515eb8;
      color: #fff;
    }
  }
  .f_social_icon a + a {
    margin-left: 10px;
  }
  p {
    font-size: 16px;
  }
  .f_widget .widget-wrap {
    margin-top: 35px;
  }
  input[type="text"] {
    -webkit-appearance: none;
    outline: none;
  }
`;

export const BottomContainer = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
  background: #252525;
  p {
    color: #ccc;
    font-weight: 400;
  }
`;

export const FooterMain = styled.div`
  color: #fff;
  font-size: 14px;
  background: ${props => props.colors.primary_color};
  position: relative;
  ${props => props.customCss};
`;

export const CopyrightImg = styled.img`
  width: 420px;
  padding: 18px 4px 0px;
`;

export const FooterData = styled.div`
  text-align: center;
  font-size: 12px;
  padding: 1rem 0;
`;

export const UpperFooterTata = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 40px 20px 40px 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PortionFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px !important;
  p {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    gap: 3px;
  }
  li:first-child {
    margin-top: 0px;
  }
  li {
    margin: 10px 0px 10px 0px;
    font-size: 14px;
    margin-left: -5px;
  }
  li:nth-child(n) {
    margin-bottom: 0px;
  }
  span {
    margin: 6px 0px 6px 0px;
    font-size: 14px;
    margin-bottom: 3px;
  }
  ${prop => prop.customCss}
  @media(max-width : 768px) {
    p {
      font-size: 14px;
    }
    span {
      font-size: 13px;
      margin-bottom: 3px;
    }
    li {
      font-size: 13px;
    }
  }
  div {
    width: 150px;
    height: 100px;
    background: transparent;
    position: absolute;
    z-index: 2;
    margin-top: -20px;
    ol {
      display: none;
    }
    &:hover {
      width: auto;
      height: auto;
      position: relative;
      margin-top: 0px;
      ol {
        display: block;
      }
    }
  }
`;

export const FollowComp = styled.span`
  display: flex;
  gap: 15px;
  align-items: top;
`;

export const LogoImg = styled.div`
  padding-left: 10%;
  padding-top: 40px;
  img {
    width: 200px;
  }
`;

export const UpperContainerFoot = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(55, 77, 219);
`;
