import { SHADES } from "src/styles/styleGuide";
import styled from "styled-components";

function Backdrop({ bgColor, onClick, zIndex = 999 }) {
  return (
    <BackdropContainerStyled
      bgColor={bgColor}
      onClick={onClick}
      zIndex={zIndex}
    />
  );
}

const BackdropContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ bgColor }) => bgColor || SHADES.LIGHT_GRAY + "77"};
  z-index: ${({ zIndex }) => zIndex};
`;
export default Backdrop;
