import { images } from "src/assets/logos/logo";
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_ROW, FLEX_ROW_CENTER } from "src/styles/styleGuide";
import styled from "styled-components";

export default function ICLogoBase({ alias, width = 100, ...props }) {
  function isSquaredImage(width) {
    // this is an array of all the insurance companies that have squared logos.
    const icSizesWiseNames = {
      squared: ["aditya_birla", "hdfc_ergo", "raheja_qbe_general", "tata_aig"],
      smallSquaredBigRec: ["reliance_general", "care_health", "go_digit"],
    };

    if (icSizesWiseNames.squared.includes(alias)) {
      return (40 / 100) * width; //40% of total width
    }
    if (icSizesWiseNames.smallSquaredBigRec.includes(alias)) {
      return (60 / 100) * width; //60% of total width
    }

    return (80 / 100) * width; //80% of total width
  }

  return (
    <ImageContainerStyled
      isSquaredImage={isSquaredImage}
      width={width}
      {...props}
    >
      <img src={images[alias]} alt={alias} loading="lazy" />
    </ImageContainerStyled>
  );
}

const ImageContainerStyled = styled.div`
  padding-right: 0.3rem;
  position: relative;
  ${FLEX_ROW};

  ${VIEW.md} {
    padding-right: 0.5rem;
    ${FLEX_ROW_CENTER}
  }
  img {
    object-fit: contain;
    width: ${({ isSquaredImage, width }) => isSquaredImage(width)}px;
    mix-blend-mode: multiply;
  }
`;
