/* READ-IT ===

This file contains media queries for different viewport.
There are five breakpoints by default, inspired by common device resolutions: 

[==== WORKING MOBILE-FIRST =====]

eg: What this means is that utilities (like `capitalize`) take effect on all screen sizes, 
while prefixed utilities (like view_md{ text-transform: capitalize;}) only take effect at the 768px breakpoint and above.

*/

const VIEW = {
  xxl: `@media (min-width: 1536px)`,
  xl: `@media (min-width: 1280px)`,
  lg: `@media (min-width: 1024px)`,
  md: `@media (min-width: 768px)`,
  sm: `@media (min-width: 640px)`,
  xs: `@media (min-width: 360px)`,
};

// Invert the above property
const MAX = {
  xxl: `@media not all and (min-width: 1536px)`,
  xl: `@media not all and (min-width: 1280px)`,
  lg: `@media not all and (min-width: 1024px)`,
  md: `@media not all and (min-width: 768px)`,
  sm: `@media not all and (min-width: 640px)`,
  xs: `@media not all and (min-width: 360px)`,
};

const DEVICES = {
  mobile: 360,
  tabletSM: 640,
  tabletBIG: 768,
  laptopSM: 1024,
  laptopBIG: 1280,
  desktop: 1536,
};

// Return object
export { VIEW, MAX, DEVICES };
