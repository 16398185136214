import { useState } from "react";
import { useTheme } from "src/customHooks";
// UTILS
import { effectiveStringCutter } from "src/utils/string.utility";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import { RiderDescriptionWrapperStyled } from "./RiderSection/RiderCard/RiderCard.style";

export default function Description({ description }) {
  const { colors } = useTheme();
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  const handleShowMore = evt => {
    evt.stopPropagation();
    toggleShowMore();
  };

  return (
    <RiderDescriptionWrapperStyled>
      <div>
        {showMore ? (
          description.includes("\r\n") ? (
            description.split("\r\n").map((desc, idx) => (
              <>
                <FHTypography
                  variant="subheading1"
                  weight="base"
                  colorType="subHeading"
                  key={idx}
                >
                  {desc}
                </FHTypography>
                <br />
              </>
            ))
          ) : (
            <FHTypography
              variant="subheading1"
              weight="base"
              colorType="subHeading"
            >
              {description}
            </FHTypography>
          )
        ) : (
          <FHTypography
            variant="subheading1"
            weight="base"
            colorType="subHeading"
          >
            {effectiveStringCutter({
              string: description,
              approxLength: 80,
              endOfStringAs: "dotted",
            })}
          </FHTypography>
        )}
      </div>
      {description?.length > 80 && (
        <button onClick={handleShowMore}>
          <FHTypography
            variant="subheading1"
            weight="md"
            color={colors.primary_color}
          >
            {showMore ? "Read less" : "Read more"}
          </FHTypography>
        </button>
      )}
    </RiderDescriptionWrapperStyled>
  );
}
