import { useEffect, useState } from "react";
import { FaChevronUp } from "react-icons/fa";
import FloatButton from "../Button/FloatButton";

const ScrollToTopBtn = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (scrollPosition < 500) return <></>;
  return (
    <FloatButton onClick={handleClick}>
      <FaChevronUp />
    </FloatButton>
  );
};

export default ScrollToTopBtn;
