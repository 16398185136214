import { getTenantDisplayName } from "src/utils/tenantSettings";

const BLOCK_JOURNEY = {
  title: "Access Control Error!",
  description: "Please do login to access journey.",
  buttonText: "Login",
};

const SERVICE_UNAVAILABLE = {
  title: "Service temporarily Unavailable! ",
  description: "",
  buttonText: "Reload",
  href: window.location.origin,
};

const POS_MOBILE_VALIDATION = {
  title: "Customer mobile number should be different from POS.",
  description: "",
  buttonText: "Retry",
  href: "",
};

const POS_TOKEN_EXPIRES = {
  title: "Token Expired!",
  description: "Please do re-generate token to access journey.",
  buttonText: "Generate",
  href: "",
};

const REDIRECT_OUTSIDE = {
  title: "Please note",
  description: `You are being redirected to the insurer's website. ${getTenantDisplayName(
    process.env.REACT_APP_TENANT,
  )} has limited control over third-party websites, we prioritize the privacy and security of your information. Please proceed with utmost care when sharing your details.`,
  buttonText: "Okay",
  href: "",
  openInNewTab: true,
  onClick: () => {},
  hideCloseButton: false,
};

// Explain group splitting
const GROUP_SPLIT_ANSWER = {
  title: "Advantages of Splitting Health Insurance",
  description:
    "We take various factors into account, such as your age, medical history, and preferred coverage options, to determine the most suitable premium for you. This personalized pricing approach ensures that you're paying a fair amount for the coverage you require, helping you optimize your costs.",
  buttonText: "OK",
  href: "",
  position: "top",
};

export {
  BLOCK_JOURNEY,
  SERVICE_UNAVAILABLE,
  POS_MOBILE_VALIDATION,
  GROUP_SPLIT_ANSWER,
  POS_TOKEN_EXPIRES,
  REDIRECT_OUTSIDE,
};
