import ReactDOM from "react-dom";
import {
  FLEX_ROW_CENTER,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components/macro";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";
import { MAX, VIEW } from "src/styles/responsiveStyle";
import FHTypography from "src/styles/Typography";
import useHandleBodyScroll from "src/customHooks/useHandleBodyScroll";
import CustomToolTip from "../../CustomTooltip";

const Modal = ({
  isModalOpen = false,
  onClose = () => {},
  width = null,
  height = null,
  mobileWidth = null,
  mobileHeight = null,
  hideCloseBtn = false,
  children,
  title = "",
  titleTooltip = "",
}) => {
  const { hideBodyOverflow, unsetBodyOverflow } = useHandleBodyScroll();

  useEffect(() => {
    // Disable scrolling when modal is open
    if (isModalOpen) {
      hideBodyOverflow();
    } else {
      unsetBodyOverflow();
    }

    return () => {
      unsetBodyOverflow();
    };
  }, [isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <BackdropStyled />
      <ModalWrapper
        width={width}
        height={height}
        mobileWidth={mobileWidth}
        mobileHeight={mobileHeight}
        data-modal-header={Boolean(title.length)}
        titileLength={title.length}
      >
        <ModalHeader titleLength={title.length}>
          {title && (
            <FHTypography className="title" variant="h5" weight="semi">
              <CustomToolTip
                description={titleTooltip}
                disable={!titleTooltip.length}
                showInfoIcon={titleTooltip.length}
              >
                {title}{" "}
              </CustomToolTip>
            </FHTypography>
          )}

          {hideCloseBtn ? null : (
            <FaTimes className="close_btn" onClick={onClose} />
          )}
        </ModalHeader>
        {children}
      </ModalWrapper>
    </>,
    document.getElementById("modal-root"),
  );
};

export default Modal;

export const BackdropStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: ${props => props.width || "50%"}; // Default width if not provided
  height: ${props => props.height || "50%"}; // Default height if not provided
  padding: 20px;
  box-shadow: ${SHADOW.md};
  border-radius: ${ROUNDED.lg};
  z-index: 1000;
  overflow: auto;

  &[data-modal-header="true"] {
    &:after {
      content: "";
      position: absolute;
      top: 60px;
      left: 0;
      height: 0.7px;
      width: 100%;
      background-color: #dee2e6;
      @media screen and (max-width: 410px) {
        top: ${props => (props.titileLength > 75 ? "80px" : "60px")};
      }
    }
  }

  ${MAX.md} {
    width: ${props =>
      props.mobileWidth || "90%"}; // Default width if not provided
    height: ${props =>
      props.mobileHeight || "90%"}; // Default height if not provided
  }
`;

export const ModalHeader = styled.section`
  ${FLEX_ROW_CENTER};
  justify-content: ${({ titleLength }) => {
    return titleLength ? "space-between" : "flex-end";
  }};
  margin-bottom: ${({ titleLength }) => (titleLength ? "30px" : "0")};

  .close_btn {
    cursor: pointer;
    font-size: 0.8rem;
    color: ${SHADES.HEADING};

    ${VIEW.md} {
      font-size: 1.2rem;
    }
  }
`;
