import styled from "styled-components";
// DESIGN SYSTEM
import {
  FLEX_COL,
  FLEX_ROW_CENTER,
  SHADES,
  ROUNDED,
  SHADOW,
  FLEX_ROW,
  FLEX_COL_CENTER,
} from "src/styles/styleGuide";
import FHTypography from "src/styles/Typography";
import { MAX, VIEW } from "src/styles/responsiveStyle";

// DownloadCard
const DownloadTabWrapperStyled = styled.div`
  ${FLEX_COL}
  gap:1rem;
`;

const DownloadTabStyled = styled.div`
  ${FLEX_ROW_CENTER}
  justify-content: space-between;
  padding: 0.75rem;
  background-color: ${SHADES.WHITE};
  border-radius: ${ROUNDED.md};

  a {
    ${FLEX_ROW_CENTER}
    background-color:${SHADES.LIGHT_GRAY};
    width: 2.25rem;
    height: 2.25rem;
    border-radius: ${ROUNDED.full};

    svg {
      font-size: 1.25rem;
      color: ${props => props.colors.primary_color};
    }
  }
`;

const DownloadCardContainerStyled = styled.div`
  ${FLEX_COL}
  padding:1rem;
  gap: 1.25rem;
  border-radius: ${ROUNDED.md};
  border: 1px solid ${SHADES.WHITE};
  box-shadow: ${SHADOW.lg};
  margin-top: 1rem;
`;

// FeatureDefinition
const FeatureDefinitionContainer = styled.div`
  background-color: #eaeef2bf;
  padding: 1rem;
  border-radius: ${ROUNDED.lg};
  display: flex;
  flex-flow: column nowrap;
  cursor: pointer;
`;

const FeatureNameWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  .toggle_icon {
    transform: ${props => {
      return props.toggle ? "rotate(180deg)" : "rotate(0deg)";
    }};
    font-size: ${props => {
      return props.toggle ? "1.35rem" : "1.25rem";
    }};
    transition: all 250ms ease-out;
    transform-origin: center;
  }
`;

const FeatureDescriptionWrapperStyled = styled.div`
  display: grid;
  grid-template-rows: ${props => {
    return props.toggle ? "1fr" : "0fr";
  }};
  margin-top: 0.25rem;
  overflow: hidden;
  transition: grid-template-rows 250ms ease-out;
`;

const FeatureDescriptionStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading2",
  weight: "base",
  colorType: "subHeading",
}))`
  line-height: 18px;
  width: 100%;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
`;

// FeatureDefinitionMobile
const FeatureDefinitionStyled = styled.div`
  ${FLEX_COL}
  gap:0.5rem;
  margin-top: 0.75rem;
  border-top: 1px solid lightgray;
  padding-top: 1rem;
`;

const TabCardStyled = styled.div`
  ${FLEX_ROW}
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .info-wrapper {
    ${FLEX_COL}
    align-items: flex-start;
    flex: 1;
    gap: 0.25rem;
  }
`;

const TabHeaderStyled = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "md",
  colorType: "heading",
}))``;

const TabDescriptionStyled = styled(FHTypography).attrs(() => ({
  variant: "subheading1",
  weight: "base",
  colorType: "subHeading",
}))``;

const TabToggleButtonStyled = styled.button`
  padding: 0.5rem;
  border-radius: ${ROUNDED.full};
  transition: all 200ms ease-in-out;

  svg {
    font-size: 1.125rem;
  }

  &[data-tab-open="true"] {
    svg {
      rotate: 180deg;
    }
  }
`;

const TabContentWrapperStyled = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: all 250ms ease-out;

  & > div {
    overflow: hidden;
  }

  &[data-tab-open="true"] {
    grid-template-rows: 1fr;
  }
`;

// FeatureTab
const FeatureTabContainer = styled.div`
  ${FLEX_COL}
  gap:1rem;
`;

const FeaturesTabListStyled = styled.li`
  border-radius: 3px;
  padding: 5px 8px;
  list-style-type: ${props => (props?.isSingle ? "none" : "disc")};
  &::marker {
    font-size: 14px;
  }
`;

// PlanDetails/index
const PlanDetailsWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  ${VIEW.lg} {
    grid-template-columns: 1fr 1fr;
  }
  height: 60vh;
`;

const FeatureListContainerStyled = styled.div`
  ${FLEX_COL}
  gap:2rem;
  height: 100%;
  overflow-y: auto;
  border-radius: 10px;
  scrollbar-width: none;
`;

const FeatureListWrapperStyled = styled.div`
  ${FLEX_COL}
  gap:1rem;
`;

const FeatureDefinitionWrapperStyled = styled.div`
  ${FLEX_COL}
  gap:1rem;
  height: 100%;
  overflow-y: auto;
  ${MAX.lg} {
    display: none;
  }
  scrollbar-width: none;
`;

// PlanTab
const PlansTabStyled = styled.a`
  border-bottom: 1px solid #eee !important;
  border-radius: 0.375rem;
  height: 104px;
  margin: 8px 0px;
  padding: 1rem;

  .plans-title {
    margin-bottom: 0.5rem;
  }

  &[data-tab-active="true"] {
    color: unset !important;
    background-color: unset !important;
    position: relative;
    border: 1px solid #eee !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    .plans-title {
      color: ${props => props.colors.primary_color};
    }
  }
`;

// PlanTabMobile
const PlanItemTitle = styled(FHTypography).attrs(() => ({
  variant: "h4",
  weight: "semi",
}))``;

const PlanItemDescription = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "base",
  colorType: "heading",
}))``;

const PlanTabMobileWrapperStyled = styled.div`
  ${FLEX_COL}
  gap :0.5rem;
  border: 1px solid ${SHADES.LIGHT_GRAY};
  border-radius: ${ROUNDED.lg};
  padding: 1.125rem;

  &[data-tab-open="true"] {
    border-color: ${props => props.activeBorderColor};
  }
`;

const MobileTabCardStyled = styled.div`
  ${FLEX_ROW}
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .info-wrapper {
    ${FLEX_COL}
    align-items: flex-start;
    flex: 1;
    gap: 1.5rem;
  }
`;

const MobileTabToggleButtonStyled = styled.button`
  ${FLEX_COL_CENTER}
  width:1.75rem;
  height: 1.75rem;
  background-color: ${SHADES.WHITE};
  border-radius: ${ROUNDED.full};
  transition: all 200ms ease-in-out;

  svg {
    font-size: 1.125rem;
  }

  &[data-tab-open="true"] {
    background-color: ${SHADES.LIGHT_GRAY};
    rotate: 180deg;
  }
`;

const MobileTabContentWrapperStyled = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: all 250ms ease-out;
  .lists {
    font-size: 11px;
    border-radius: 3px;
    padding: 3px;
    line-height: 1.6;
    list-style-type: disc;
    text-indent: -0.8em;
    &::marker {
      font-size: 14px;
    }
    &:hover {
      background-color: #de9b9e29;
    }
  }

  & > div {
    overflow: hidden;
  }

  &[data-tab-open="true"] {
    grid-template-rows: 1fr;
  }
`;

export {
  // DownloadCard
  DownloadTabWrapperStyled,
  DownloadTabStyled,
  DownloadCardContainerStyled,
  // FeatureDefinition
  FeatureDefinitionContainer,
  FeatureDescriptionStyled,
  FeatureDescriptionWrapperStyled,
  FeatureNameWrapperStyled,
  // FeatureDefinitionMobile
  FeatureDefinitionStyled,
  TabCardStyled,
  TabContentWrapperStyled,
  TabDescriptionStyled,
  TabHeaderStyled,
  TabToggleButtonStyled,
  // FeatureTab
  FeatureTabContainer,
  FeaturesTabListStyled,
  // PlanDetails/index
  FeatureDefinitionWrapperStyled,
  FeatureListContainerStyled,
  FeatureListWrapperStyled,
  PlanDetailsWrapperStyled,
  // PlanTab
  PlansTabStyled,
  //   PlanTabMobile
  MobileTabCardStyled,
  MobileTabContentWrapperStyled,
  MobileTabToggleButtonStyled,
  PlanItemDescription,
  PlanItemTitle,
  PlanTabMobileWrapperStyled,
};
