import FHTypography from "src/styles/Typography";
import { Button } from "../Common/Button/ContinueButton";
import { useDispatch } from "react-redux";
import { useGetCartQuery } from "src/api/api";
import { setCartSummaryModalAction } from "src/slices/utility.slice";
import { VIEW } from "src/styles/responsiveStyle";
import styled from "styled-components/macro";
import { FLEX_COL, SHADES } from "src/styles/styleGuide";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
import { plantypes } from "src/pages/ProductDetails/components/ReviewCart/components";
import { numToLakh } from "src/utils/string.utility";
import { useCompanies } from "src/customHooks";
import { useEventTracking } from "src/customHooks/useEventTracking";

function CartSummaryFooter({ onContinue, ...props }) {
  const dispatch = useDispatch();
  const { refetch: refetchEnquiryQuery } = useGetEnquiry();

  const { journeyType } = useFrontendBoot();

  const { getCompanyLogo } = useCompanies();

  const { data, isLoading, isUninitialized } = useGetCartQuery();
  const { webEngageTrackEvent } = useEventTracking();
  if (isLoading || isUninitialized || !data) return null;

  // filtering cart entries by removing empty entries
  const cartEntriesList =
    data?.data?.filter(cart => !Array.isArray(cart)) || [];

  if (!cartEntriesList.length) return null;
  const totalPremium = cartEntriesList
    .map(
      entry =>
        +entry.total_premium_with_mandatory_riders || entry.total_premium,
    )
    .reduce((total, currentValue) => total + currentValue, 0);

  function handleContinueClick() {
    let planDetails = [];

    cartEntriesList.forEach(quote => {
      const groupObj = {
        "Insurer Name": quote?.product?.company?.name,
        "Insurer Logo": getCompanyLogo(quote?.product?.company?.alias),
        Cover: numToLakh(quote?.sum_insured),
        "Yearly Premium incl GST": quote?.total_premium,
      };
      planDetails.push(groupObj);
    });

    webEngageTrackEvent("Health Plan Buy Started", {
      "Plan Name": cartEntriesList
        ?.map(quote => quote?.product?.name)
        .join(", "),
      "Insurer Name": cartEntriesList
        ?.map(quote => quote?.product?.company?.name)
        .join(", "),
      "Policy Type": cartEntriesList
        ?.map(quote => plantypes[quote?.group?.plan_type])
        .join(", "),
      "Insurance Cover": cartEntriesList
        ?.map(quote => numToLakh(quote?.sum_insured))
        ?.join(", "),
      "Premium incl GST": cartEntriesList.reduce(
        (sum, policy) => sum + policy?.total_premium,
        0,
      ),
      "Plan Type": cartEntriesList
        ?.map(() => (journeyType === "health" ? "Base Health" : "Top Up"))
        .join(", "),
      "Plan Details": planDetails,
    });
    dispatch(setCartSummaryModalAction(false));
    // refetch enquiry to update cart item in enquiry data
    refetchEnquiryQuery();
    onContinue && onContinue();
  }

  return (
    <FooterContainerStyled {...props}>
      <TotalPremiumContainerStyled>
        <div>
          <FHTypography variant="subheading1" weight="md" colorType="body">
            Total Premium :
          </FHTypography>
          <FHTypography
            variant="body1"
            weight="md"
            className="incl-gst"
            colorType="body"
          >
            Incl. GST
          </FHTypography>
        </div>
        <FHTypography variant="h4" weight="md">
          ₹ {parseInt(totalPremium).toLocaleString("en-IN")}
        </FHTypography>
      </TotalPremiumContainerStyled>

      <Button
        font="h5"
        className="cartSummary_submitButton"
        onClick={handleContinueClick}
      >
        Continue
      </Button>
    </FooterContainerStyled>
  );
}

const FooterContainerStyled = styled.div`
  ${FLEX_COL};
  margin-top: 30px;
  .cartSummary_submitButton {
    width: 100%;
    ${VIEW.md} {
      width: 150px;
      height: 45px;
    }
  }
  ${VIEW.md} {
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px dashed ${SHADES.LIGHT_GRAY};
    padding-top: 10px;
  }
`;

const TotalPremiumContainerStyled = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  ${VIEW.md} {
    width: max-content;
  }
  .incl-gst {
    display: inline-block;
    border-bottom: 2px dashed ${SHADES.BODY};
  }
`;

export default CartSummaryFooter;
