import { Page } from "src/components/Page";
import InputPageInner from "./InputPage";

const InputPage = () => {
  return (
    <Page id="input-page">
      <InputPageInner />
    </Page>
  );
};

export default InputPage;
