import { useRouteMatch } from "react-router-dom";

function useGetRouteMatch() {
  const isRootRoute = useRouteMatch({ path: "/", exact: true });
  const isQuoteRoute = useRouteMatch({ path: "/quotes" });
  const isProductDetailsRoute = useRouteMatch({ path: "/productdetails" });
  const isProposalRoute = useRouteMatch({ path: "/proposal" });
  const isProposalSummaryRoute = useRouteMatch({ path: "/proposal_summary" });
  const isCompareRoute = useRouteMatch({ path: "/compare" });
  const isThankYouRoute = useRouteMatch({ path: "/thankyou" });
  const isRecommendRoute = useRouteMatch({ path: "/recommend" });
  const isRecommendResultRoute = useRouteMatch({ path: "/recommend-result" });
  const isCustomPlanRoute = useRouteMatch({ path: "/custom_plan" });
  const isShortlistRoute = useRouteMatch({ path: "/shortlisted" });
  const isPaymentFailureRoute = useRouteMatch({ path: "/payment-failure" });
  const isInputPagesRoute = useRouteMatch({ path: "/input" });
  const isBasicDetailsRoute = useRouteMatch({
    path: "/input/basic-details",
  });
  const isInputMembersRoute = useRouteMatch({
    path: "/input/members",
  });

  const isPortabilityRoute = useRouteMatch({
    path: "/input/portability",
    exact: true,
  });

  const isRenewalDetailsRoute = useRouteMatch({
    path: "/input/renewal-details",
    exact: true,
  });

  const isChooseYourJourneyPage = useRouteMatch({
    path: "/choose-your-journey",
    exact: true,
  });
  return {
    isRootRoute,
    isInputPagesRoute,
    isBasicDetailsRoute,
    isInputMembersRoute,
    isPortabilityRoute,
    isRenewalDetailsRoute,
    isChooseYourJourneyPage,
    isQuoteRoute,
    isProductDetailsRoute,
    isProposalRoute,
    isProposalSummaryRoute,
    isCompareRoute,
    isThankYouRoute,
    isRecommendRoute,
    isRecommendResultRoute,
    isShortlistRoute,
    isCustomPlanRoute,
    isPaymentFailureRoute,
  };
}

export default useGetRouteMatch;
