import { useSelector } from "react-redux";

// REACT ICONS
// import { TbDiscount2 } from "react-icons/tb";
import { IoArrowBackCircleSharp } from "react-icons/io5";
// CUSTOM HOOKS
import useIsPos from "src/pos/useIsPos";
import { useCompanies, useTheme } from "src/customHooks";
import useGetRouteMatch from "src/customHooks/useGetRouteMatch";
import {
  useFrontendBoot,
  useGetEnquiry,
} from "src/customHooks/useGetCommonQueries";
// UTILS
import { getDisplayPremium } from "src/utils/string.utility";
import { getSumInsuredOptions } from "./DesktopSeeDetailsHeader";
import { matchCurrentRoute } from "src/utils/productDetailsPageUtils";
import { calculateTotalPremium, figureToWords } from "src/utils/helper";
// DESIGN SYSTEM
import FHTypography from "src/styles/Typography";
// STYLED COMPONENTS
import {
  QuoteDetailLoader,
  MobileSeeDetailsNav,
  MobileSeeDetailsHeaderStyled,
  ProductHeaderWrapperStyled,
  ICInfoWrapper,
  ICDetailsWrapper,
  ICDetailStyled,
  ICDetailName,
  ICDetailValue,
  PremiumWrapperStyled,
} from "./SeeDetailsHeader.style";
// CUSTOM COMPONENTS
import ICLogoBase from "src/components/Common/ICLogo";
import QuoteCardSelect from "src/pages/quotePage/components/QuoteCard/QuoteCardSelect";
import CustomToolTip from "src/components/Common/CustomTooltip";
// CONSTANTS
import { plantypes } from "src/pages/ProductDetails/components/ReviewCart/components";
// import useEffectiveCostModal from "src/pages/ProductDetails/Hooks/useEffectiveCostModal";

export default function MobileSeeDetailsHeader({
  quote,
  isLoading,
  sumInsuredChangeHandler,
  deductibleChangeHandler,
  handleClose,
}) {
  // const [effectiveCost, setEffectiveCost] = useState([]);
  const { colors } = useTheme();
  const { isPosJourney } = useIsPos();
  const { getCompany } = useCompanies();
  const { currentGroupObject } = useGetEnquiry();
  // const { currentTaxSlab, updateTaxSlab } = useEffectiveCostModal();
  const { isQuoteRoute } = useGetRouteMatch();
  const { riders = [] } = useSelector(state => state.productPage);
  const selectedRiders = riders?.filter(rider => rider.isSelected) || [];
  const { ridersLoading } = useSelector(({ productPage }) => productPage);
  const isOnProposalPageOrLater = matchCurrentRoute({
    matcher: "gte",
    route: "proposal",
  });
  const {
    journeyType,
    data: {
      settings: { restrict_posp_quotes_after_limit, block_csr_visibility },
    },
    // costeffectivecalculation = {},
  } = useFrontendBoot();

  // VARIABLE/s
  let deductibleOptions = [];
  let sumInsuredOptions = [];
  const {
    product: { company, name },
    sum_insured,
    total_premium,
    tenure,
    deductible,
    available_sum_insureds = [],
    available_sum_insured_deductibles = [],
  } = quote;

  const { csr } = getCompany(company.alias);

  const netPremium = calculateTotalPremium(
    {
      total_premium,
      health_riders: selectedRiders,
      top_up_riders: selectedRiders,
    },
    {},
    journeyType,
  );

  if (journeyType === "top_up") {
    const allDeductibles = available_sum_insured_deductibles?.map(
      item => item.deductible,
    );
    const uniqueDeductibles = [...new Set(allDeductibles)].sort(
      (a, b) => a - b,
    );

    deductibleOptions = getSumInsuredOptions(uniqueDeductibles);

    sumInsuredOptions = getSumInsuredOptions(
      available_sum_insured_deductibles
        .filter(item => item.deductible === deductible)
        .map(item => item.sum_insured),
    );
  } else {
    sumInsuredOptions = getSumInsuredOptions(available_sum_insureds);
  }

  if (isPosJourney && restrict_posp_quotes_after_limit === `${1}`) {
    sumInsuredOptions = sumInsuredOptions?.filter(si => si.value < 500001);
  }

  // comment out code for not showing effective cost right now
  // useEffect(() => {
  //   if (netPremium) {
  //     let effectiveCostObj = [
  //       {
  //         label: `${amount(netPremium - (netPremium / 100) * 5)} (5%)`,
  //         value: 5,
  //       },
  //       {
  //         label: `${amount(netPremium - (netPremium / 100) * 20)} (20%)`,
  //         value: 20,
  //       },
  //       {
  //         label: `${amount(netPremium - (netPremium / 100) * 31)} (31%)`,
  //         value: 31,
  //       },
  //     ];
  //     setEffectiveCost(effectiveCostObj);
  //   }
  // }, [netPremium]);

  return (
    <MobileSeeDetailsHeaderStyled>
      <MobileSeeDetailsNav primary_color={colors.primary_color}>
        <IoArrowBackCircleSharp onClick={handleClose} />
        <FHTypography variant="h4" weight="md">
          See Details
        </FHTypography>
      </MobileSeeDetailsNav>

      <ProductHeaderWrapperStyled>
        <ICInfoWrapper>
          <ICLogoBase alias={company?.alias} />
          <FHTypography variant="h4" weight="semi" colorType="heading">
            {name}
          </FHTypography>
        </ICInfoWrapper>

        <ICDetailsWrapper>
          {journeyType === "top_up" && (
            <ICDetailStyled>
              <ICDetailName>Deductible</ICDetailName>
              <ICDetailValue>
                {isLoading ? (
                  <QuoteDetailLoader />
                ) : deductibleOptions?.length === 0 || !isQuoteRoute ? (
                  <ICDetailValue>₹ {figureToWords(deductible)}</ICDetailValue>
                ) : (
                  <QuoteCardSelect
                    options={deductibleOptions}
                    defaultValue={{
                      value: +deductible,
                      label: `₹ ${figureToWords(deductible)}`,
                    }}
                    onChange={deductibleChangeHandler}
                  />
                )}
              </ICDetailValue>
            </ICDetailStyled>
          )}

          <ICDetailStyled>
            <ICDetailName>Cover</ICDetailName>
            {isLoading ? (
              <QuoteDetailLoader />
            ) : sumInsuredOptions?.length === 0 || !isQuoteRoute ? (
              <ICDetailValue>₹ {figureToWords(sum_insured)}</ICDetailValue>
            ) : (
              <QuoteCardSelect
                options={sumInsuredOptions}
                defaultValue={{
                  value: +sum_insured,
                  label: `₹ ${figureToWords(sum_insured)}`,
                }}
                fontSize="10px"
                onChange={sumInsuredChangeHandler}
              />
            )}
          </ICDetailStyled>

          <PremiumWrapperStyled>
            <ICDetailStyled>
              <ICDetailName>Premium</ICDetailName>
              {ridersLoading || isLoading ? (
                <QuoteDetailLoader />
              ) : (
                <ICDetailValue>
                  {getDisplayPremium({ total_premium: netPremium, tenure })}
                </ICDetailValue>
              )}
            </ICDetailStyled>
            {/* {effectiveCost.length > 0 &&
              Object.keys(costeffectivecalculation).length > 0 &&
              Object.values(costeffectivecalculation).some(
                costEffectiveValue => costEffectiveValue,
              ) && (
                <ICDetailStyled>
                  <ICDetailName>
                    Effective Cost
                    <CustomToolTip
                      description={`Under Section 80D You can get tax benefit as per the tax slab of 5%, 20%or 31% . To know more benefits please proceed to product details. By clicking on premium amount.`}
                      showInfoIcon={false}
                    >
                      <span>
                        <TbDiscount2 size={12} className="info_icon" />
                      </span>
                    </CustomToolTip>
                  </ICDetailName>
                  <QuoteCardSelect
                    value={effectiveCost.find(
                      item => item.value === currentTaxSlab,
                    )}
                    options={effectiveCost}
                    onChange={e => updateTaxSlab(e.value)}
                  />
                </ICDetailStyled>
              )} */}
          </PremiumWrapperStyled>
          {+block_csr_visibility === 0 && (
            <ICDetailStyled>
              <ICDetailName>
                CSR &nbsp;
                <CustomToolTip
                  description=" Claim Settlement Ratio of an Insurer is the number of
                      claims settled against the number of claims filed."
                  showInfoIcon={true}
                ></CustomToolTip>
              </ICDetailName>
              <ICDetailValue>{csr}%</ICDetailValue>
            </ICDetailStyled>
          )}
          <ICDetailStyled>
            <ICDetailName>Policy Type</ICDetailName>
            <ICDetailValue>
              {
                plantypes[
                  isOnProposalPageOrLater
                    ? quote?.group.plan_type
                    : currentGroupObject?.plan_type
                ]
              }
            </ICDetailValue>
          </ICDetailStyled>
        </ICDetailsWrapper>
      </ProductHeaderWrapperStyled>
    </MobileSeeDetailsHeaderStyled>
  );
}
