import FHTypography from "src/styles/Typography";
import { MAX, VIEW } from "src/styles/responsiveStyle";
import {
  FLEX_COL,
  FLEX_ROW,
  FLEX_ROW_CENTER,
  ROUNDED,
  SHADES,
  SHADOW,
} from "src/styles/styleGuide";
import styled from "styled-components";

const CashlessHospitalStyled = styled.div`
  ${FLEX_COL}
  gap:1.5rem;
`;
const CashlessEverywhereWrapper = styled.div`
  padding: 0.5rem;
  border: 1px solid ${props => props.colors.primary_color};
  border-radius: ${ROUNDED.md};
  background-color: ${props => props.colors.primary_shade};

  .link {
    color: ${props => props.colors.primary_color};
    border-bottom: 1px solid ${props => props.colors.primary_color};
  }

  .sectionwrapper {
    ${FLEX_ROW_CENTER}
    justify-content: left;
    gap: 0.5rem;
    position: relative;
    flex-shrink: 0;
  }

  .announceicon {
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    font-size: 1rem;
    opacity: 0.6;
  }

  ${VIEW.md} {
    svg {
      font-size: 1rem;
    }
  }

  ${MAX.md} {
    margin-bottom: 1rem;
  }
`;

const CashlessViewDetailsWrapper = styled.div`
  background-color: white;
  border-radius: ${ROUNDED.md};

  padding: 1rem;
  margin-top: 1rem;
  &[data-show-as-modal="true"] {
    padding: 0;
    margin-top: 0;

    .heading {
      color: ${props => props.colors.primary_color};
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid ${SHADES.LIGHT_GRAY};
    }
  }
  .heading {
    color: ${props => props.colors.primary_color};
    margin-bottom: 1.5rem;
  }

  .steps {
    margin-bottom: 1.5rem;
  }

  .question {
    margin-bottom: 0.5rem;
  }

  ul {
    padding: 0 1rem;
  }

  ul li {
    list-style-type: disc;
    margin-bottom: 0.5rem;
  }

  .subheading {
    margin-bottom: 1rem;
  }

  .anchor {
    font-weight: normal;
    color: ${props => props.colors.primary_color};
  }
`;

const HospitalsContainerStyled = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  ${VIEW.lg} {
    grid-auto-rows: 140px;
  }
`;

const InfoHeaderStyled = styled(FHTypography).attrs(() => ({
  variant: "h5",
  weight: "md",
  colorType: "white",
}))`
  padding: 0.75rem 1rem;
  border-radius: ${ROUNDED.md};
  background-color: ${props => props.colors.primary_color};
`;

const NoHospitalsText = styled(FHTypography).attrs(() => ({
  variant: "h5",
  weight: "md",
  colorType: "heading",
}))`
  padding: 0.75rem 1rem;
`;

const NetworkHospitalWrapperStyled = styled.div`
  padding: 20px;
  background: transparent;
`;

//  Hospital Card styled
const HospitalsDisplayContainerStyled = styled.div`
  ${FLEX_COL}
  gap:1rem;
`;
const HospitalCardStyled = styled.div`
  ${FLEX_COL}
  gap:0.5rem;
  height: 100%;
  min-height: fit-content;
  padding: 0.75rem;
  border-radius: ${ROUNDED.md};
  box-shadow: ${SHADOW.lg};
  border: 1px solid ${SHADES.LIGHT_GRAY};

  .address {
    flex: 1;
  }
  ${VIEW.lg} {
    padding: 0.5rem;
  }
`;

const HospitalName = styled(FHTypography).attrs(() => ({
  variant: "h6",
  weight: "semi",
  colorType: "heading",
}))``;

const HospitalAddress = styled(FHTypography).attrs(() => ({
  variant: "subheading2",
  weight: "md",
  colorType: "subHeading",
  className: "address",
}))``;

const HospitalPhoneNo = styled(FHTypography).attrs(() => ({
  variant: "subheading2",
  weight: "md",
  colorType: "subHeading",
}))``;

const NetworkHospitalCardStyled = styled.div`
  ${FLEX_ROW}
  align-items:center;
  gap: 1rem;
  max-width: 480px;
  padding: 1.5rem 1rem;
  border: 1px solid ${SHADES.WHITE};
  border-radius: ${ROUNDED.lg};
  box-shadow: ${SHADOW.lg};
  .info-wrapper {
    ${FLEX_COL}
    gap:0.5rem;
    flex: 1;
  }
  a {
    ${FLEX_ROW_CENTER}
    width: 2.5rem;
    height: 2.5rem;
    background: ${SHADES.WHITE};
    border-radius: ${ROUNDED.full};
    :hover {
      background-color: ${SHADES.LIGHT_GRAY};
    }
    svg {
      font-size: 1.25rem;
      color: ${props => props.colors.primary_color};
    }
  }
`;

const SearchBarWrapperStyled = styled.div`
  ${FLEX_ROW}
`;

const CurrentCityStyled = styled(FHTypography).attrs(() => ({
  variant: "h5",
  weight: "md",
}))`
  ${FLEX_ROW_CENTER}
  padding: 0 1.75rem;
  border: 1px solid ${props => props.colors.primary_color};
  border-radius: 0.5rem 0 0 0.5rem;
  color: ${props => props.colors.primary_color};
`;

const SearchBarOuter = styled.div`
  flex: 1;
  padding: 1rem;
  border: 1px solid ${SHADES.LIGHT_GRAY};
  border-radius: 0 0.5rem 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1017px) {
    height: 45px;
  }
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  font-size: 18px;
  width: 90%;
  @media (max-width: 1017px) {
    font-size: 15px;
  }
`;

const InfoStyled = styled.div`
  ${FLEX_ROW}
  width: 100%;
  & > h6 {
    width: 50%;
  }
  > *:last-child {
    text-align: right;
    ${MAX.sm} {
      border-left: 1px solid #384252;
    }
  }
`;

export {
  CashlessHospitalStyled,
  CashlessEverywhereWrapper,
  CashlessViewDetailsWrapper,
  HospitalAddress,
  HospitalCardStyled,
  HospitalName,
  HospitalPhoneNo,
  HospitalsContainerStyled,
  HospitalsDisplayContainerStyled,
  InfoHeaderStyled,
  NetworkHospitalWrapperStyled,
  NoHospitalsText,
  NetworkHospitalCardStyled,
  CurrentCityStyled,
  SearchBarOuter,
  SearchBarWrapperStyled,
  SearchInput,
  InfoStyled,
};
