import { useDispatch, useSelector } from "react-redux";
import { useUpdateShortlistedQuotesMutation } from "src/api/api";
import { replaceShortlistedQuote } from "src/pages/NewQuotePage/QuotePageSlice/quote.slice";

export default function useShortlistedPlans() {
  const dispatch = useDispatch();
  const [updateShortlistedQuotes, result] =
    useUpdateShortlistedQuotesMutation();
  const { shortlistedQuotes } = useSelector(({ quotePage }) => quotePage);

  // Functions
  function getPlanByGroup(groupCode) {
    return shortlistedQuotes
      ?.flat()
      .filter(plan => plan.groupCode === groupCode);
  }

  function addPlanToShortlist(quote) {
    const newQuotes = [quote, ...shortlistedQuotes];
    dispatch(replaceShortlistedQuote(newQuotes));
    updateShortlistedQuotes(newQuotes);
  }

  function removePlanToShortlist(quote) {
    const filteredShortlistedQuotes = shortlistedQuotes.filter(plan => {
      return (
        plan.product?.id !== quote?.product?.id ||
        plan.sum_insured != quote?.sum_insured ||
        plan.tenure !== quote?.tenure
      );
    });
    updateShortlistedQuotes(filteredShortlistedQuotes);
    dispatch(replaceShortlistedQuote(filteredShortlistedQuotes));
  }

  function replaceShortlistedPlans(shortlistedQuotes = []) {
    dispatch(replaceShortlistedQuote(shortlistedQuotes));
    updateShortlistedQuotes(shortlistedQuotes);
  }

  return {
    getPlanByGroup,
    shortlistedQuotes,
    addPlanToShortlist,
    removePlanToShortlist,
    replaceShortlistedPlans,
    isShortlistedLoading: result.isLoading,
  };
}
