import { useDispatch } from "react-redux";
import { setBodyOverflow } from "src/slices/globalComp.slice";

export default function useHandleBodyScroll() {
  const dispatch = useDispatch();

  const hideBodyOverflow = () => {
    dispatch(setBodyOverflow("hidden"));
  };

  const unsetBodyOverflow = () => {
    dispatch(setBodyOverflow("unset"));
  };

  return { hideBodyOverflow, unsetBodyOverflow };
}
