import { createSlice } from "@reduxjs/toolkit";
import { saveProductCart } from "../NewQuotePage/QuotePageSlice/quote.slice";
import { getCartApi } from "./serviceApi";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    totalPremium: 0,
    discounted_total_premium: 0,
  },
  reducers: {
    addQuoteToCart: (state, action) => {
      const { groupCode, product } = action.payload;
      state[groupCode] = { ...product };
    },
    removeQuoteFromCart: (state, action) => {
      const groupCode = action.payload;
      state[groupCode] = null;
    },
    setTotalPremium: (state, action) => {
      state.totalPremium = action.payload;
    },
    setCart: (state, action) => {
      return (state = {
        ...state,
        ...action.payload,
      });
    },
    restoreInitialCart: state => {
      state = {
        ...state,
        totalPremium: 0,
        discounted_total_premium: 0,
      };
      return state;
    },
  },
});

export const {
  addQuoteToCart,
  removeQuoteFromCart,
  setTotalPremium,
  restoreInitialCart,
  setCart,
} = cartSlice.actions;

export const getCart = () => async dispatch => {
  try {
    const { data, statusCode } = await getCartApi();
    if (statusCode === 200) {
      const { discounted_total_premium, total_premium, ...cartData } = data;
      let fetchedCart = {
        discounted_total_premium,
        totalPremium: total_premium,
      };

      cartData.data.forEach(cartItem => {
        const groupCode = cartItem.group?.id;
        fetchedCart[groupCode] = cartItem;
      });

      dispatch(setCart(fetchedCart));
      dispatch(saveProductCart(data.data));
    }
  } catch (error) {
    alert(error);
    console.error(error);
  }
};

export default cartSlice.reducer;
