import { useState } from "react";

export default function useReusableModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  //FUNCTION/s
  function handleOpenReusableModal() {
    setIsModalOpen(true);
  }

  function handleCloseReusableModal() {
    setIsModalOpen(false);
  }
  return { isModalOpen, handleCloseReusableModal, handleOpenReusableModal };
}
