// Elements loading handling using reducer
const initialLoadingState = { email: false, sms: false, whatsapp: false };

function loadingReducer(state, action) {
  switch (action.type) {
    case "email":
      return { ...state, email: action.payload };
    case "sms":
      return { ...state, sms: action.payload };
    case "whatsapp":
      return { ...state, whatsapp: action.payload };
    default:
      return state;
  }
}

function validateElementValue(element, elementValue) {
  switch (element) {
    case "email": {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return emailRegex.test(elementValue);
    }
    case "sms":
    case "whatsapp": {
      const smsRegex = /^[0-9]{10}$/;
      return smsRegex.test(elementValue);
    }
    default:
      return true;
  }
}

export { initialLoadingState, loadingReducer, validateElementValue };
