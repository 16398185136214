import { useState, createRef } from "react";
import { getReactSelectOption } from "../utils/helper";
import Dropdown from "./Dropdown";
import { FaChevronDown } from "react-icons/fa";
import "styled-components/macro";
import CustomToolTip from "./Common/CustomTooltip";
import styled from "styled-components";

const SearchableDropDown = ({
  rawOptions,
  setSelectedOption,
  selectedOption,
  inputType = "text",
  inputCss,
  dropdownCss,
  tooltip_text = "",
  label = "Select",
  isDisabled = false,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const [searchQuery, setSearchQuery] = useState(selectedOption?.value);

  const dropdownRef = createRef();

  const handleDropDownChange = evt => {
    setSelectedOption(evt);
    setSearchQuery(evt.value);
    setShowDropdown(false);
  };

  const handleInputBlur = () => {
    setShowDropdown(false);
  };

  const inputClickHandler = () => {
    if (isDisabled) return;
    setSearchQuery("");
    setShowDropdown(prev => !prev);
  };

  const handleInputChange = e => {
    setSearchQuery(e.target.value);
    setShowDropdown(true);
  };

  return (
    <CustomToolTip description={tooltip_text} showInfoIcon={false}>
      <span>
        <CustomInput
          placeholder={label}
          type={inputType}
          onClick={inputClickHandler}
          autoComplete="off"
          onChange={handleInputChange}
          value={searchQuery}
          inputCss={inputCss}
          onBlur={handleInputBlur}
          isDisabled={isDisabled}
          showDropdown={showDropdown}
        />
        <Dropdown
          hideDefaultControl
          value={
            selectedOption ? getReactSelectOption(selectedOption) : "Select"
          }
          onChange={handleDropDownChange}
          options={rawOptions.map(getReactSelectOption)}
          ref={dropdownRef}
          searchQuery={searchQuery}
          showDropdown={showDropdown}
          dropdownCss={dropdownCss}
        />
      </span>
    </CustomToolTip>
  );
};

export default SearchableDropDown;

const CustomInput = ({
  placeholder,
  type,
  value,
  autoComplete,
  onChange,
  onPaste,
  onClick = () => {},
  onKeyDown,
  onBlur,
  maxLength,
  onKeyPress,
  name,
  inputCss = ``,
  isDisabled = false,
  showDropdown = false,
}) => {
  return (
    <InputWrapper>
      <input
        autoComplete={autoComplete}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onPaste={onPaste}
        onClick={onClick}
        onCopy={onPaste}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        name={name}
        css={inputCss}
        disabled={isDisabled}
      />
      <DropDownIndicator showDropdown={showDropdown} onClick={onClick}>
        <FaChevronDown />
      </DropDownIndicator>
    </InputWrapper>
  );
};

CustomInput.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
};

const DropDownIndicator = styled.div`
  position: absolute;
  left: 125px;
  width: fit-content;
  color: var(--body_color);
  svg {
    transition: all 150ms ease;
    transform: ${props => (props.showDropdown ? "rotate(180deg)" : null)};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
