import { cloneElement, useEffect, useState } from "react";
import { FaArrowCircleLeft, FaTimes } from "react-icons/fa";
import { GiCircle } from "react-icons/gi";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useGetEnquiry } from "src/customHooks/useGetCommonQueries";
import useSeeDetailsUtility from "src/customHooks/useSeeDetailsUtility";
import { useUrlQueries } from "src/customHooks/useUrlQuery";
import FHTypography from "src/styles/Typography";
import styled from "styled-components/macro";
import {
  useGetCartQuery,
  useGetPoliciesQuery,
  useGetShortlistedQuotesQuery,
} from "../api/api";
import { useQuote, useQuotesCompare, useTheme } from "../customHooks";
import { useCheckPolicies } from "../customHooks/useCheckPolicies";
import { replaceShortlistedQuote } from "../pages/NewQuotePage/QuotePageSlice/quote.slice";
import * as mq from "../utils/mediaQueries";
import FullScreenLoader from "./Common/FullScreenLoader";
import { Page } from "./Page";
import { VIEW } from "src/styles/responsiveStyle";

export function ScreenTopLoader({ progress, show }) {
  const { colors } = useTheme();

  if (!show) return null;

  return <ScreenTopLoaderStyled colors={colors} progress={progress} />;
}
const ScreenTopLoaderStyled = styled.div`
  position: fixed;
  top: 0;
  height: 0.2em;
  background-color: ${props => props.colors.primary_color};
  width: ${({ progress }) => progress}%;
  transition: 0.3s ease-in;
  z-index: 99;
`;

export function LoadEnquiries({ children }) {
  const { isLoading, isFetching, isUninitialized, isError } = useGetEnquiry();

  if (isError) {
    sessionStorage.setItem("invalidEnquiry", 1);
    window.location.href = `${
      window.location.origin + process.env.PUBLIC_URL
    }/input/basic-details`;
  }

  if (isLoading || isFetching || isUninitialized) return <FullScreenLoader />;

  return children;
}

export const CheckEditAccess = ({ children }) => {
  const isBasicDetailsRoute = useRouteMatch("/input/basic-details");

  const { policiesData, policiesLoading, policiesFetching } =
    useCheckPolicies();

  if (isBasicDetailsRoute) {
    return children;
  }

  if (policiesLoading || policiesFetching) {
    return <FullScreenLoader />;
  }

  const editAccess = !policiesData?.some(
    policy => policy.payment_status === "success",
  );

  return cloneElement(children, { editAccess });
};

export const MemberText = styled(FHTypography).attrs(() => ({
  variant: "subheading2",
  weight: "semi",
  colorType: "subHeading",
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export function MembersList({ members = [], ...props }) {
  const displayMembers = members
    ?.map(member => {
      if (
        !member?.multiple &&
        (member?.code.startsWith("son") || member?.code.startsWith("daughter"))
      ) {
        return member?.code;
      }
      return member?.display_name;
    })
    ?.join(", ");
  return <MemberText {...props}>{displayMembers}</MemberText>;
}

export function LoadCart({ children }) {
  const { groupCode } = useParams();
  const { buyQuote } = useQuote();
  const { product, enquiryId, riders: ridersString = "" } = useUrlQueries();

  // ========== If riders are selected in compare pdf ===== //
  let decodedString = "";
  try {
    decodedString = decodeURIComponent(ridersString);
  } catch (err) {
    decodedString = "[]";
  }

  let ridersArray = [];
  try {
    ridersArray = JSON.parse(decodedString);
  } catch (err) {
    ridersArray = [];
  }
  // ========== ===================================== ===== //

  const history = useHistory();
  const {
    getCompareQuotes,
    query: { isLoading: isCompareLoading },
  } = useQuotesCompare();
  const [isCartLoading, setIsCartLoading] = useState(true);
  const isQuoteRoute = useRouteMatch({ path: "/quotes" });
  const isProductDetailsRoute = useRouteMatch({ path: "/productdetails" });
  const isProposalRoute = useRouteMatch({ path: "/proposal" });
  const isProposalSummaryRoute = useRouteMatch({ path: "/proposal_summary" });

  const makeRequest = [
    isProposalSummaryRoute,
    isProposalRoute,
    isProductDetailsRoute,
    isQuoteRoute,
  ].some(value => value);

  // When PDF continue button is clicked
  useEffect(() => {
    if (product && !isCompareLoading) {
      const compareQuotes = getCompareQuotes(groupCode)?.quotes || [];
      setIsCartLoading(true);
      const productData =
        compareQuotes.find(quoteValue => quoteValue.product.id == product) ||
        {};
      const selectedRiders = ridersArray || [];
      buyQuote(
        {
          total_premium: productData?.total_premium,
          sum_insured: productData?.sum_insured,
          tax_amount: productData?.tax_amount,
          tenure: productData?.tenure,
          product: { id: productData?.product?.id },
          premium: productData?.premium,
          deductible: productData?.deductible,
        },
        selectedRiders,
      )
        .then(() => {
          setIsCartLoading(false);
          window.location.replace(
            `${
              window.location.origin +
              window.location.pathname +
              "?enquiryId=" +
              enquiryId
            }`,
          );
        })
        .catch(err => {
          console.error("err", err);
          setIsCartLoading(false);
        });
      setIsCartLoading(false);
    }
    return () => {};
  }, [isCompareLoading]);

  const { data: enquiryData } = useGetEnquiry();

  // when group code mismatched at product details page (invalid group code pass in url)
  const hasGroupCode = enquiryData?.data?.groups?.find(
    group => group.id == groupCode,
  );
  if (!hasGroupCode && isProductDetailsRoute) {
    window?.location?.replace(
      `${
        window.location.origin +
        process.env.PUBLIC_URL +
        `/productdetails/${enquiryData?.data?.groups[0]?.id}` +
        "?enquiryId=" +
        enquiryId
      }`,
    );
  }

  const { isLoading, error } = useGetCartQuery(undefined, {
    skip: !makeRequest,
  });

  if (error) {
    sessionStorage.setItem("invalidCart", 1);
    history.push("/");
  }

  if (product || isLoading || (product && isCartLoading))
    return <FullScreenLoader />;

  return children;
}

export const LoadShortlistedQuotes = ({ children }) => {
  const searchQueries = useUrlQueries();

  const isQuoteRoute = useRouteMatch({ path: "/quotes" });

  const isShortlistRoute = useRouteMatch({ path: "/shortlisted" });

  const makeRequest = [isQuoteRoute, isShortlistRoute].some(value => value);

  const { data, isFetching, isLoading } = useGetShortlistedQuotesQuery(
    undefined,
    {
      skip: !searchQueries.enquiryId || !makeRequest,
    },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && isShortlistRoute) {
      dispatch(replaceShortlistedQuote(data?.data?.shortlisted_quotes || []));
    }
    return () => {};
  }, [data]);

  if (isFetching || isLoading) {
    return <FullScreenLoader />;
  }

  return children;
};

export const LoadPolicies = ({ children }) => {
  const searchQueries = useUrlQueries();

  const { isLoading } = useGetPoliciesQuery(undefined, {
    skip: !searchQueries.enquiryId,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return children;
};

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Page id="error-page">
      <div role={"alert"}>
        <p>Something went wrong</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Reload</button>
      </div>
    </Page>
  );
}

export function CloseButton({ onClick, css = "", className = "" }) {
  return (
    <button
      onClick={onClick}
      className={`position-absolute ${className}`}
      css={`
        top: 1em;
        right: 1em;
        line-height: 1;
        ${css};
      `}
    >
      <FaTimes />
    </button>
  );
}

export function CircleCloseButton({ placeOnCorner = false, onClick }) {
  return (
    <CloseButtonStyled placeOnCorner={placeOnCorner} onClick={onClick}>
      <FaTimes />
    </CloseButtonStyled>
  );
}

export function useGotoProductDetailsPage() {
  const { data } = useGetCartQuery();
  const searchQueries = useUrlQueries();
  const history = useHistory();

  const { handleCloseSeeDetailsModal: closeSeeDetailsModal } =
    useSeeDetailsUtility();

  function gotoProductPage() {
    const groupCodes = data.data.map(cartEntry => cartEntry.group?.id);
    const firstGroupWithQuote = Math.min(...groupCodes);
    closeSeeDetailsModal();
    history.push(
      `/productdetails/${firstGroupWithQuote}?enquiryId=${searchQueries.enquiryId}`,
    );
  }

  return { gotoProductPage };
}

export function Import({ mobile, desktop, children, breakpoint = 768 }) {
  const isMobile = window.matchMedia(`(max-width: ${breakpoint}px)`).matches;
  //! This allows use to make imports dynamic by not including them into initial app loading.

  const [Component, setComponent] = useState(null);

  useEffect(() => {
    const importCallback = isMobile ? mobile : desktop;

    if (importCallback) {
      importCallback().then(componentDetails => {
        setComponent(componentDetails);
      });
    }
    return () => {};
  }, [desktop, mobile]);

  return children(Component ? Component.default : () => null);
}

export function BackButtonMobile({ css = "" }) {
  const { colors } = useTheme();
  const history = useHistory();

  return (
    <span
      aria-hidden="true"
      css={`
        color: ${colors.primary_color};
        font-size: 1.29em;
        ${css};
      `}
      onClick={() => {
        history.goBack();
      }}
    >
      <FaArrowCircleLeft />
    </span>
  );
}

export function OptionSelect({
  selectable,
  label,
  checked,
  onChange,
  dropdown = <></>,
  children,
}) {
  const { colors } = useTheme();

  return (
    <OptionContainer>
      <label>
        {selectable ? (
          <input
            type="checkbox"
            className="visually-hidden"
            checked={checked}
            onChange={onChange}
          />
        ) : null}
        {selectable ? (
          <div
            css={`
              font-size: 1.67rem;
              line-height: 0;
              margin-right: 0.3em;
            `}
          >
            {checked ? (
              <IoCheckmarkCircleSharp
                css={`
                  color: ${colors.primary_color};
                `}
              />
            ) : (
              <GiCircle
                css={`
                  color: #ccc;
                `}
              />
            )}
          </div>
        ) : null}
        {label}
      </label>
      {children}
      <div>{dropdown}</div>
    </OptionContainer>
  );
}

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  border: solid 1px #b0bed0;
  flex: 1 1 21em;
  gap: 0.7em;
  & label {
    display: flex;
    align-items: center;
    flex-grow: 1;
    align-self: stretch;
    font-size: 15px;
    line-height: 1;
    font-weight: 600;
  }
`;

const CloseButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: ${props => (props.placeOnCorner ? "absolute" : "")};
  top: -5px;
  right: 3px;
  height: 25px;
  width: 25px;
  transform: translate(30%, -30%);
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  ${VIEW.lg} {
    height: 25px;
    font-size: 1rem;
    width: 25px;
    top: -5px;
  }

  z-index: 120;
  ${mq.mobile} {
    font-size: 0.75rem;
  }
`;
