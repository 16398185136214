import styled from "styled-components";
import { bg } from "../../assets/images";
import { brokerTenant } from "src/utils/tenantSettings";
import { MAX, VIEW } from "src/styles/responsiveStyle";
import { ROUNDED, SHADES, SHADOW } from "src/styles/styleGuide";

export const InputWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  width: 100%;
  & > div {
    width: 50%;
  }
  ${MAX.md} {
    flex-direction: column;
    & > div {
      margin: 0 auto;
      width: 90%;
    }
  }
  ${MAX.sm} {
    margin: 0 auto;
    padding-top: 0;
    width: ${props => (props.currentForm === 2 ? "100%" : "90%")};
    & > div {
      width: 100% !important;
    }
  }
`;

export function checkSpecialChar(e, type) {
  if (["!", "@", "#", "$", "%", "^", "&", "*"].includes(e?.key)) {
    if (type === "name") {
      return "Please enter valid Full Name.";
    } else if (type === "number") {
      return "Please enter valid Mobile Number.";
    }
  } else {
    return "";
  }
}

export const DefaultFooterStyled = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
`;

export const InputContainerStyled = styled.div`
  background-image: url(${!brokerTenant("bajaj") && bg});
  background-attachment: fixed;
  background-position: center;
  min-height: calc(100vh - 60px);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  & div.sriyah__footer {
    position: absolute;
    left: 0px;
    width: 100%;
  }
  ${MAX.sm} {
    background: white;
    .hide_on_mobile {
      display: none;
    }
  }
`;

// Used in RenderInputForms.js
export const InputInnerWrapperStyled = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
`;

export const RenderInputCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 10px 0;
  max-width: 640px;
  width: 100%;
  margin-left: auto;
  padding: 0;
  position: relative;

  ${VIEW.md} {
    max-width: 500px;
    .renderInput_card {
      background-color: white;
      border-radius: ${ROUNDED.lg};
      box-shadow: ${SHADOW.lg};
      overflow: hidden;
    }
  }
`;

export const InputHeading = styled.div`
  width: fit-content;
  color: ${props => props.colors.primary_color};
  border: 1px solid ${props => props.colors.primary_color};
  background: ${props => props.colors.primary_shade + "44"};
  padding: 2px 5px;
  border-radius: 20px;
  font-size: 12px;
`;
export const InputSubtitle = styled.p`
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 4px;
`;

export const ResponsiveWrapper = styled.div`
  width: 85%;
  margin: 0 auto;

  @media (max-width: 830px) {
    width: 100%;
  }
  @media all and (min-width: 830px) and (max-width: 990px) {
    width: 98%;
  }
  @media all and (min-width: 990px) and (max-width: 1120px) {
    width: 95%;
  }
  @media all and (min-width: 1120px) and (max-width: 1250px) {
    width: 90%;
  }
`;
export const MobileHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: space-between;
  padding: 10px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: ${ROUNDED.lg};
  border: 1px solid ${SHADES.LIGHT_GRAY};
  box-shadow: ${SHADOW.base};

  ${
    // gradient styling for broker
    props =>
      props.settings?.color_visibilty?.gradients &&
      (brokerTenant("tmibasl") || brokerTenant("fyntune")) &&
      `background: linear-gradient(to right, ${Object.keys(
        props.settings?.color_visibilty?.gradients,
      )?.map(color => {
        return props.settings?.color_visibilty?.gradients[color];
      })});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`
  }

  .headingCapsule {
    width: fit-content;
    color: ${props => props.colors.primary_color};
    border: 1px solid ${props => props.colors.primary_color};
    padding: 2px 8px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
  }
`;
