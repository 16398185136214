import { RiLoader4Fill } from "react-icons/ri";
import styled from "styled-components";
import { useTheme } from "src/customHooks";
import { VIEW } from "src/styles/responsiveStyle";
import { FLEX_ROW_CENTER, ROUNDED } from "src/styles/styleGuide";

// use this component to render fully rounded icon button only (no text)
// pass primary/secondary as a variant
export default function IconButton({
  isLoading = false,
  disabled = false,
  children,
  btnId = "icon-btn",
  variant = "secondary",
  className,
  onClick = () => {},
}) {
  const { colors } = useTheme();

  return (
    <IconButtonStyled
      disabled={disabled}
      colors={colors}
      id={`auto_${btnId}`}
      variant={variant}
      onClick={onClick}
      className={className}
    >
      {isLoading ? <RiLoader4Fill className="rotate" /> : children}
    </IconButtonStyled>
  );
}

const IconButtonStyled = styled.button`
  padding: 0.5rem;
  ${FLEX_ROW_CENTER}
  border-radius: ${ROUNDED.full};
  font-size: 0.7rem;
  color: ${props => {
    return props.variant === "primary"
      ? props.colors.primary_color
      : props.colors.secondary_color;
  }};

  background-color: ${props => {
    return props.variant === "primary"
      ? props.colors.primary_shade
      : props.colors.secondary_shade;
  }};
  transition: all 0.2s ease-in-out;

  ${VIEW.lg} {
    font-size: 0.8rem;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    user-select: none;
  }
  &:hover:not(:disabled) {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;
